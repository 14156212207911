import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { user_Service } from "../Services/user_Service";
import {
    GridComponent,
    ColumnDirective,
    ColumnsDirective,
    Page,
    Edit,
    Toolbar,
    Inject, ColumnChooser, CommandColumn, Filter, PdfExport, ExcelExport, Grid, Sort, Reorder, Resize, Group, VirtualScroll, InfiniteScroll
} from "@syncfusion/ej2-react-grids";

export const EditAudit = () => {

    const onInputChange = () => {

    }
    const getDefaultDate = (datestring) => {
        var date;
        if (datestring === "") {
            var curr = new Date();
            curr.setDate(curr.getDate());
            date = curr.toISOString().substr(0, 10);
        }
        else {
            date = datestring.substr(0, 10);
        }
        return date;
    }

    const dateChange = e => {
    };

    const filter = {
        type: 'Menu'
    };
    const toolbarOptions = ["Search"];
    let grid = Grid | null;

    const [userdata, setuserdata] = useState([]);
    const getUserData = async () => {
        user_Service.getUserList().then((result) =>
            setuserdata(result.data)
        )
    }

    useEffect(() => {
        getUserData()
    }, [])

    const commandClick = (args) => { }
    return (
        <>
            <div className="container-fluid pt-20">
                <div className="row">


                    <div className="col-md-4">
                        <div className="card">
                            <div class="card-header text-center pb-0">
                      
                                <div className="row">
                                    <div className="col-md-7 text-right">
                                    <h5 className="card-text">Audit</h5>
                                    </div>
                                    <div className="col-md-5 text-right">
                                        <h4><Link to="/newaudit">...</Link></h4>

                                    </div>
                                </div>
                            </div>
                            <div className="card-body">
                                <div class="row align-self-center">
                                    <div className="col-md-3"></div>
                                    <div class="col-md-12 col-sm-12 col-xs-12">
                                        <div class="form-body">
                                            <div class="form-group row">
                                                <div class="col-md-12 my-1">
                                                    <label className="form-label">Audit Name</label><span className="text-danger reqired-fields">*</span>
                                                    <input type="text" readOnly  onChange={e => onInputChange(e)} className="form-control" id="controlOwnerEmailId" placeholder="Enter Audit Name" />
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <div class="col-md-12 my-1">
                                                    <label className="form-label">Description</label><span className="text-danger reqired-fields">*</span>
                                                    <textarea readOnly type="text" rows="4" cols="50" onChange={e => onInputChange(e)} className="form-control" id="pbcDesc" placeholder="Enter Request Description" />
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <div class="col-md-12 my-1">
                                                    <label className="form-label">Audit Period</label><span className="text-danger reqired-fields">*</span>
                                                    <input readOnly type="date" dateFormat={"mm-dd-yyyy"} value={getDefaultDate("")} onChange={date => dateChange(date)} className="form-control" id="pbcRequestDueDate" />
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <div class="col-md-12 my-1">
                                                    <label className="form-label">Audit Sponsor</label><span className="text-danger reqired-fields">*</span>
                                                    <select readOnly name="processAreaId" id="processAreaId" onChange={e => { onInputChange(e) }} className='form-control '>
                                                        <option value>-- Select Audit Sponsor --</option>
                                                    </select>
                                                </div>
                                            </div>
                                            {/* <div className="form-group row">
                                                <div class="col-md-12 my-1">
                                                    <div class="form-group text-right">
                                                        {<button type="button" className="btn btn-md btn-primary">Save</button>}
                                                        <button id="gotoNextClientTeam" className="btn btn-md btn-primary" style={{ marginLeft: "10px" }} >
                                                            Cancel
                                                        </button>
                                                    </div>
                                                </div>
                                            </div> */}
                                        </div>
                                    </div>
                                    <div className="col-md-3"></div>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="card">
                            <div class="card-header text-center pb-0">
                                <div className="row">
                                    <div className="col-md-8 text-right">
                                        <h5 className="card-text">Audit Team</h5>
                                    </div>
                                    <div className="col-md-4 text-right">
                                    <h4><Link to="/newaudit">...</Link></h4>

                                    </div>
                                </div>
                            </div>
                            <div className="card-body" style={{ minHeight: "425px", height: "auto" }}>
                                <GridComponent id='grid'
                                    dataSource={userdata}
                                    allowResizing={true}
                                    toolbar={toolbarOptions}
                                    filterSettings={filter}
                                    allowFiltering={true}
                                    allowSorting={true} allowPaging={true}
                                    recordDoubleClick={true}
                                    pageSettings={{ pageCount: 5, pageSize: 10 }}
                                    allowPdfExport={true} allowExcelExport={true} ref={g => grid = g} height={"100%"}
                                    commandClick={commandClick.bind(this)}>
                                    <ColumnsDirective>
                                        <ColumnDirective filter={filter} field="name" width='180' textAlign='left' headerText="Name" />
                                        <ColumnDirective filter={filter} field="emailId" width='200' textAlign='left' headerText="EmailId" />
                                    </ColumnsDirective>
                                    <Inject services={[Reorder, Resize, InfiniteScroll, VirtualScroll, Group, Sort, Edit, Page, Toolbar, ColumnChooser, CommandColumn, Filter, PdfExport, ExcelExport]} />
                                </GridComponent>

                            </div>
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="card">
                            <div class="card-header text-center pb-0">
                                <div className="row">
                                    <div className="col-md-8 text-right">
                                    <h5 className="card-text">Client Team</h5>
                                    </div>
                                    <div className="col-md-4 text-right">
                                    <h4><Link to="/newaudit">...</Link></h4>

                                    </div>
                                </div>

                            </div>
                            <div className="card-body" style={{ minHeight: "425px", height: "auto" }}>
                                <GridComponent id='grid'
                                    dataSource={userdata}
                                    allowResizing={true}
                                    toolbar={toolbarOptions}
                                    filterSettings={filter}
                                    allowFiltering={true}
                                    allowSorting={true} allowPaging={true}
                                    recordDoubleClick={true}
                                    pageSettings={{ pageCount: 5, pageSize: 10 }}
                                    allowPdfExport={true} allowExcelExport={true} ref={g => grid = g} height={"100%"}
                                    commandClick={commandClick.bind(this)}>
                                    <ColumnsDirective>
                                        <ColumnDirective filter={filter} field="name" width='180' textAlign='left' headerText="Name" />
                                        <ColumnDirective filter={filter} field="emailId" width='200' textAlign='left' headerText="EmailId" />
                                    </ColumnsDirective>
                                    <Inject services={[Reorder, Resize, InfiniteScroll, VirtualScroll, Group, Sort, Edit, Page, Toolbar, ColumnChooser, CommandColumn, Filter, PdfExport, ExcelExport]} />
                                </GridComponent>

                            </div>
                        </div>
                    </div>

                </div>
                <div className="row">
                    <div className="col-md-12 text-center">
                        <div className="card">
                            <div className="card-body ">
                                <div className="text-center">
                                    <Link to="/newaudit" className="btn btn-primary btn-md" style={{ marginRight: "20px" }}>Create New Audit</Link>
                                    <Link to="/AuditList" className="btn btn-primary btn-md">Manage Existing Audit</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}