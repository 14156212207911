import PropTypes from 'prop-types'
import { Link, useHistory } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { Login_Service } from "../Services/Login_Service";
import { ViewToaster } from "./ViewToaster";
import Cookies from 'universal-cookie';
import Swal from 'sweetalert2';
import Modal from "react-bootstrap/Modal";



export const Login = () => {
  let history = useHistory();
  const cookies = new Cookies();
  var CryptoJS = require("crypto-js");
  let [Credentials, setCredentials] = useState({ emailId: "", password: "" });

  const onInputChange = e => {
    setCredentials({ ...Credentials, [e.target.id]: e.target.value });
  };

  const [isOpen, setIsOpen] = React.useState(false);
  const [title, setTitle] = useState();
  let [values, SetValues] = useState({});
  const hideModal = () => {
    setIsOpen(false);
    setTitle("");
  };


  //Validating Inputes
  let emailIdError = "";
  let passwordError = "";
  const [validationstate, setValidationState] = useState(emailIdError, passwordError);
  const validate = () => {

    if (Credentials.emailId.length > 0) {
      var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
      if (!pattern.test(Credentials.emailId)) {
        emailIdError = "Please enter valid email address.";
      }
    }
    else {
      emailIdError = "Please Enter Email Address";
    }

    if (!Credentials.password.length > 0) {
      passwordError = "Please Enter Password";
    }

    if (emailIdError || passwordError) {
      setValidationState({ emailIdError, passwordError });
      return false;
    }
    return true;
  }


  const [loading, setloading] = useState("Sign In");
  const [buttonDisable, setbuttonDisable] = useState(false);

  //Form Submiting
  const onSubmit = async (e) => {
    e.preventDefault();
    const isValid = validate();
    if (isValid) {
      setValidationState({ emailIdError: "", password: "" });
      setloading("Loading");
      setbuttonDisable(true);

      Login_Service.userValidation(Credentials.emailId, Credentials.password).then(result => {
        var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(result.data.userId), process.env.REACT_APP_Secrect_Key).toString();
        cookies.set("_data", ciphertext, { path: "/", expires: new Date(Date.now() + 25920000) });
        if (result.data.isSystemPassword === true) {
          // setIsOpen(true);
        cookies.set("_IsSystem", true);
        history.push("/");
        window.location.reload(true);

        }
        else{
          cookies.set("_IsSystem", false);
          history.push("/");
          window.location.reload(true);
        }

      }).catch(err => {
        ViewToaster.getToaster(err.response.data.responseText, err.response.data.success);
        setloading("Sign In");
        setbuttonDisable(false);
      });
    }
  };

  function forgetpassword() {
    const isValid = validate();
    if (isValid) {
      setValidationState({ emailIdError: "", password: "" });
      Swal.fire({
        text: "Please check Email",
        confirmButtonColor: '#043a05',
        confirmButtonText: 'Ok'
      }).then((result) => {
        if (result.isConfirmed) {
        }
      })
    }
  }

  return (
    <div className="container pt-50">
      <div className="row">
        <div className="col-md-12">
          <div className="card">
            {/* <div className="card-header text-center">
              <div className="row">
                <div className="col-md-5 text-right pb-4">
                  <img style={{ height: "50px" }} src={require('../dist/img/small-logo.png').default} ></img>
                </div>
                <div className="col-md-6">
                  <h5 className="pt-4 text-left">Choose an option below to sign in </h5>
                </div>
              </div>
              <div className="row">
                <div className="col-md-3">
                </div>
                <div className="col-md-6">
                  <div className="text-center">
                    <div className="input-group mb-3">
                      <select type="password" className="form-control" id="password" value={Credentials.password} onChange={e => { onInputChange(e) }} placeholder="Password" >
                        <option value="">Select Audit</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                </div>
              </div>
            </div> */}

            <form onSubmit={onSubmit}>
              <div className="card-body">
                <div className="row">
                  <div className="col-md-5">
                    <div className="card-body">
                      <p className="login-box-msg"><b>CollabAudit Users</b></p>
                      <p className="login-box-msg">Sign in with your CollabAudit Credentials</p>
                      <div className="input-group mb-3">
                        <input type="emailId" className="form-control" id="emailId" value={Credentials.emailId} onChange={e => { onInputChange(e) }} placeholder="Email Id" />
                        <div className="input-group-append">
                          <div className="input-group-text">
                            <span className="fas fa-envelope"></span>
                          </div>
                        </div>
                        <div className="input-group ">
                          <small class="text-danger">{validationstate.emailIdError}</small>
                        </div>
                      </div>
                      <div className="input-group mb-3">
                        <input type="password" className="form-control" id="password" value={Credentials.password} onChange={e => { onInputChange(e) }} placeholder="Password" />
                        <div className="input-group-append">
                          <div className="input-group-text">
                            <span className="fas fa-lock"></span>
                          </div>
                        </div>
                        <div className="input-group ">
                          <small class="text-danger">{validationstate.passwordError}</small>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12 pt-3">
                          <button type="submit" disabled={buttonDisable} className="btn btn-primary btn-block">{loading}</button>
                        </div>
                      </div>
                      <p class="col-md-12 pt-2" style={{ textAlign: "center" }} >
                        <a href="#" onClick={() => forgetpassword()}>I forgot my password</a>
                      </p>
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="vertical">
                      <div style={{ position: "absolute", top: "45%", left: "-10px", background: "#fff" }}>
                        <span>OR</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-5">
                    <div className="card-body">
                      <div class="col-md-12 pt-4 mt-4 text-center" >
                        <a href="#"><img style={{ height: "120px", width: "150px" }} src={require('../dist/img/acme-1-logo-png-transparent.png').default} ></img></a>&nbsp;&nbsp;
                      </div>
                      <div className="col-12 pt-5">
                        <button type="submit" className="btn btn-primary btn-block">Sign With Organization</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
     
    </div>
  )
}
Login.defaultProps = {
  title: "Collab Audit :: Login",
  searchBar: true
}

Login.propTypes = {
  title: PropTypes.string,
  searchBar: PropTypes.bool.isRequired
}