import React, { useState, useEffect } from "react";
import Swal from 'sweetalert2'
import Modal from "react-bootstrap/Modal";
import axios from 'axios'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { ViewToaster } from "./ViewToaster";
import AuditName from './Common/AuditName'
import { ProgressBar } from 'react-bootstrap';
import {
    GridComponent,
    ColumnDirective,
    ColumnsDirective,
    Page,
    Edit,
    Toolbar,
    Inject, ColumnChooser, CommandColumn, Filter, PdfExport, ExcelExport, Grid, Sort, Reorder, Resize, Group, VirtualScroll, InfiniteScroll
} from "@syncfusion/ej2-react-grids";
import {
    ChartComponent,
    SeriesCollectionDirective,
    SeriesDirective,
    Legend,
    Category,
    Tooltip,
    ColumnSeries,
    DataLabel
} from '@syncfusion/ej2-react-charts';
import { Browser } from '@syncfusion/ej2-base';
export const Task = (props) => {

    var location = Location;
    ///tabs Selection
    const [key, setKey] = useState('overduetab');
    const onInputChange = e => {
    };

    let grid = Grid | null;
    const toolbarOptions = ["Search"];
    const commands = [{ type: 'Delete', buttonOption: { iconCss: 'e-icons e-delete', cssClass: 'e-flat' } },
    { type: 'Download', buttonOption: { iconCss: 'e-icons e-M_PV_Download', cssClass: 'e-flat' } }];
    const filter = {
        type: 'Menu'
    };
    const commandClick = (args) => {
    }
    return (
        <>
            {/* < AuditName></AuditName> */}
            <div class="container-fluid" style={{ background: "#e4e4e4", paddingTop: "5px" }}><div class="row"><div class="col-md-12"><h5>Payroll Processing</h5></div></div></div>
            <div className="container-fluid mt-2">
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-2">
                                        <div style={{ color: "#808080", fontWeight: "600", fontSize: "12px;" }}><span className="text-danger"><i className="fa fa-circle"></i></span> Overdue</div>
                                    </div>
                                    <div className="col-md-2">
                                        <div style={{ color: "#808080", fontWeight: "600", fontSize: "12px;" }}><span className="text-default"><i className="fa fa-circle"></i></span> Upcoming</div>
                                    </div>
                                    <div className="col-md-2">
                                        <div style={{ color: "#808080", fontWeight: "600", fontSize: "12px;" }}><span className="text-info"><i className="fa fa-circle"></i></span> Not Started</div>
                                    </div>
                                    <div className="col-md-2">
                                        <div style={{ color: "#808080", fontWeight: "600", fontSize: "12px;" }}><span className="text-warning"><i className="fa fa-circle"></i></span> Pending Review</div>
                                    </div>
                                    <div className="col-md-2">
                                        <div style={{ color: "#808080", fontWeight: "600", fontSize: "12px;" }}><span className="text-success"><i className="fa fa-circle"></i></span> Accepted</div>
                                    </div>
                                    <div className="col-md-2">
                                        <div style={{ color: "#808080", fontWeight: "600", fontSize: "12px;" }}><span className="text-danger"><i className="fa fa-circle"></i></span> Rejected</div>
                                    </div>
                                </div>
                                <div className="row mt-1">
                                    <div className="col-md-2 p-0 m-0">
                                        <ProgressBar variant="danger" now="100" label="100%" />
                                    </div>
                                    <div className="col-md-2 p-0 m-0">
                                        <ProgressBar variant="warning" now="100" label="100%" />
                                    </div>
                                    <div className="col-md-2 p-0 m-0"><ProgressBar variant="warning" now="100" label="100%" /></div>
                                    <div className="col-md-2 p-0 m-0"><ProgressBar variant="warning" now="100" label="100%" /></div>
                                    <div className="col-md-2 p-0 m-0"><ProgressBar variant="warning" now="100" label="100%" /></div>
                                    <div className="col-md-2 p-0 m-0"><ProgressBar variant="success" now="100" label="100%" /></div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                {/* <div class="row">
                    <div class="col-md-12"><h4 class="form-section">My Task</h4><hr></hr></div>
                </div> */}

                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-body">
                                <div class="form-group row m-0">
                                    <div class="col-md-1">
                                        <h5 class="card-text pt-2">My Task</h5>
                                    </div>
                                    <div class="col-md-4">
                                        <select name="processAreaId" id="processAreaId" onChange={e => { onInputChange(e) }} className='form-control '>
                                            <option value>-- Select Audit --</option>
                                        </select>
                                    </div>
                                    <div class="col-md-4">
                                        <select name="processAreaId" id="processAreaId" onChange={e => { onInputChange(e) }} className='form-control '>
                                            <option value>-- Select Team --</option>
                                        </select>
                                    </div>
                                    {/* <div class="col-md-3" style={{ paddingTop: "29px" }}>
                                        <button className="btn btn-md btn-primary">Submit</button>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="row">
                    <div class="col-md-12 col-sm-12 col-xs-12">
                        <Tabs id="controlled-tab-example" activeKey={key} onSelect={(k) => setKey(k)} >
                            <Tab eventKey="overduetab" title="Overdue Request">
                                <div className="container-fluid">
                                    <GridComponent id='grid'
                                        // dataSource={DocumentList}
                                        toolbar={toolbarOptions}
                                        allowResizing={true}
                                        allowSorting={true} allowPaging={true}
                                        filterSettings={filter}
                                        allowFiltering={true}
                                        recordDoubleClick={true}
                                        filterSettings={filter}
                                        allowFiltering={true}
                                        pageSettings={{ pageCount: 5, pageSize: 5 }}
                                        allowPdfExport={true} allowExcelExport={true} ref={g => grid = g} allowTextWrap={true} height={"100%"}
                                        commandClick={commandClick.bind(this)}>
                                        <ColumnsDirective>
                                            <ColumnDirective filter={filter} headerText='PBC Req Id' width='140' ></ColumnDirective>
                                            <ColumnDirective filter={filter} textAlign='Center' width='150' headerText="Process Area" />
                                            <ColumnDirective filter={filter} textAlign='Center' width='150' headerText="Control Id" />
                                            <ColumnDirective filter={filter} textAlign='Center' width='110' headerText="Due Date" />
                                            <ColumnDirective allowFiltering={false} textAlign='Center' width='110' headerText="OverDue Age" />
                                            <ColumnDirective headerText='Manage Records' width='150' textAlign='Center' commands={commands}></ColumnDirective>
                                        </ColumnsDirective>
                                        <Inject services={[Reorder, Resize, InfiniteScroll, VirtualScroll, Group, Sort, Edit, Page, Toolbar, ColumnChooser, CommandColumn, Filter, PdfExport, ExcelExport]} />
                                    </GridComponent>
                                </div>
                            </Tab>
                            <Tab eventKey="upcomingtab" title="Upcoming Request">
                                <div className="container-fluid">
                                    <GridComponent id='grid'
                                        // dataSource={DocumentList}
                                        toolbar={toolbarOptions}
                                        allowResizing={true}
                                        allowSorting={true} allowPaging={true}
                                        filterSettings={filter}
                                        allowFiltering={true}
                                        recordDoubleClick={true}
                                        filterSettings={filter}
                                        allowFiltering={true}
                                        pageSettings={{ pageCount: 5, pageSize: 5 }}
                                        allowPdfExport={true} allowExcelExport={true} ref={g => grid = g} allowTextWrap={true} height={"100%"}
                                        commandClick={commandClick.bind(this)}>
                                        <ColumnsDirective>
                                            <ColumnDirective filter={filter} headerText='PBC Req Id' width='140' />
                                            <ColumnDirective filter={filter} textAlign='Center' width='150' headerText="Process Area" />
                                            <ColumnDirective filter={filter} textAlign='Center' width='110' headerText="Control Id" />
                                            <ColumnDirective allowFiltering={false} textAlign='Center' width='110' headerText="Due Date" />
                                            <ColumnDirective allowFiltering={false} textAlign='Center' width='110' headerText="Due In" />
                                            <ColumnDirective headerText='Manage Records' width='150' textAlign='Center' commands={commands}></ColumnDirective>
                                        </ColumnsDirective>
                                        <Inject services={[Reorder, Resize, InfiniteScroll, VirtualScroll, Group, Sort, Edit, Page, Toolbar, ColumnChooser, CommandColumn, Filter, PdfExport, ExcelExport]} />
                                    </GridComponent>
                                </div>
                            </Tab>
                            <Tab eventKey="submittedtab" title="Submitted Request">
                                <div className="container-fluid">
                                    <GridComponent id='grid'
                                        // dataSource={DocumentList}
                                        toolbar={toolbarOptions}
                                        allowResizing={true}
                                        allowSorting={true} allowPaging={true}
                                        filterSettings={filter}
                                        allowFiltering={true}
                                        recordDoubleClick={true}
                                        filterSettings={filter}
                                        allowFiltering={true}
                                        pageSettings={{ pageCount: 5, pageSize: 5 }}
                                        allowPdfExport={true} allowExcelExport={true} ref={g => grid = g} allowTextWrap={true} height={"100%"}
                                        commandClick={commandClick.bind(this)}>
                                        <ColumnsDirective>
                                            <ColumnDirective filter={filter} headerText='PBC Req Id' width='140' />
                                            <ColumnDirective filter={filter} textAlign='Center' width='150' headerText="Process Area" />
                                            <ColumnDirective filter={filter} textAlign='Center' width='110' headerText="Control Id" />
                                            <ColumnDirective allowFiltering={false} textAlign='Center' width='110' headerText="Submitted On" />
                                            <ColumnDirective allowFiltering={false} textAlign='Center' width='110' headerText="Submitted Date" />
                                            <ColumnDirective headerText='Manage Records' width='150' textAlign='Center' commands={commands}></ColumnDirective>
                                        </ColumnsDirective>
                                        <Inject services={[Reorder, Resize, InfiniteScroll, VirtualScroll, Group, Sort, Edit, Page, Toolbar, ColumnChooser, CommandColumn, Filter, PdfExport, ExcelExport]} />
                                    </GridComponent>
                                </div>
                            </Tab>
                            <Tab eventKey="pendingtab" title="Request Pending Review">
                                <div className="container-fluid">
                                    <GridComponent id='grid'
                                        // dataSource={DocumentList}
                                        toolbar={toolbarOptions}
                                        allowResizing={true}
                                        allowSorting={true} allowPaging={true}
                                        filterSettings={filter}
                                        allowFiltering={true}
                                        recordDoubleClick={true}
                                        filterSettings={filter}
                                        allowFiltering={true}
                                        pageSettings={{ pageCount: 5, pageSize: 5 }}
                                        allowPdfExport={true} allowExcelExport={true} ref={g => grid = g} allowTextWrap={true} height={"100%"}
                                        commandClick={commandClick.bind(this)}>
                                        <ColumnsDirective>
                                            <ColumnDirective filter={filter} headerText='PBC Req Id' width='140' />
                                            <ColumnDirective filter={filter} textAlign='Center' width='150' headerText="Process Area" />
                                            <ColumnDirective filter={filter} textAlign='Center' width='110' headerText="Control Id" />
                                            <ColumnDirective allowFiltering={false} textAlign='Center' width='110' headerText="Submitted Date" />
                                            <ColumnDirective headerText='Manage Records' width='150' textAlign='Center' commands={commands}></ColumnDirective>
                                        </ColumnsDirective>
                                        <Inject services={[Reorder, Resize, InfiniteScroll, VirtualScroll, Group, Sort, Edit, Page, Toolbar, ColumnChooser, CommandColumn, Filter, PdfExport, ExcelExport]} />
                                    </GridComponent>
                                </div>
                            </Tab>
                            <Tab eventKey="accepttab" title="Request Accepted">
                                <div className="container-fluid">
                                    <GridComponent id='grid'
                                        // dataSource={DocumentList}
                                        toolbar={toolbarOptions}
                                        allowResizing={true}
                                        allowSorting={true} allowPaging={true}
                                        filterSettings={filter}
                                        allowFiltering={true}
                                        recordDoubleClick={true}
                                        filterSettings={filter}
                                        allowFiltering={true}
                                        pageSettings={{ pageCount: 5, pageSize: 5 }}
                                        allowPdfExport={true} allowExcelExport={true} ref={g => grid = g} allowTextWrap={true} height={"100%"}
                                        commandClick={commandClick.bind(this)}>
                                        <ColumnsDirective>
                                            <ColumnDirective filter={filter} headerText='PBC Req Id' width='140' />
                                            <ColumnDirective filter={filter} textAlign='Center' width='150' headerText="Process Area" />
                                            <ColumnDirective filter={filter} textAlign='Center' width='110' headerText="Control Id" />
                                            <ColumnDirective allowFiltering={false} textAlign='Center' width='110' headerText="Submitted On" />
                                            <ColumnDirective allowFiltering={false} textAlign='Center' width='110' headerText="Accepted On" />
                                            <ColumnDirective headerText='Manage Records' width='150' textAlign='Center' commands={commands}></ColumnDirective>
                                        </ColumnsDirective>
                                        <Inject services={[Reorder, Resize, InfiniteScroll, VirtualScroll, Group, Sort, Edit, Page, Toolbar, ColumnChooser, CommandColumn, Filter, PdfExport, ExcelExport]} />
                                    </GridComponent>
                                </div>
                            </Tab>
                            <Tab eventKey="rejecttab" title="Request Rejected">
                                <div className="container-fluid">
                                    <GridComponent id='grid'
                                        // dataSource={DocumentList}
                                        toolbar={toolbarOptions}
                                        allowResizing={true}
                                        allowSorting={true} allowPaging={true}
                                        filterSettings={filter}
                                        allowFiltering={true}
                                        recordDoubleClick={true}
                                        filterSettings={filter}
                                        allowFiltering={true}
                                        pageSettings={{ pageCount: 5, pageSize: 5 }}
                                        allowPdfExport={true} allowExcelExport={true} ref={g => grid = g} allowTextWrap={true} height={"100%"}
                                        commandClick={commandClick.bind(this)}>
                                        <ColumnsDirective>
                                            <ColumnDirective filter={filter} headerText='PBC Req Id' width='140' />
                                            <ColumnDirective filter={filter} textAlign='Center' width='150' headerText="Process Area" />
                                            <ColumnDirective filter={filter} textAlign='Center' width='110' headerText="Control Id" />
                                            <ColumnDirective allowFiltering={false} textAlign='Center' width='110' headerText="Submitted On" />
                                            <ColumnDirective filter={filter} textAlign='Center' width='110' headerText="Rjetcted On" />
                                            <ColumnDirective headerText='Manage Records' width='150' textAlign='Center' commands={commands}></ColumnDirective>
                                        </ColumnsDirective>
                                        <Inject services={[Reorder, Resize, InfiniteScroll, VirtualScroll, Group, Sort, Edit, Page, Toolbar, ColumnChooser, CommandColumn, Filter, PdfExport, ExcelExport]} />
                                    </GridComponent>
                                </div>
                            </Tab>
                        </Tabs>
                    </div>
                </div>
            </div>
        </>
    );
}