import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import {
    GridComponent,
    ColumnDirective,
    ColumnsDirective,
    Page,
    Edit,
    Toolbar,
    Inject, ColumnChooser, CommandColumn, Filter, PdfExport, ExcelExport, Grid, Sort, Reorder, Resize, Group, VirtualScroll, InfiniteScroll
} from "@syncfusion/ej2-react-grids";
import { Audit_Service } from "../Services/Audit_Service"
import AuditName from './Common/AuditName'
import { ViewToaster } from "./ViewToaster";

export class AuditList extends React.Component {

    constructor(props) {
        super(props);
        this.deleteAudit = this.deleteAudit.bind(this);

        this.state = {
            AuditList: []
        }
        this.filter = {
            type: 'Menu'
        };
        this.check = {
            type: 'CheckBox'
        };
        this.groupOptions = { showGroupedColumn: false };

        this.toolbarOptions = ["Search",]; //'ColumnChooser',

        this.grid = Grid | null;


    }

    componentDidMount() {
        this.getAllData();
    }

    getAllData = async () => {
        Audit_Service.getAuditList().then((result) => {
            this.setState({ AuditList: result.data });

        })
    }

    //Manage Client Button 
    getManageClientButton = (args) => {
        return (
            <>
                <div>
                    <div >
                        <Link to={{ pathname: "/newAudit", state: { objAuditId: args.auditId, tabName: "manageClientTeamTab" } }} className="btn btn-sm btn-success">Manage Client</Link></div>
                </div>
            </>
        );
    }

    //Manage Audit Team Button 
    getManageAuditButton = (args) => {
        return (
            <>
                <div>
                    <div>
                        <Link to={{ pathname: "/newAudit", state: { objAuditId: args.auditId, tabName: "manageAuditTeamTab" } }} className="btn btn-sm btn-info">Manage Audit</Link>
                    </div>
                </div>
            </>
        );
    }


    deleteAudit(args) {
        Audit_Service.deleteAudit(args.auditId).then(result => {
            this.getAllData();
            ViewToaster.getToaster(result.data.responseText, result.data.success);
        }).catch(err => ViewToaster.getToaster(err.response.data.responseText, err.response.data.success));
    }

    getcommandButton(args) {
        return (
            <>
                {
                    <div className="row" style={{ placeContent: "center" }}>
                        <Link to={{ pathname: "/newAudit", state: { objAuditId: args.auditId, tabName: "auditTab" } }} href="javscript:" title="Edit" style={{ marginRight: "4px" }} className="e-lib e-btn e-control e-flat" ><span className="e-btn-icon e-icons e-edit"></span></Link>
                        <button href="javscript:" onClick={()=>this.deleteAudit} title="Delete" style={{ marginLeft: "4px", marginRight: "4px" }} className="e-lib e-btn e-control e-flat" ><span className="e-btn-icon e-icons e-delete"></span></button>
                        <button href="javscript:" title="Suspend" style={{ marginLeft: "4px", marginRight: "4px" }} className="e-lib e-btn e-control e-flat" ><span className="e-btn-icon e-icons e-MT_Close"></span></button>
                    </div>
                }
            </>
        );
    }

    render() {
        return (
            <>
                <AuditName />
                <div className="my-4 container-fluid">
                    <section id="horizontal-form-layouts">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card ">
                                    <div class="card-header text-center">
                                        <h6 className="card-text">Manage Existing Audit</h6>
                                    </div>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-md-12 text-right mb-2">
                                                <Link to="/newaudit" className="btn btn-primary btn-md">Create New Audit</Link>
                                            </div>
                                        </div>
                                        <GridComponent id='grid'
                                            dataSource={this.state.AuditList}
                                            toolbar={this.toolbarOptions}
                                            allowResizing={true}
                                            allowSorting={true} allowPaging={true}
                                            filterSettings={this.filter}
                                            allowFiltering={true}
                                            recordDoubleClick={true}
                                            filterSettings={this.filter}
                                            allowFiltering={true}
                                            pageSettings={{ pageCount: 5, pageSize: 5 }}
                                            allowPdfExport={true} allowExcelExport={true} ref={g => this.grid = g} allowTextWrap={true} height={"100%"}
                                        >
                                            <ColumnsDirective>
                                                <ColumnDirective field="lastUpdateDateTime" filter={this.filter} textAlign="left" type="date" format='dd-MM-yyyy' headerText='Date' width='100' ></ColumnDirective>
                                                <ColumnDirective field="auditName" filter={this.filter} textAlign='Left' width='160' headerText="Name" />
                                                <ColumnDirective field="auditStartDate" type="date" format='dd-MM-yyyy' filter={this.filter} textAlign='Left' width='130' headerText="Start Date" />
                                                <ColumnDirective field="auditEndDate" type="date" format='dd-MM-yyyy' filter={this.filter} textAlign='Left' width='130' headerText="Target Date" />
                                                <ColumnDirective field="auditPeriod" filter={this.filter} textAlign='center' width='120' headerText="Audit Period" />
                                                <ColumnDirective field="auditId" textAlign='Left' width='120' headerText="Client Team" template={this.getManageClientButton} />
                                                <ColumnDirective field="auditId" textAlign='Left' width='110' headerText="Audit Team" template={this.getManageAuditButton} />
                                                {/* <ColumnDirective field="auditId" allowFiltering={false} allowReordering={false} headerText='Manage Records' width='120' textAlign='center' template={this.getcommandButton}></ColumnDirective> */}
                                            </ColumnsDirective>
                                            <Inject services={[Reorder, Resize, InfiniteScroll, VirtualScroll, Group, Sort, Edit, Page, Toolbar, ColumnChooser, CommandColumn, Filter, PdfExport, ExcelExport]} />
                                        </GridComponent>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </>
        )

    }
}



