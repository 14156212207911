import axios from 'axios'

const Url = process.env.REACT_APP_API_URL;
export const Role_Service = {
    getRoleList,
    getRoleListByUserType
}

async function getRoleList() {
    const result = await axios.get(`${Url}role/getRoleList`);
    return result;
}

async function getRoleListByUserType(usertypeId) {
    const result = await axios.get(`${Url}role/getRoleListByUserType?usertypeId=${usertypeId}`);
    return result;
}
