import './App.css';
import Header from "./Component/Header";
import { Footer } from "./Component/Footer";
import { PBCRequestList } from './Component/PBCRequestList';
import Dashboard from './Component/Dashboard';
import { Login } from "./Component/Login";
import { ResetPassword } from "./Component/ResetPassword";
import { ModuleAddEdit } from "./Component/Module/ModuleAddEdit";
import { PermissionAddEdit } from "./Component/Permission/PermissionAddEdit";
import { UserRolePermission } from "./Component/UserRolePermission/UserRolePermission";
import { UserAddEdit } from "./Component/User/UserAddEdit";
import { BrowserRouter as Router, Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { createBrowserHistory } from "history";
import { Common_Service } from '../src/Services/Common_Service';
import { UserManagement } from './Component/UserManagement';
import { Setting } from './Component/Setting';
import { Audit } from './Component/Audit';
import { AuditList } from './Component/AuditList'
import { AddEditPBCRequest } from './Component/AddEditPBCRequest'
import { Task } from './Component/Task'
import { EditAudit } from './Component/EditAudit'
import { MyProfile } from './Component/MyProfile'

export const history = createBrowserHistory();

function App() {

  function getToken() {
    let objuserId = Common_Service.getCookies();
    return objuserId;
  }

  function getIsSystemPassword() {
    let objIsSystem = Common_Service.getSystemCookie();
    return objIsSystem;
  }

  const userId = getToken();
  const isSystemPassword = getIsSystemPassword();

  if (!userId) {
    return <Login />
  }
  else if (isSystemPassword==="true") {
    return <ResetPassword objuserId={userId}/>
  }

  return (
    <>
      <Router>
        <Header title="CollabAudit" />
        <Switch>
          <Route exact path='/' component={Dashboard} objuserId={userId} />
          <Route exact path='/Dashboard' component={Dashboard} objuserId={userId} />
          <Route exact path='/PBCRequestList' component={PBCRequestList} />
          <Route exact path='/module' component={ModuleAddEdit} />
          <Route exact path='/permissions' component={PermissionAddEdit} />
          <Route exact path='/userrolePermission' component={UserRolePermission} />
          <Route exact path='/user' component={UserAddEdit} objuserId={userId} />
          <Route exact path='/usermanagement' component={UserManagement} />
          <Route exact path='/settings' component={Setting} />
          <Route exact path='/newaudit' component={Audit} />
          <Route exact path='/AuditList' component={AuditList} />
          <Route exact path='/AddEditPBCRequest' component={AddEditPBCRequest} />
          <Route exact path='/mytask' component={Task} />
          <Route exact path='/myprofile' component={MyProfile} />
          <Route exact path='/audit' component={EditAudit} />
          <Route path='*'>
            <h1>Not Found</h1>
          </Route>
        </Switch>
        <Footer></Footer>
      </Router>
    </>
  );
}
export default App;