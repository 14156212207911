import React, { useState, useEffect } from "react";
import {
    AccumulationChartComponent,
    AccumulationSeriesCollectionDirective,
    AccumulationSeriesDirective,
    Inject,
    AccumulationLegend,
    PieSeries,
    AccumulationTooltip,
    AccumulationDataLabel,
    ChartComponent, SeriesCollectionDirective, SeriesDirective, Legend, Category, StackingColumnSeries, Tooltip, ILoadedEventArgs, ChartTheme,
    ColumnSeries,
    DataLabel, LineSeries, DateTime,
} from '@syncfusion/ej2-react-charts';
import { ProgressBar } from 'react-bootstrap';
import { Browser } from '@syncfusion/ej2-base';
import { fabricColors, bootstrapColors, materialColors, highContrastColors } from './theme-color';
import AuditName from './Common/AuditName'

import FusionCharts from "fusioncharts";
import charts from "fusioncharts/fusioncharts.charts";
import ReactFusioncharts from "react-fusioncharts";

const palette = ["#37761d", "#ed7d31", "#00ff01", "#ffc000", "#0364c3", "#81bc00", "#990100", "#ed7d31", "#741b47", "#fdff00", "#7c9cd5"];
const requestProgressColors = ["#8fb94b", "#095994"];
const resubmissionColors = ["#006aa5", "#dddddd"];
const requestNTaskColors = ["#f04019", "#0478ab", "#afdfff"];
const paletteStakedColumn = ["#999999", "#81bc00", "#fdc100", "#4a86e8", "#cb0101", "#ec7d36"];

function Dashboard(props) {
    charts(FusionCharts);

    // Donut Chart
    let donutData = [{ x: 'Privacy & Security', y: 18, text: '18%' },
    { x: 'Inventory', y: 8, text: '8%' },
    { x: 'Current Assets', y: 18, text: '18%' },
    { x: 'Accounting', y: 15, text: '15%' },

    { x: 'Payroll', y: 10, text: '10%' },
    { x: 'Revenue', y: 19, text: '19%' },
    { x: 'Purchases', y: 20, text: '20%' },
    { x: 'Fixed Assets', y: 50, text: '50%' },
    { x: 'Recievables', y: 23, text: '23%' },
    { x: 'Payables', y: 11, text: '11%' },
    { x: 'Other Liablities', y: 16, text: '16%' },

    ];


    const openFullscreen = (id) => {
        var elem = document.getElementById(id);

        if (elem.requestFullscreen) {
            elem.requestFullscreen();
        } else if (elem.webkitRequestFullscreen) { /* Safari */
            elem.webkitRequestFullscreen();
        } else if (elem.msRequestFullscreen) { /* IE11 */
            elem.msRequestFullscreen();
        }
    }


   

    let data1 = [
        { x: 'Recived', y: 46 },
        { x: 'Pending', y: 27 },
        { x: 'Rejected', y: 26 },
        { x: 'Ovredue', y: 50 },
        { x: 'Clarifivation Requested', y: 52 },
        { x: 'Pending Review', y: 100 }
    ];

    
    let labelRender = args => {
        let selectedTheme = '';
        selectedTheme = selectedTheme ? selectedTheme : 'material';
        if (selectedTheme && selectedTheme.indexOf('fabric') > -1) {
            args.fill = fabiricColors[args.point.index % 10];
        } else {
            args.fill = fabiricColors[args.point.index % 10];
        }
    };

    let labelRender2 = args => {
        let selectedTheme = '';
        selectedTheme = selectedTheme ? selectedTheme : 'material';
        if (selectedTheme && selectedTheme.indexOf('fabric') > -1) {
            args.fill = fabiricColors1[args.point.index % 10];
        } else {
            args.fill = fabiricColors1[args.point.index % 10];
        }
    };

    const fabiricColors = ['#81bc00', '#0564c2', '#999999', '#cc0001', '#ed7d31',
        '#ffc000'];
    const fabiricColors1 = ['#ed7d31', '#6f6fe2', '#990100', '#70ad46', '#5c9ad3', '#ed7d31',
        '#ffc000', '#5c9ad3'];
    return (
        <>
            <AuditName></AuditName>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12 bg-black">
                        <div className="row">
                            <div className="col-md-3 col-sm-12 col-xs-12" style={{ borderRight: "1px solid #e4e4e4" }}>
                                <div className="card bg-black">
                                    <div class="card-header text-left">
                                        <h5 className="card-text">My Activities <i className="fa fa-exclamation-circle"></i></h5>
                                    </div>
                                    <div className="card-body">
                                        <h6 className="card-text">Items Needing Attention</h6>
                                        <hr className="hr-hori-white"></hr>
                                        <table className="table table-sm" style={{ border: "none", fontSize: "10px" }}>
                                            <tr>
                                                <td style={{ border: "none" }}><span className="text-danger" style={{ fontSize: "16px", fontWeight: "600", paddingRight: "5px" }}>2</span><span>OVREDUE</span></td>
                                                <td style={{ border: "none" }}><span className="text-warning" style={{ fontSize: "16px", fontWeight: "600", paddingRight: "5px" }}>3</span><span>UPCOMING</span></td>
                                            </tr>
                                            
                                        </table>
                                    </div><div className="clearfix"></div>
                                    <div className="card-body">
                                        <h6 className="card-text">Other Items</h6>
                                        <hr className="hr-hori-white"></hr>
                                        <table className="table table-sm" style={{ border: "none", fontSize: "10px" }}>
                                            <tr>
                                                <td style={{ border: "none" }}><span className="text-danger" style={{ fontSize: "16px", fontWeight: "600", paddingRight: "5px" }}>1</span><span>ACCEPTED</span></td>
                                                <td style={{ border: "none" }}><span className="text-warning" style={{ fontSize: "16px", fontWeight: "600", paddingRight: "5px" }}>3</span><span>SUBMITTED</span></td>
                                            </tr>
                                            <tr>
                                                <td style={{ border: "none" }}><span className="text-danger" style={{ fontSize: "16px", fontWeight: "600", paddingRight: "5px" }}>2</span><span>REJECTED</span></td>
                                                <td style={{ border: "none" }}><span className="text-warning" style={{ fontSize: "16px", fontWeight: "600", paddingRight: "5px" }}>3</span><span>PENDING REVIEW</span></td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-12 col-xs-12" style={{ borderRight: "1px solid #e4e4e4" }}>
                                <div className="card bg-black">
                                    <div class="card-header text-left">
                                        <h5 className="card-text">Request Progress <i className="fa fa-exclamation-circle"></i></h5>
                                    </div>
                                    <div className="card-body">
                                        <table className="mt-50">
                                            <tr>
                                                <td className="text-left">
                                                    <ul class="chart-skills">
                                                        <li>
                                                            <span></span>
                                                        </li>
                                                        <li>
                                                            <span></span>
                                                        </li>
                                                        <li>
                                                            <span></span>
                                                        </li>
                                                        <li>
                                                            <span></span>
                                                        </li>
                                                    </ul>
                                                </td>
                                                <td className="text-left" style={{ paddingLeft: "30px" }}>
                                                    <ul>
                                                        <li style={{ fontSize: "20px", color: "#81bc00" }}><span style={{ fontSize: "12px", color: "#fff" }}>Client Submitted</span></li>
                                                        <li style={{ fontSize: "20px", color: "#0478ab" }}><span style={{ fontSize: "12px", color: "#fff" }}>CollabAudit Acceped</span></li>
                                                    </ul>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-5 col-sm-12 col-xs-12" style={{ borderRight: "1px solid #e4e4e4" }}>
                                <div className="card bg-black">
                                    <div class="card-header text-left">
                                        <h5 className="card-text">Open Requests & Tasks <i className="fa fa-exclamation-circle"></i></h5>
                                    </div>
                                    <div className="card-body">
                                        <table className="table mt-20" style={{ border: "none" }}>
                                            <tr>
                                                <td className="text-left" style={{ border: "none" }}>
                                                    <ul class="chart1-skills">
                                                        <li>
                                                            <span></span>
                                                        </li>
                                                        <li>
                                                            <span></span>
                                                        </li>
                                                        <li>
                                                            <span></span>
                                                        </li>
                                                        <li>
                                                            <span></span>
                                                        </li>
                                                    </ul>
                                                </td>
                                                <td className="text-left" style={{ border: "none" }}>
                                                    <ul class="chart1-skills">
                                                        <li>
                                                            <span></span>
                                                        </li>
                                                        <li>
                                                            <span></span>
                                                        </li>
                                                        <li>
                                                            <span></span>
                                                        </li>
                                                        <li>
                                                            <span></span>
                                                        </li>
                                                    </ul>
                                                </td>
                                                <td className="text-left" style={{ paddingLeft: "30px", border: "none" }}>
                                                    <ul>
                                                        <li style={{ fontSize: "20px", color: "#81bc00" }}><span style={{ fontSize: "12px", color: "#fff" }}>Ovredue</span></li>
                                                        <li style={{ fontSize: "20px", color: "#0478ab" }}><span style={{ fontSize: "12px", color: "#fff" }}>Due Today</span></li>
                                                        <li style={{ fontSize: "20px", color: "#afdfff" }}><span style={{ fontSize: "12px", color: "#fff" }}>Upcoming 1-7 days</span></li>
                                                        <li style={{ fontSize: "20px", color: "#f04019" }}><span style={{ fontSize: "12px", color: "#fff" }}>Due in 8+ days</span></li>
                                                    </ul>

                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-20">
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <div className="card">
                            <div class="card-header text-center bg-warning">
                                <h6 className="card-text">PBC By Location</h6>
                            </div>
                            <div className="card-body p-1">
                                <div className="text-right">
                                    <h6><span class="badge badge-sm badge-danger">Location(s) 45</span></h6>
                                </div>
                                <table className='table table-sm table-striped'>
                                    <tr>
                                        <th>Location</th>
                                        <th>Received</th>
                                        <th>Due</th>
                                        <th>Pending</th>
                                        <th>Total</th>
                                    </tr>
                                    <tr>
                                        <td className="bg-table-grey">USA</td>
                                        <td className="bg-table-grey">4</td>
                                        <td className="bg-table-grey">2</td>
                                        <td className="bg-table-grey">6</td>
                                        <td className="bg-table-grey">12</td>
                                    </tr>
                                    <tr>
                                        <td>Singapore</td>
                                        <td>10</td>
                                        <td>4</td>
                                        <td>6</td>
                                        <td>20</td>
                                    </tr>
                                    <tr>
                                        <td className="bg-table-grey">India</td>
                                        <td className="bg-table-grey">10</td>
                                        <td className="bg-table-grey">4</td>
                                        <td className="bg-table-grey">6</td>
                                        <td className="bg-table-grey">20</td>
                                    </tr>
                                    <tr>
                                        <td>UK</td>
                                        <td>10</td>
                                        <td>4</td>
                                        <td>6</td>
                                        <td>20</td>
                                    </tr>
                                </table>

                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <div className="card">
                            <div class="card-header text-center bg-warning">
                                <h6 className="card-text">PBC By Process Owner</h6>
                            </div>
                            <div className="card-body p-1">
                                <div className="text-right">
                                    <h6><span class="badge badge-sm badge-danger">Process owner 16</span></h6>
                                </div>
                                <table className='table table-sm table-striped'>
                                    <tr>
                                        <th>Location</th>
                                        <th>Received</th>
                                        <th>Due</th>
                                        <th>Pending</th>
                                        <th>Total</th>
                                    </tr>
                                    <tr>
                                        <td className="bg-table-grey">Ajit</td>
                                        <td className="bg-table-grey">4</td>
                                        <td className="bg-table-grey">2</td>
                                        <td className="bg-table-grey">6</td>
                                        <td className="bg-table-grey">12</td>
                                    </tr>
                                    <tr>
                                        <td>Amit</td>
                                        <td>10</td>
                                        <td>4</td>
                                        <td>6</td>
                                        <td>20</td>
                                    </tr>
                                    <tr>
                                        <td className="bg-table-grey">Riya</td>
                                        <td className="bg-table-grey">10</td>
                                        <td className="bg-table-grey">4</td>
                                        <td className="bg-table-grey">6</td>
                                        <td className="bg-table-grey">20</td>
                                    </tr>
                                    <tr>
                                        <td>Sumit</td>
                                        <td>10</td>
                                        <td>4</td>
                                        <td>6</td>
                                        <td>20</td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <div className="card">
                            <div class="card-header text-left">
                                <h6 className="card-text">Resubmissions <i className="fa fa-exclamation-circle"></i></h6>
                            </div>
                            <div className="card-body">
                                <table className="table mt-20">
                                    <tr>
                                        <td className="text-center" style={{ border: "none", paddingLeft: "30%" }}>
                                            <ul class="chartResub-skills">
                                                <li>
                                                    <span></span>
                                                </li>
                                                <li>
                                                    <span></span>
                                                </li>
                                                <li>
                                                    <span></span>
                                                </li>
                                                <li>
                                                    <span></span>
                                                </li>
                                            </ul>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row" >
                    <div className="col-md-4">
                        <div className="card">
                            <div class="card-header text-center bg-warning">
                                <h6 className="card-text">PBC Due Today</h6>
                            </div>
                            <div className="card-body p-1">
                                <div className="text-right">
                                    <div className="row">
                                        <div className="col-md-9">
                                            <h6><span class="badge badge-sm badge-danger">Process owner 16</span></h6>
                                        </div>
                                        <div className="col-md-3">
                                            <h6><span class="badge badge-sm badge-danger">Auditor(s) 7</span></h6>
                                        </div>
                                    </div>
                                </div>
                                <table className='table table-sm table-striped'>
                                    <tr>
                                        <th>Name</th>
                                        <th>Count</th>
                                    </tr>
                                    <tr>
                                        <td className="bg-table-grey">Sean</td>
                                        <td className="bg-table-grey">12</td>
                                    </tr>
                                    <tr>
                                        <td>Amit</td>
                                        <td>20</td>
                                    </tr>
                                    <tr>
                                        <td className="bg-table-grey">Sumit</td>
                                        <td className="bg-table-grey"> 10</td>
                                    </tr>
                                    <tr>
                                        <td>shawn</td>
                                        <td>15</td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="card">
                            <div class="card-header text-center bg-warning">
                                <h6 className="card-text">PBC Due This Week</h6>
                            </div>
                            <div className="card-body p-1">
                                <div className="text-right">
                                    <div className="row">
                                        <div className="col-md-9">
                                            <h6><span class="badge badge-sm badge-danger">Process owner 16</span></h6>
                                        </div>
                                        <div className="col-md-3">
                                            <h6><span class="badge badge-sm badge-danger">Auditor(s) 7</span></h6>
                                        </div>
                                    </div>
                                </div>
                                <table className='table table-sm table-striped'>
                                    <tr>
                                        <th>Name</th>
                                        <th>Count</th>
                                    </tr>
                                    <tr>
                                        <td className="bg-table-grey">Sean</td>
                                        <td className="bg-table-grey">12</td>
                                    </tr>
                                    <tr>
                                        <td>Amit</td>
                                        <td>20</td>
                                    </tr>
                                    <tr>
                                        <td className="bg-table-grey">Sumit</td>
                                        <td className="bg-table-grey">10</td>
                                    </tr>
                                    <tr>
                                        <td>shawn</td>
                                        <td>15</td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="card">
                            <div class="card-header text-left">
                                <h6 className="card-text">Completed Request <i className="fa fa-exclamation-circle"></i></h6>
                            </div>
                            <div className="card-body">
                                <div className="row" style={{ backgroundColor: "#e2e0e0" }}>
                                    <div className="col-md-6 p-3">
                                        <h6 className="card-text"><b>86%</b> <small>ON TIME</small></h6>
                                    </div>
                                    <div className="col-md-6 p-3">
                                        <h6 className="card-text"><b>1</b> <small>AVG DAYS OVERDUE</small></h6>
                                    </div>
                                </div>
                                <div className="pt-2">
                                    <small>On Time</small>
                                    <ProgressBar variant="success" now="90" label="90%" />
                                </div>
                                <div>
                                    <small>Over Due 1-7 Days</small>
                                    <ProgressBar now="60" label="60%" />
                                </div>
                                <div>
                                    <small>Over Due 8+ Days</small>
                                    <ProgressBar now="0" label="0%" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row" >
                    <div className="col-md-6 col-sm-12 col-xs-12" >
                        <div className="card card-warning">
                            <div className="card-header">
                                <h3 className="card-title">PBC Volume By Process Area</h3>
                                <div class="card-tools">
                                    <button type="button" onClick={() => openFullscreen("pieChart")} class="btn btn-tool" data-card-widget="maximize"><i class="fas fa-expand"></i>
                                    </button>
                                </div>
                            </div>
                            <div className="card-body chart-wrapper" id="pieChart">
                                <AccumulationChartComponent id="pie-chart" legendSettings={{
                                    visible: true,
                                    position: 'Right'
                                }} enableSmartLabels={true} tooltip={{ enable: true }} >
                                    <Inject services={[AccumulationLegend, PieSeries, AccumulationDataLabel]} />
                                    <AccumulationSeriesCollectionDirective>
                                        <AccumulationSeriesDirective palettes={palette} name='Project' dataSource={donutData} xName='x' yName='y' innerRadius='40%' startAngle={0} endAngle={360} radius='70%' explode={true} explodeOffset='10%' explodeIndex={3} dataLabel={{
                                            visible: true,
                                            name: 'text',
                                            position: 'Inside',
                                            font: {
                                                fontWeight: '600',
                                                color: '#ffffff'
                                            }
                                        }}>
                                        </AccumulationSeriesDirective>
                                    </AccumulationSeriesCollectionDirective>
                                </AccumulationChartComponent>
                            </div>
                        </div>
                    </div>


                    <div className="col-md-6 col-sm-12 col-xs-12">
                        <div className="card card-warning">
                            <div className="card-header">
                                <h3 className="card-title">PBC Delivery Status</h3>
                                <div class="card-tools">
                                    <button type="button" onClick={() => openFullscreen("bar_chart")} class="btn btn-tool" data-card-widget="maximize"><i class="fas fa-expand"></i>
                                    </button>
                                </div>
                            </div>
                            <div className="card-body" id="bar_chart">
                                <ChartComponent
                                    id="charts_Bar"
                                    style={{ textAlign: 'center' }}
                                    primaryXAxis={{
                                        valueType: 'Category',
                                        interval: 1,
                                        majorGridLines: { width: 0 }
                                    }}
                                    primaryYAxis={{
                                        majorGridLines: { width: 0 },
                                        majorTickLines: { width: 0 },
                                        lineStyle: { width: 0 },
                                        // labelStyle: { color: 'transparent' }
                                    }}
                                    chartArea={{ border: { width: 0 } }}
                                    tooltip={{ enable: true }}
                                    pointRender={labelRender}
                                >
                                    <Inject
                                        services={[ColumnSeries, Legend, Tooltip, Category, DataLabel]}
                                    />
                                    <SeriesCollectionDirective>
                                        <SeriesDirective
                                            dataSource={data1}
                                            xName="x"
                                            yName="y"
                                            type="Column"
                                            marker={{
                                                dataLabel: {
                                                    visible: true,
                                                    position: 'Top',
                                                    font: { fontWeight: '600', color: '#ffffff' }
                                                }
                                            }}
                                        />
                                    </SeriesCollectionDirective>
                                </ChartComponent>


                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </>
    );
}
export default Dashboard;
