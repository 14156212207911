
import React, { useState, useEffect } from "react";
import {
    GridComponent,
    ColumnDirective,
    ColumnsDirective,
    Page,
    Edit,
    Toolbar,
    Inject, ColumnChooser, CommandColumn, Filter, PdfExport, ExcelExport, Sort, Reorder,Resize
} from "@syncfusion/ej2-react-grids";
import { ViewToaster } from "../ViewToaster";
import { UserRolePermission_Service } from "../../Services/UserRolePermission_Service";
import { Module_Service } from "../../Services/Module_Service"
import { Permission_Service } from "../../Services/Permission_Service";
import { Multiselect } from 'multiselect-react-dropdown';
import Swal from 'sweetalert2';

export const UserRolePermission = () => {


    let [userRolePermissionData, setuserRolePermissionData] = useState({
        userRoleId: 0,
        userTypeId: 0,
        roleId: 0,
        moduleId: 0,
        permissions: []

    });
    let { userTypeId, roleId, moduleId } = userRolePermissionData;

    useEffect(() => {
        userRolePermissionsList();
        userTypeList();
        moduleList();
        permissionList();
    }, []);

    ///user Role PermissionList
    const [userRolePermissionList, setuserRolePermissionList] = useState([]);
    const userRolePermissionsList = async () => {
        UserRolePermission_Service.getUserRolePermissionsList().then((result) => setuserRolePermissionList(result.data));
    }

    //User Type List
    const [userTypeData, setuserTypeData] = useState([]);
    const userTypeList = async () => {
        UserRolePermission_Service.getUserTypeList().then((result) => setuserTypeData(result.data));
    }

    //UserType Change
    const onuserTypeChange = e => {
        setuserRolePermissionData({ ...userRolePermissionData, [e.target.id]: e.target.value });
        roleList(e.target.value)
    };

    const onInputChange = e => {
        setuserRolePermissionData({ ...userRolePermissionData, [e.target.id]: e.target.value });
    };

    //Role List
    const [roleData, setroleData] = useState([]);
    const roleList = async (typeId) => {
        UserRolePermission_Service.getRoleListByUserType(typeId).then((result) => setroleData(result.data));
    }

    //Module LIst
    const [moduleData, setmoduleData] = useState([]);
    const moduleList = async () => {
        Module_Service.getModuleList().then((result) => setmoduleData(result.data));
    }

    //permission List
    let ArrayData = [];
    const [permissionData, setpermissionData] = useState([]);
    const permissionList = async () => {
        Permission_Service.getPermissionList().then((result) => {
            if (result.data.length > 0) {
                result.data.map((e, key) =>
                    ArrayData.push({ id: e.permissionId, name: e.permissionName })
                )
            }
            setpermissionData(ArrayData);
        })
    }


    const [permissionSelected, SetpermissionSelected] = useState([]);
    const [permissionSelectedIds, SetpermissionSelectedIds] = useState([]);

    // Select permission from  Dropdown
    const handleChange = (event) => {
        const selected = [];
        const selectedIds = [];
        let selectedOption = (event);
        for (let i = 0; i < selectedOption.length; i++) {
            selected.push(selectedOption[i].name)
            selectedIds.push(selectedOption[i].id)
        }
        SetpermissionSelected({ selected });
        SetpermissionSelectedIds({ selectedIds })
    }

    //Remove Select Permission from dropdown
    const remove = (event) => {
        const selected = [];
        const selectedIds = [];
        let selectedOption = (event);
        for (let i = 0; i < selectedOption.length; i++) {
            selected.push(selectedOption[i].name)
            selectedIds.push(selectedOption[i].id)
        }
        SetpermissionSelected({ selected });
        SetpermissionSelectedIds({ selectedIds })
    }



    //On Form Submiting
    function onSubmit() {
        const isValid = validate();
        if (isValid) {
            setValidationState({ userTypeError: "", roleError: "", moduleError: "", permissionsError: "" });
            //ids Comma Separate
            let SelectedPermissions = permissionSelected.selected.join();
            let SelectedPermissionsIds = permissionSelectedIds.selectedIds.join();

            UserRolePermission_Service.submitPermissions(SelectedPermissions, SelectedPermissionsIds, userRolePermissionData).then(result => {
                userRolePermissionsList();
                setuserRolePermissionData({
                    userRoleId: 0,
                    userTypeId: 0,
                    roleId: 0,
                    moduleId: 0,
                });
                setdefaultSelected([]);
                ViewToaster.getToaster(result.data.responseText, result.data.success);
            }).catch(err => ViewToaster.getToaster(err.response.data.responseText, err.response.data.success));

        }
    }

    const [defaultSelected, setdefaultSelected] = useState([])
    //Command Click Action Perform
    const commandClick = (args) => {
        if (args.commandColumn.type === "Edit") {
            setuserRolePermissionData(args.rowData)
            roleList(args.rowData.userTypeId)
            var ids = args.rowData.permissionsids.split(",");
            var names = args.rowData.permissions.split(",");
            let arrayids_name = []
            const selected = [];
            const selectedIds = [];

            for (let i = 0; i < ids.length; i++) {
                arrayids_name.push({ id: ids[i], name: names[i] })
                selected.push(names[i])
                selectedIds.push(ids[i])
            }
            setdefaultSelected(arrayids_name);
            SetpermissionSelected({ selected });
            SetpermissionSelectedIds({ selectedIds })
        }
        if (args.commandColumn.type === "Delete") {
            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
                if (result.isConfirmed) {
                    UserRolePermission_Service.deleteUserRolePermissionById(args.rowData.userRoleId)
                        .then(res => {
                            Swal.fire(
                                'Deleted!',
                                'Your file has been deleted.',
                                'success'
                            );
                            userRolePermissionsList();
                        }).catch(err => ViewToaster.getToaster(err.response.data.responseText, err.response.data.success));
                }
                if (result.isDismissed) {
                    userRolePermissionsList();
                }
            })
        }
    }
    ///Validation
    let userTypeError = "";
    let roleError = "";
    let moduleError = "";
    let permissionsError = "";
    const [validationstate, setValidationState] = useState(userTypeError, roleError, moduleError, permissionsError);
    const validate = () => {

        if (!userTypeId > 0) {
            userTypeError = "Please select user type";
        }
        if (!roleId > 0) {
            roleError = "Please select role";
        }
        if (!moduleId > 0) {
            moduleError = "Please select module";
        }
        if (permissionSelected.length === 0) {
            permissionsError = "Please select Permissions";
        }
        else if (permissionSelected.selected.length === 0) {
            permissionsError = "Please select Permissions";
        }
        if (userTypeError || roleError || moduleError || permissionsError) {
            setValidationState({ userTypeError, roleError, moduleError, permissionsError });
            return false;
        }
        return true;
    }

    const editOptions = {
        allowEditing: false,
        allowAdding: true,
        allowDeleting: true,
    };
    const toolbarOptions = ["Search"];

    const commands = [{ type: 'Edit', buttonOption: { iconCss: ' e-icons e-edit', cssClass: 'e-flat' } },
    { type: 'Delete', buttonOption: { iconCss: 'e-icons e-delete', cssClass: 'e-flat' } }];

    const filter = {
        type: 'Menu'
    };
    const check = {
        type: 'CheckBox'
    };

    return (
        <form>
            <div className="card">
                <div className="card-body">
                    <div class="row"><div class="col-md-12"><h5 class="card-text">Manage User Role & Permission</h5><hr></hr></div></div>
                    <div class="row">
                        <div class="col-md-4">
                            <div class="form-group">
                                <label className="form-label">User Type</label><span className="text-danger reqired-fields">*</span>
                                <select name="userTypeId" id="userTypeId" value={userTypeId} onChange={e => { onuserTypeChange(e) }} className='form-control '>
                                    <option value>-- Select User Type --</option>
                                    {
                                        userTypeData.map((e, key) => {
                                            return <option key={key} value={e.userTypeId} >{e.userTypeName}</option>;
                                        })
                                    }
                                </select>
                                <small class="text-danger">{validationstate.userTypeError}</small>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label className="form-label">Role</label><span className="text-danger reqired-fields">*</span>
                                <select name="roleId" id="roleId" value={roleId} onChange={e => { onInputChange(e) }} className='form-control '>
                                    <option value>-- Select Role --</option>
                                    {
                                        roleData.map((e, key) => {
                                            return <option key={key} value={e.roleId} >{e.roleName}</option>;
                                        })
                                    }
                                </select>
                                <small class="text-danger">{validationstate.roleError}</small>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label className="form-label">Module</label><span className="text-danger reqired-fields">*</span>
                                <select name="moduleId" id="moduleId" value={moduleId} onChange={e => { onInputChange(e) }} className='form-control '>
                                    <option value>-- Select Module --</option>
                                    {
                                        moduleData.map((e, key) => {
                                            return <option key={key} value={e.moduleId} >{e.moduleName}</option>;
                                        })
                                    }
                                </select>
                                <small class="text-danger">{validationstate.moduleError}</small>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group">
                                <label className="form-label">Permission</label><span className="text-danger reqired-fields">*</span>
                                <Multiselect
                                    options={permissionData} // Options to display in the dropdown
                                    selectedValues={defaultSelected} // Preselected value to persist in dropdown
                                    onSelect={handleChange.bind(this)} // Function will trigger on select event
                                    onRemove={remove.bind(this)} // Function will trigger on remove event
                                    displayValue="name" // Property name to display in the dropdown options
                                />
                                <small class="text-danger">{validationstate.permissionsError}</small>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div class="form-group text-right">
                                <button type="button" onClick={() => { onSubmit() }} className="btn btn-md btn-primary">Save </button>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div class="form-group row">
                            <div class="col-md-12">
                                <GridComponent id='grid'
                                    dataSource={userRolePermissionList}
                                    allowResizing={true}
                                    filterSettings={filter}
                                    allowFiltering={true}
                                    editSettings={editOptions}
                                    toolbar={toolbarOptions}
                                    allowSorting={true} allowPaging={true}
                                    showColumnChooser={true}
                                    pageSettings={{ pageCount: 5, pageSizes: true }}
                                    allowPdfExport={true} allowExcelExport={true}
                                    commandClick={commandClick.bind(this)}>
                                    <ColumnsDirective>
                                        <ColumnDirective filter={check} field="userTypeName" width='200' textAlign='Center' headerText="User Type" />
                                        <ColumnDirective filter={check} field="roleName" width='200' textAlign='Center' headerText="Role Name" />
                                        <ColumnDirective filter={check} field="moduleName" width='200' textAlign='Center' headerText="Module Name" />
                                        <ColumnDirective filter={filter} clipMode="EllipsisWithTooltip" field="permissions" width='200' textAlign='Center' headerText="Permissions" />
                                        <ColumnDirective allowReordering={false} headerText='Manage Records' width='200' textAlign='Center' commands={commands}></ColumnDirective>
                                    </ColumnsDirective>
                                    <Inject services={[Reorder,Resize, Sort, Edit, Page, Toolbar, ColumnChooser, CommandColumn, Filter, PdfExport, ExcelExport]} />
                                </GridComponent>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </form>

    );

}