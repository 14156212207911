import React, { useState, useEffect } from "react";
import {
    GridComponent,
    ColumnDirective,
    ColumnsDirective,
    Page,
    Edit,
    Toolbar,
    Inject, ColumnChooser, CommandColumn, Filter, PdfExport, ExcelExport, Grid, Sort, Reorder, Resize, Group, VirtualScroll, InfiniteScroll
} from "@syncfusion/ej2-react-grids";
import { user_Service } from "../../Services/user_Service";
import { UserRolePermission_Service } from "../../Services/UserRolePermission_Service";
import { ViewToaster } from "../ViewToaster";
import axios from 'axios'
import Swal from 'sweetalert2';
const Url = process.env.REACT_APP_API_URL;
export const UserAddEdit = (props) => {


    let [userData, setuserData] = useState({
        userId: 0,
        name: "",
        emailId: "",
        timeZoneId: "",
        mobileNo: "",
        roleId: 0,
        userTypeId: 0,
    });

    let { userTypeId, roleId, name, emailId, timeZoneId, mobileNo } = userData;


    useEffect(() => {
        getUserData();
        timeZoneList();
        userTypeList();
    }, []);

    const [timeZonedataList, settimeZonedataList] = useState([]);
    function timeZoneList() {
        user_Service.getTimeZoneList().then((result) => settimeZonedataList(result.data));
    }

    //User Type List
    const [userTypeData, setuserTypeData] = useState([]);
    const userTypeList = async () => {
        UserRolePermission_Service.getUserTypeList().then((result) => setuserTypeData(result.data));
    }

    //role List
    const [roleData, setroleData] = useState([]);
    const roleList = async (typeId) => {
        UserRolePermission_Service.getRoleListByUserType(typeId).then((result) => setroleData(result.data));
    }


    const onuserTypeChange = e => {
        setuserData({ ...userData, [e.target.id]: e.target.value });
        roleList(e.target.value)
    };

    const onInputChange = e => {
        setuserData({ ...userData, [e.target.id]: e.target.value });
    };

    // const [imageState, setimageState] = useState({ file: null });
    // const onFileChange = event => {
    //     setuserData({ ...userData, profilePhoto: URL.createObjectURL(event.target.files[0]) })
    //     setimageState({ file: event.target.files[0] });
    // };


    //Validating Inputes
    let nameError = "";
    let emailError = "";
    let mobileError = "";
    let usertypeError = "";
    let roleError = "";
    const [validationstate, setValidationState] = useState(nameError, emailError, mobileError, usertypeError, roleError);
    const validate = () => {
        if (!name.length > 0) {
            nameError = "Please Enter User Name";
        }

        if (emailId.length > 0) {
            var emailpattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            if (!emailpattern.test(emailId)) {
                emailError = "Please enter valid email address.";
            }
        }
        else {
            emailError = "Please enter email address";
        }

        if (mobileNo.length > 0) {
            var pattern = new RegExp(/^[0-9\b]+$/);
            if (!pattern.test(mobileNo)) {
                mobileError = "Please enter valid mobile no.";
            }
        }
        else {
            mobileError = "Please Enter mobile no.";
        }

        if (!userTypeId > 0) {
            usertypeError = "Please select usertype"
        }
        if (!roleId > 0) {
            roleError = "Please select role"
        }


        if (nameError || emailError || mobileError || usertypeError || roleError) {
            setValidationState({ nameError, emailError, mobileError, usertypeError, roleError });
            return false;
        }
        return true;
    };



    //Form Submiting
    const onSubmit = async () => {

        const isValid = validate();
        if (isValid) {
            setValidationState({ nameError: "", emailError: "", mobileError: "", usertypeError: "", roleError: "" });
            user_Service.addUpdateUser(userData).then(result => {
                getUserData();
                setuserData({
                    userId: 0,
                    name: "",
                    emailId: "",
                    timeZoneId: "",
                    mobileNo: "",
                    roleId: 0,
                    userTypeId: 0,

                });
                ViewToaster.getToaster(result.data.responseText, result.data.success);
            }).catch(err => ViewToaster.getToaster(err.response.data.responseText, err.response.data.success));

        }
    };

    ///Grid List
    const [userList, setuserList] = useState();
    //Get All Data for Grid
    const getUserData = async () => {
        user_Service.getUserList().then((result) =>
            setuserList(result.data)
        )
    }

    //Get User By Id
    const loadUserById = async (userId) => {
        user_Service.getUserById(userId).then((result) => {
            console.log(result);

            setuserData(result.data);
        }

        );
    };

    //Command Click Action Perform
    const commandClick = (args) => {
        if (args.commandColumn.type === "Edit") {
            loadUserById(args.rowData.userId)
            //setuserData({ ...userData, userTypeId: args.rowData.userTypeId })

            roleList(args.rowData.userTypeId)
        }
        //Delete PCBRequest
        if (args.commandColumn.type === 'Delete') {


            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
                if (result.isConfirmed) {

                    user_Service.deleteUserById(args.rowData.userId)
                        .then(res => {
                            Swal.fire(
                                'Deleted!',
                                'Your file has been deleted.',
                                'success'
                            );
                            getUserData();
                        }).catch(err => ViewToaster.getToaster(err.response.data.responseText, err.response.data.success));
                }
                if (result.isDismissed) {
                    getUserData();
                }
            })


            // const confirmBox = window.confirm(
            //     "Do you really want to delete?"
            // )
            // if (confirmBox === true) {
            //     user_Service.deleteUserById(args.rowData.userId)
            //         .then(res => {
            //             getUserData();
            //             ViewToaster.getToaster(res.data.responseText, res.data.success);
            //         }).catch(err => ViewToaster.getToaster(err.response.data.responseText, err.response.data.success));
            // }
        }
    }
    const editOptions = {
        allowEditing: false,
        allowAdding: true,
        allowDeleting: true,
    };
    const toolbarOptions = ["Search"];

    const commands = [{ type: 'Edit', buttonOption: { iconCss: ' e-icons e-edit', cssClass: 'e-flat' } },
    { type: 'Delete', buttonOption: { iconCss: 'e-icons e-delete', cssClass: 'e-flat' } }];

    const filter = {
        type: 'Menu'
    };
    const check = {
        type: 'CheckBox'
    };
    return (
        <form>
            <div className="card">
                <div className="card-body">
                    <div class="row"><div class="col-md-12"><h5 class="card-text">Manage User</h5><hr></hr></div></div>
                    <div class="row">
                        <div className={props.ShowList == false ? "col-md-6 col-sm-12 col-xs-12" : "col-md-4 col-sm-12 col-xs-12"} >
                            <div class="form-body">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label htmlFor="name" className="form-label">Name</label><span className="text-danger reqired-fields">*</span>
                                            <input type="text" value={name} onChange={e => onInputChange(e)} className="form-control" id="name" placeholder="Enter User Name" />
                                            <small class="text-danger">{validationstate.nameError}</small>
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label htmlFor="emailId" className="form-label">EmailId</label><span className="text-danger reqired-fields">*</span>
                                            <input type="text" value={emailId} onChange={e => onInputChange(e)} className="form-control" id="emailId" placeholder="Enter EmailId" />
                                            <small class="text-danger">{validationstate.emailError}</small>
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label htmlFor="mobileNo" className="form-label">Mobile No</label><span className="text-danger reqired-fields">*</span>
                                            <input type="text" value={mobileNo} onChange={e => onInputChange(e)} className="form-control" id="mobileNo" placeholder="Enter 10 Digit Mobile No." />
                                            <small class="text-danger">{validationstate.mobileError}</small>
                                        </div>
                                    </div>
                                    {/* <div class="col-md-12">
                                    <div class="form-group">
                                        <label htmlFor="timeZoneId" className="form-label">Select TimeZone</label><span className="text-danger reqired-fields">*</span>
                                        <select name="timeZoneId" id="timeZoneId" value={timeZoneId} onChange={e => { onInputChange(e) }} className='form-control'>
                                            <option value>-- Select Time Zone --</option>
                                            {
                                                timeZonedataList.map((e, key) => {
                                                    return <option key={key} value={e.timeZoneId} >{e.name}-{e.utc}</option>;
                                                })
                                            }
                                        </select>
                                        <small class="text-danger">{validationstate.timezoneError}</small>
                                    </div>
                                </div> */}
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label className="form-label">User Type</label><span className="text-danger reqired-fields">*</span>
                                            <select name="userTypeId" id="userTypeId" value={userTypeId} onChange={e => { onuserTypeChange(e) }} className='form-control '>
                                                <option value>-- Select User Type --</option>
                                                {
                                                    userTypeData.map((e, key) => {
                                                        return <option key={key} value={e.userTypeId} >{e.userTypeName}</option>;
                                                    })
                                                }
                                            </select>
                                            <small class="text-danger">{validationstate.usertypeError}</small>
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label htmlFor="roleId" className="form-label">Role</label><span className="text-danger reqired-fields">*</span>
                                            <select name="roleId" id="roleId" value={roleId} onChange={e => { onInputChange(e) }} className='form-control'>
                                                <option value>-- Select Role --</option>
                                                {
                                                    roleData.map((e, key) => {
                                                        return <option key={key} value={e.roleId} >{e.roleName}</option>;
                                                    })
                                                }
                                            </select>
                                            <small class="text-danger">{validationstate.roleError}</small>
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <div class="form-group text-right">
                                            {<button type="button" onClick={() => { onSubmit() }} className="btn btn-md btn-primary"> Save</button>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {props.ShowList == true || props.ShowList == undefined ? <div class="col-md-8 col-sm-12 col-xs-12">
                            <GridComponent id='grid'
                                dataSource={userList}
                                allowResizing={true}
                                filterSettings={filter}
                                allowFiltering={true}
                                editSettings={editOptions}
                                toolbar={toolbarOptions}
                                allowSorting={true} allowPaging={true}
                                showColumnChooser={true}
                                pageSettings={{ pageCount: 5, pageSizes: true }}
                                allowPdfExport={true} allowExcelExport={true}
                                commandClick={commandClick.bind(this)}>
                                <ColumnsDirective>
                                    <ColumnDirective filter={filter} field="name" width='200' textAlign='Center' headerText="Name" />
                                    <ColumnDirective filter={filter} field="emailId" width='200' textAlign='Center' headerText="EmailId" />
                                    <ColumnDirective filter={filter} field="mobileNo" width='200' textAlign='Center' headerText="Mobile No" />
                                    <ColumnDirective filter={check} field="roleName" width='200' textAlign='Center' headerText="Role" />
                                    <ColumnDirective allowReordering={false} headerText='Manage Records' width='200' textAlign='Center' commands={commands}></ColumnDirective>
                                </ColumnsDirective>
                                <Inject services={[Reorder, Resize, Sort, Edit, Page, Toolbar, ColumnChooser, CommandColumn, Filter, PdfExport, ExcelExport]} />
                            </GridComponent>
                        </div> : ""}

                    </div>
                </div>
            </div>

        </form>
    );

}