import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";

export const MyProfile = (props) => {
    const onInputChange = e => { }

    const [isopen, setisopen] = useState(false);
    const openAboutModel = () => {
        setisopen(true);
    }
    const closeAboutModel = () => {
        setisopen(false);
    }

    return (
        <div className="container py-3">


            <div class="content-body">
                <div class="row justify-content-md-center">
                    <div class="col-md-5">
                        <div class="card">
                            <div class="card-header">
                                <div class="card-text text-center">
                                    <h5 class="card-text">My Profile</h5>
                                </div>
                            </div>

                            <div class="card-body">
                                <div class="form-body">
                                    <div class="form-group text-right">
                                        <a href="javascript:" onClick={() => openAboutModel()}>Change Password</a>
                                    </div>
                                    <div class="form-group">
                                        <label htmlFor="name" className="form-label">Display Name</label><span className="text-danger reqired-fields">*</span>
                                        <input type="text" onChange={e => onInputChange(e)} className="form-control" id="name" placeholder="Enter Full Name" />
                                    </div>
                                    <div class="form-group">
                                        <label htmlFor="name" className="form-label">Role</label><span className="text-danger reqired-fields">*</span>
                                        <select name="timeZoneId" id="timeZoneId" onChange={e => { onInputChange(e) }} className='form-control'>
                                            <option value>-- Select Role --</option>
                                        </select>
                                    </div>

                                    <div class="form-group">
                                        <label htmlFor="name" className="form-label">Points</label><span className="text-danger reqired-fields">*</span>
                                        <input type="text" onChange={e => onInputChange(e)} className="form-control" id="name" placeholder="Enter Points" />
                                    </div>

                                    <div class="form-group">
                                        <label htmlFor="name" className="form-label">TimeZone</label><span className="text-danger reqired-fields">*</span>
                                        <select name="timeZoneId" id="timeZoneId" onChange={e => { onInputChange(e) }} className='form-control'>
                                            <option value>-- Select Time Zone --</option>
                                        </select>
                                    </div>

                                    <div class="form-actions text-right">
                                        <button type="submit" class="btn btn-md btn-primary" id="btnSubmit">
                                            <i class="la la-check-square-o"></i> Submit
                                        </button>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <Modal
                show={isopen}
                onHide={closeAboutModel}
                size="md" backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>Reset Password</Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <div class="content-body">
                        <div class="row justify-content-md-center">
                            <div class="col-md-12">
                                <div class="card">

                                    <div class="card-body">
                                        <div class="form-body">
                                            <div class="form-group">
                                                <label htmlFor="name" className="form-label">Enter Old Password</label><span className="text-danger reqired-fields">*</span>
                                                <input type="text" onChange={e => onInputChange(e)} className="form-control" id="name" placeholder="Enter Old Password" />
                                            </div>
                                            <div class="form-group">
                                                <label htmlFor="name" className="form-label">Enter New Password</label><span className="text-danger reqired-fields">*</span>
                                                <input type="text" onChange={e => onInputChange(e)} className="form-control" id="name" placeholder="Enter New Password" />
                                            </div>
                                            <div class="form-group">
                                                <label htmlFor="name" className="form-label">Enter Confirm Password</label><span className="text-danger reqired-fields">*</span>
                                                <input type="text" onChange={e => onInputChange(e)} className="form-control" id="name" placeholder="Enter Confirm Password" />
                                            </div>


                                            <div class="form-actions text-right">
                                                <button type="submit" class="btn btn-md btn-primary" id="btnSubmit">
                                                    <i class="la la-check-square-o"></i> Submit
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                </Modal.Body>
            </Modal>
        </div>
    )
}