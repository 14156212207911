import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios'
toast.configure()
const Url = process.env.REACT_APP_API_URL;
export const Documents_Service = {
    addDocumentLink,
    getDocumentsList,
    getTypeOfImage,
    deleteDocuments,
    addNoAttachments,
    getDocumentsListWithNoAttchments,
    addSubmission,
    approveORRejectDocument,
    finalDocumentSubmission,
    getAuditTrialList
}

// Add Document links.
async function addDocumentLink(pbcRequestId, UrlLink) {
    const result = await axios.post(`${Url}pbcRequest/addDocumentLink?id=${pbcRequestId}&link=${UrlLink}`);
    return result;
}

// On submiting Data
async function addSubmission(userId, pbcRequestId, comment, requesttype, selectedvalue) {
    const result = await axios.post(`${Url}pbcRequest/addSubmission?userId=${userId}&pbcRequestId=${pbcRequestId}&requestType=${requesttype}&comment=${comment}&selectedvalue=${selectedvalue}`);
    return result;
}

async function getDocumentsList(pbcRequestId) {
    const result = await axios.get(`${Url}pbcRequest/getDocumentsList?id=${pbcRequestId}`);
    return result;
}

async function getDocumentsListWithNoAttchments(pbcRequestId) {
    const result = await axios.get(`${Url}pbcRequest/getDocumentsWithNoAttachmentsList?id=${pbcRequestId}`);
    return result;
}

async function getTypeOfImage(props) {
    // var cssClass = "";

    // if (props != undefined) {
    //     if (props.pbcDocName.includes('.pdf')) {
    //         cssClass = "e-icons e-M_PDFExport"
    //     }
    //     else if (props.pbcDocName.includes('.xlsx') || props.pbcDocName.includes('.xls')) {
    //         cssClass = "e-icons e-Excel_Export"
    //     }
    //     else if (props.pbcDocName.includes('.doc') || props.pbcDocName.includes('.docx')) {
    //         cssClass = "e-icons e-M_PV_NewDocument"
    //     }
    //     else if (props.pbcDocName.includes('.jpeg') || props.pbcDocName.includes('.png') || props.pbcDocName.includes('.jpg')) {
    //         cssClass = "e-icons e-M_Image"
    //     }
    //     else {
    //         cssClass = ""
    //     }
    // }
    // return (<div style={{ fontSize: "25px" }} > <span class={cssClass}></span>
    // </div>);

    var cssClass = props.pbcDocName.includes('.pdf') ? "e-icons e-M_PDFExport" : props.pbcDocName.includes('.xlsx') || props.pbcDocName.includes('.xls') ? 'e-icons e-Excel_Export' : props.pbcDocName.includes('.jpeg') || props.pbcDocName.includes('.png') || props.pbcDocName.includes('.jpg') ? 'e-icons e-M_Image' : '';
    return (<div style={{ fontSize: "25px" }} > <span class={cssClass}></span>
    </div>);
}

function deleteDocuments(pbcRequestId, pbcDocumentsId, caDocId, projectId, controlId,userId) {
    var result = axios.post(`${Url}pbcRequest/deleteDocuments?id=${pbcRequestId}&pbcDocumentsId=${pbcDocumentsId}&caDocId=${caDocId}&projectId=${projectId}&controlId=${controlId}&userId=${userId}`);
    return result
}

async function addNoAttachments(pbcRequestId, value) {
    const result = await axios.post(`${Url}pbcRequest/pbcRequestUpdateNoAttachments?id=${pbcRequestId}&value=${value}`);
    return result;
}

async function approveORRejectDocument(caDocId, status,pbcrequestId,userId) {
    const result = await axios.post(`${Url}pbcRequest/approveORRejectDocument?docId=${caDocId}&status=${status}&pbcRequestId=${pbcrequestId}&userId=${userId}`);
    return result;
}

async function finalDocumentSubmission(pbcRequestId, userId) {
    const result = await axios.post(`${Url}pbcRequest/documentsFinalSubmit?pbcRequestId=${pbcRequestId}&userId=${userId}`);
    return result;
}

async function getAuditTrialList(pbcRequestId) {
    const result = await axios.get(`${Url}auditTrial/getAuditTrialList?id=${pbcRequestId}`);
    return result;
}