import React, { useState, useEffect } from "react";
import axios from 'axios'
import { ViewToaster } from "./ViewToaster";
import Parser from 'html-react-parser';

export const Comments = (props) => {
    const Url = process.env.REACT_APP_API_URL;

    let [PBCComments, setPBCComments] = useState({ comment: "" });

    const onInputChange = e => {
        setPBCComments({ ...PBCComments, [e.target.id]: e.target.value });
    };

    console.log(props.documentid);

    //Validating Inputes
    let CommentError = "";
    const [validationstate, setValidationState] = useState(CommentError);
    const validate = () => {

        if (PBCComments.comment === "") {
            CommentError = "Please Enter Comment";
        }
        if (CommentError) {
            setValidationState({ CommentError });
            return false;
        }
        return true;
    }

    const [disableButton, setDisableButton] = useState(false);


    //Insert Comment Of PBC Request Documents
    const onSubmit = async () => {
        setDisableButton(true);
        const isValid = validate();
        if (isValid) {
            setValidationState({ CommentError: "" });
            const objdata = {
                PBCRequestId: props.pbcRequestId,
                PBCComment: PBCComments.comment,
                CADocId: props.documentid
            };
            await axios.post(`${Url}pbcRequest/addComment/`, objdata)
                .then(res => {
                    ViewToaster.getToaster(res.data.responseText, res.data.success);
                    getCommentsList();
                }).catch(err => ViewToaster.getToaster(err.response.data.responseText, err.response.data.success));
            setPBCComments({ comment: "" });
            setDisableButton(false);
        }
        setDisableButton(false);
    };

    const [commentsList, setCommentsList] = useState([]);
    ///Get Comments List
    const getCommentsList = async () => {
        const result = await axios.get(`${Url}pbcRequest/getPBCRequestCommentsList?id=${props.pbcRequestId}&caDocId=${props.documentid}`);
        setCommentsList(result.data);
    }

    let listItems = [];
    if (commentsList.length > 0) {
        listItems = commentsList.map((e, key) =>
            <li style={{ listStyle: "none" }} key={key}>
                <p style={{ padding: "10px 5px", background: "#e4e4e4", borderRadius: "5px", marginBottom: "2px", width: "90%" }}>   {Parser(e.pbcComment)}</p>
                <p style={{ fontStyle: "italic", textAlign: "right", width: "90%", marginBottom: "15px", fontSize: "12px", color: "#043A05" }}>{e.lastUpdateByEmailId}, {e.lastUpdateDateTime}</p>
            </li>
        );
    }

    useEffect(() => {
        if (props.documentid > 0) {

            setInterval(() => {
                getCommentsList();
            }, 1000);
        }
    }, []);

    return (

        <div className="container my-3">
            <div>
                <h4>Document Name:</h4><p>{props.documentname}</p>
            </div>
            <div class="form-group row">
                <div class="col-md-12">
                    <div className="mb-3">
                        <div class="form-group row">
                            <div class="col-md-12">
                                <div style={{ overflowY: "scroll", height: "300px" }}>
                                    {listItems}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-12">
                    <div className="mb-3">
                        <div class="form-group row">
                            <div class="col-md-9">
                                <label htmlFor="additionalField10" className="form-label">Enter Comment</label><span className="text-danger reqired-fields">*</span>
                                <textarea value={PBCComments.comment} disabled={props.commentsPermission.includes("Create") ? false : true} onChange={e => { onInputChange(e) }} rows="3" cols="40" type="text" className="form-control" id="comment" placeholder="Enter Comment" />
                                <small class="text-danger">{validationstate.CommentError}</small>
                            </div>
                            {
                                PBCComments.comment != "" ? <div className="col-md-3 pt-5" >
                                    <button type="button" disabled={props.commentsPermission.includes("Create") && disableButton === false ? false : true} onClick={() => { onSubmit() }} id="clsSaveData" className="btn btn-md btn-primary"> Save</button>
                                </div> : ""
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}