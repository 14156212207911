import Cookies from 'universal-cookie';
import axios from 'axios'

const Url = process.env.REACT_APP_API_URL;
var CryptoJS = require("crypto-js");
export const Common_Service = {
    getCookies,
    getSystemCookie,
    getpermissionByUser
}

const cookies = new Cookies();
function getCookies() {
    const objUserId = cookies.get('_data');
    if (objUserId !== undefined) {
        var bytes = CryptoJS.AES.decrypt(objUserId, process.env.REACT_APP_Secrect_Key);
        var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        return decryptedData;
    }
    else
        return decryptedData;
}

function getSystemCookie() {
    const objIsSystem = cookies.get('_IsSystem');
    if (objIsSystem !== undefined) {
        return objIsSystem;
    }
    else {
        return false
    }
}


async function getpermissionByUser(userId, moduleName) {
    const result = await axios.get(`${Url}pbcRequest/checkLoginUserPermissions?userId=${userId}&moduleName=${moduleName}`);
    return result;
}