import React, { useState, useEffect } from "react";
import {
    GridComponent,
    ColumnDirective,
    ColumnsDirective,
    Page,
    Edit,
    Toolbar,
    Inject, ColumnChooser, CommandColumn, Filter, PdfExport, ExcelExport, Grid, Sort, Reorder
} from "@syncfusion/ej2-react-grids";
import { Module_Service } from "../../Services/Module_Service";
import { ViewToaster } from "../ViewToaster";
import Swal from 'sweetalert2';
export const ModuleAddEdit = (props) => {


    useEffect(() => {
        getModuleData();
    }, []);

    let [moduleData, setmoduleData] = useState({
        moduleId: 0,
        moduleName: ""
    });

    let { moduleName } = moduleData;
    const onInputChange = e => {
        setmoduleData({ ...moduleData, [e.target.id]: e.target.value });
    };

    //Form Submiting
    const onSubmit = async () => {
        const isValid = validate();
        if (isValid) {
            setValidationState({ moduleError: "" });
            Module_Service.submitModuleData(moduleData).then(result => {
                ViewToaster.getToaster(result.data.responseText, result.data.success);
                getModuleData();
                setmoduleData({ moduleId: 0, moduleName: "" });

            }).catch(err => ViewToaster.getToaster(err.response.data.responseText, err.response.data.success));
        }
    };


    //Validating Inputes
    let moduleError = "";
    const [validationstate, setValidationState] = useState(moduleError);
    const validate = () => {


        if (!moduleName.length > 0) {
            moduleError = "Please Enter Module Name";
        }

        if (moduleError) {
            setValidationState({ moduleError });
            return false;
        }
        return true;
    };

    ///Grid List
    const [moduleList, setmoduleList] = useState();
    //Get All Data for Grid
    const getModuleData = async () => {
        Module_Service.getModuleList().then((result) => setmoduleList(result.data))
    }

    //Get Module By Id
    const loadPBCRequestById = async (moduleId) => {
        Module_Service.moduleDataById(moduleId).then((result) => setmoduleData(result.data));
    };

    //Command Click Action Perform
    const commandClick = (args) => {
        if (args.commandColumn.type === "Edit") {
            loadPBCRequestById(args.rowData.moduleId)
        }
        //Delete PCBRequest
        if (args.commandColumn.type === 'Delete') {
            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
                if (result.isConfirmed) {
                    Module_Service.deleteModuleById(args.rowData.moduleId)
                        .then(res => {
                            Swal.fire(
                                'Deleted!',
                                'Your file has been deleted.',
                                'success'
                            );
                            getModuleData();
                        }).catch(err => ViewToaster.getToaster(err.response.data.responseText, err.response.data.success));
                }
                if (result.isDismissed) {
                    getModuleData();
                }
            })
        }
    }

    const editOptions = {
        allowEditing: false,
        allowAdding: true,
        allowDeleting: true,
    };
    const toolbarOptions = ["Search"];

    const commands = [{ type: 'Edit', buttonOption: { iconCss: ' e-icons e-edit', cssClass: 'e-flat' } },
    { type: 'Delete', buttonOption: { iconCss: 'e-icons e-delete', cssClass: 'e-flat' } }];


    return (
        <form>
            <div className="container pt-20">
                <div class="row"><div class="col-md-12"><h5 class="card-text">Manage Module</h5><hr></hr></div></div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label htmlFor="moduleName" className="form-label">Enter Module Name</label><span className="text-danger reqired-fields">*</span>
                                    <input type="text" value={moduleName} onChange={e => onInputChange(e)} className="form-control" id="moduleName" placeholder="Enter Module Name" />
                                    <small class="text-danger">{validationstate.moduleError}</small>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div class="form-group text-right">
                                    {<button type="button" onClick={() => { onSubmit() }} className="btn btn-md btn-primary"> Save</button>}
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="col-md-6">
                        <GridComponent id='grid'
                            dataSource={moduleList}
                            editSettings={editOptions}
                            toolbar={toolbarOptions}
                            allowSorting={true} allowPaging={true}
                            showColumnChooser={true}
                            pageSettings={{ pageCount: 5, pageSizes: true }}
                            allowPdfExport={true} allowExcelExport={true}
                            commandClick={commandClick.bind(this)}>
                            <ColumnsDirective>
                                <ColumnDirective field="moduleName" width='200' textAlign='Center' headerText="Module Name" />
                                <ColumnDirective allowReordering={false} headerText='Manage Records' width='200' textAlign='Center' commands={commands}></ColumnDirective>
                            </ColumnsDirective>
                            <Inject services={[Reorder, Sort, Edit, Page, Toolbar, ColumnChooser, CommandColumn, Filter, PdfExport, ExcelExport]} />
                        </GridComponent>
                    </div>
                </div>
            </div>

        </form>
    );

}