import React, { useState, useEffect } from "react";
import { PBCRequest_Service } from "../Services/PBCRequest_Service";
import { Common_Service } from "../Services/Common_Service";
import {
  GridComponent,
  ColumnDirective,
  ColumnsDirective,
  Page,
  Edit,
  Toolbar,
  Inject, ColumnChooser, CommandColumn, Filter, PdfExport, ExcelExport, Grid, Sort, Reorder, Group, VirtualScroll, Resize, InfiniteScroll
} from "@syncfusion/ej2-react-grids";
import { AddEditPBCRequest } from './AddEditPBCRequest';
import { ViewToaster } from "./ViewToaster";
import Modal from "react-bootstrap/Modal";
import { Documents } from "./Documents";
import { MassUpdate } from "./MassUpdate";
import $ from 'jquery';
import FileSaver from 'file-saver';
import axios from 'axios'
import Swal from 'sweetalert2'
import AuditName from './Common/AuditName'

export class PBCRequestList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      headerText: [],
      selectedColumns: [],
      isOpenPbcrequest: false,
      isaddNew: false,
      values: {},
      title: "",
      isOpen: false,
      isDocumentAuditorOpen: false,
      isDocument_AtchmntOpen: false,
      isDocument_clrn_Open: false,
      cmtpermissions: [],
      docpermissions: [],
      pbcpermissions: [],
      buttons: [],
      pbcdata: [],
      pbcEditable: true,
      isaddedit: false,
      objroleId: 0,
      objroleName: "",
      objuserName: "",
      alreadyInProgress: false
    };
    this.Url = process.env.REACT_APP_API_URL;
    this.editOptions = {
      allowEditing: false,
      allowAdding: true,
      allowDeleting: true,
      mode: "Dialog",
    };

    //CheckBox
    this.select = {
      persistSelection: true,
      type: "Multiple",
      checkboxOnly: true,
      enableSimpleMultiRowSelection: true,
      selectionMode: ["row"]
    };


    this.sortingOptions = {
      columns: [
        { field: 'pbcRequestId', direction: 'Ascending' },
      ]
    };

    // For Filtering
    this.filter = {
      type: 'Menu'
    };
    this.check = {
      type: 'CheckBox'
    };

    this.groupOptions = { showGroupedColumn: false, columns: ['status'] };
    this.toolbarOptions = [{ tooltipText: 'Start Work Flow', id: 'setworkflow', align: 'left', prefixIcon: 'e-icons e-Material_Play' }, { text: 'Add New', tooltipText: 'Add New Record', id: 'AddNew', align: 'left', prefixIcon: 'e-icons e-add-new' }, { text: 'Bulk Modify', tooltipText: 'Bulk Modify', id: 'MassUpdate', align: 'left', prefixIcon: 'e-icons e-add-new' }, { text: 'Save Current View', tooltipText: 'Save Current View', id: 'currentview', align: 'right', prefixIcon: 'e-icons e-add-new' }, "ExcelExport", 'ColumnChooser', "Search",]; //'ColumnChooser',
    this.commands = [{ type: 'Edit', buttonOption: { iconCss: 'e-icons e-edit', cssClass: 'e-flat' } },
    { type: 'PBC Document Upload', buttonOption: { iconCss: 'e-icons e-MT_Upload', cssClass: 'e-flat' } },
    { type: 'DownloadZip', buttonOption: { iconCss: 'e-icons e-M_PV_Download', cssClass: 'e-flat' } },
    { type: 'Delete', buttonOption: { iconCss: 'e-icons e-delete', cssClass: 'e-flat' } }];

    this.grid = Grid;
    this.userId = null;
    this.selected = [];
    this.listItems = [];
    this.PbcAddedit = "";


  }

  getrolenameByUserId(userId) {
    PBCRequest_Service.getroleByUserId(userId).then((result) => {
      this.setState({
        objroleId: result.data.data.roleId,
        objroleName: result.data.data.roleName,
        objuserName: result.data.data.name
      })
    })
  }

  // pbc Request List
  getAllData = async () => {
    PBCRequest_Service.getPBCRequestList(this.userId).then((result) =>
      this.setState({ data: result.data }))
  }

  getHeaderTexts = async () => {
    PBCRequest_Service.getHeaderText().then((result) => {
      this.setState({ headerText: result.data });
    })
  }

  getuserIdByCookie() {
    let objuserId = Common_Service.getCookies();
    return objuserId;
  }

  getPermissionsByUser(objuserId) {
    const result = Common_Service.getpermissionByUser(objuserId, "PBC Grid").then(res => {
      if (res.data.length > 0) {
        let objString = res.data.split(',');

        objString.forEach(element => {
          let splitString = "";
          if (element.includes("PBC Grid")) {
            splitString = element.split('|');
            this.setState({ pbcpermissions: splitString })
          }
          if (element.includes("PBC Comments")) {
            splitString = element.split('|');
            this.setState({ cmtpermissions: splitString })
          }
          if (element.includes("PBC Documents")) {
            splitString = element.split('|');
            this.setState({ docpermissions: splitString })
          }
        });
      }
    })
  }



  componentDidMount() {

    this.userId = this.getuserIdByCookie();
    this.getAllData();
    this.getHeaderTexts();
    setInterval(()=>{this.getAllData(); this.getHeaderTexts();}, 180000);

    if (this.grid == null) {
    }
    else {
      if (this.grid !== 0) {
        if (this.grid != null) {
          setTimeout(() => {
            if (this.grid != null) {
              this.grid.refresh();

              const pbcRequestIdcolumn = this.grid.getColumnByField("pbcRequestId");
              pbcRequestIdcolumn.headerText = this.state.headerText.pbcrequestId.columnName;
              pbcRequestIdcolumn.visible = this.state.headerText.pbcrequestId.visibility;
              pbcRequestIdcolumn.showInColumnChooser = this.state.headerText.pbcrequestId.visibility;

              const statuscolumn = this.grid.getColumnByField("status");
              statuscolumn.headerText = this.state.headerText.status.columnName;
              statuscolumn.visible = this.state.headerText.status.visibility;
              statuscolumn.showInColumnChooser = this.state.headerText.status.visibility;

              const processAreaNamecolumn = this.grid.getColumnByField("processAreaName");
              processAreaNamecolumn.headerText = this.state.headerText.processArea.columnName;
              processAreaNamecolumn.visible = this.state.headerText.processArea.visibility;
              processAreaNamecolumn.showInColumnChooser = this.state.headerText.processArea.visibility;

              const subProcessAreacolumn = this.grid.getColumnByField("subProcessAreaName");
              subProcessAreacolumn.headerText = this.state.headerText.subProcessArea.columnName;
              subProcessAreacolumn.visible = this.state.headerText.subProcessArea.visibility;
              subProcessAreacolumn.showInColumnChooser = this.state.headerText.subProcessArea.visibility;

              const controlDesccolumn = this.grid.getColumnByField("controlDesc");
              controlDesccolumn.headerText = this.state.headerText.controlDesc.columnName;
              controlDesccolumn.visible = this.state.headerText.controlDesc.visibility;
              controlDesccolumn.showInColumnChooser = this.state.headerText.controlDesc.visibility;

              const pbcDesccolumn = this.grid.getColumnByField("pbcDesc");
              pbcDesccolumn.headerText = this.state.headerText.pbcdesc.columnName;
              pbcDesccolumn.visible = this.state.headerText.pbcdesc.visibility;
              pbcDesccolumn.showInColumnChooser = this.state.headerText.pbcdesc.visibility;

              const requestItemSecuritycolumn = this.grid.getColumnByField("requestItemSecurity");
              requestItemSecuritycolumn.headerText = this.state.headerText.requestItemSecurity.columnName;
              requestItemSecuritycolumn.visible = this.state.headerText.requestItemSecurity.visibility;
              requestItemSecuritycolumn.showInColumnChooser = this.state.headerText.requestItemSecurity.visibility;

              const pbcRequestDueDatecolumn = this.grid.getColumnByField("pbcRequestDueDate");
              pbcRequestDueDatecolumn.headerText = this.state.headerText.pbcrequestDueDate.columnName;
              pbcRequestDueDatecolumn.visible = this.state.headerText.pbcrequestDueDate.visibility;
              pbcRequestDueDatecolumn.showInColumnChooser = this.state.headerText.pbcrequestDueDate.visibility;

              const documentsCountcolumn = this.grid.getColumnByField("documentsCount");
              documentsCountcolumn.headerText = this.state.headerText.documentsCount.columnName;
              documentsCountcolumn.visible = this.state.headerText.documentsCount.visibility;
              documentsCountcolumn.showInColumnChooser = this.state.headerText.documentsCount.visibility;

              const commentsCountcolumn = this.grid.getColumnByField("commentsCount");
              commentsCountcolumn.headerText = this.state.headerText.commentsCount.columnName;
              commentsCountcolumn.visible = this.state.headerText.commentsCount.visibility;
              commentsCountcolumn.showInColumnChooser = this.state.headerText.commentsCount.visibility;

              const auditTeamMembercolumn = this.grid.getColumnByField("auditTeamMember");
              auditTeamMembercolumn.headerText = this.state.headerText.auditTeamMember.columnName;
              auditTeamMembercolumn.visible = this.state.headerText.auditTeamMember.visibility;
              auditTeamMembercolumn.showInColumnChooser = this.state.headerText.auditTeamMember.visibility;

              const auditTeamManagercolumn = this.grid.getColumnByField("auditTeamManager");
              auditTeamManagercolumn.headerText = this.state.headerText.auditTeamManager.columnName;
              auditTeamManagercolumn.visible = this.state.headerText.auditTeamManager.visibility;
              auditTeamManagercolumn.showInColumnChooser = this.state.headerText.auditTeamManager.visibility;

              const controlOwnerEmailIdcolumn = this.grid.getColumnByField("controlOwnerEmailId");
              controlOwnerEmailIdcolumn.headerText = this.state.headerText.controlOwnerEmailId.columnName;
              controlOwnerEmailIdcolumn.visible = this.state.headerText.controlOwnerEmailId.visibility;
              controlOwnerEmailIdcolumn.showInColumnChooser = this.state.headerText.controlOwnerEmailId.visibility;

              const escalationEmailIdscolumn = this.grid.getColumnByField("escalationEmailIds");
              escalationEmailIdscolumn.headerText = this.state.headerText.escalationEmailIds.columnName;
              escalationEmailIdscolumn.visible = this.state.headerText.escalationEmailIds.visibility;
              escalationEmailIdscolumn.showInColumnChooser = this.state.headerText.escalationEmailIds.visibility;

              const additionalField1column = this.grid.getColumnByField("additionalField1");
              additionalField1column.headerText = this.state.headerText.additionalField1.columnName;
              additionalField1column.visible = this.state.headerText.additionalField1.visibility;
              additionalField1column.showInColumnChooser = this.state.headerText.additionalField1.visibility;

              const additionalField2column = this.grid.getColumnByField("additionalField2");
              additionalField2column.headerText = this.state.headerText.additionalField2.columnName;
              additionalField2column.visible = this.state.headerText.additionalField2.visibility;
              additionalField2column.showInColumnChooser = this.state.headerText.additionalField2.visibility;

              const additionalField3column = this.grid.getColumnByField("additionalField3");
              additionalField3column.headerText = this.state.headerText.additionalField3.columnName;
              additionalField3column.visible = this.state.headerText.additionalField3.visibility;
              additionalField3column.showInColumnChooser = this.state.headerText.additionalField3.visibility;

              const additionalField4column = this.grid.getColumnByField("additionalField4");
              additionalField4column.headerText = this.state.headerText.additionalField4.columnName;
              additionalField4column.visible = this.state.headerText.additionalField4.visibility;
              additionalField4column.showInColumnChooser = this.state.headerText.additionalField4.visibility;

              const additionalField5column = this.grid.getColumnByField("additionalField5");
              additionalField5column.headerText = this.state.headerText.additionalField5.columnName;
              additionalField5column.visible = this.state.headerText.additionalField5.visibility;
              additionalField5column.showInColumnChooser = this.state.headerText.additionalField5.visibility;

              const additionalField6column = this.grid.getColumnByField("additionalField6");
              additionalField6column.headerText = this.state.headerText.additionalField6.columnName;
              additionalField6column.visible = this.state.headerText.additionalField6.visibility;
              additionalField6column.showInColumnChooser = this.state.headerText.additionalField6.visibility;

              const additionalField7column = this.grid.getColumnByField("additionalField7");
              additionalField7column.headerText = this.state.headerText.additionalField7.columnName;
              additionalField7column.visible = this.state.headerText.additionalField7.visibility;
              additionalField7column.showInColumnChooser = this.state.headerText.additionalField7.visibility;

              const additionalField8column = this.grid.getColumnByField("additionalField8");
              additionalField8column.headerText = this.state.headerText.additionalField8.columnName;
              additionalField8column.visible = this.state.headerText.additionalField8.visibility;
              additionalField8column.showInColumnChooser = this.state.headerText.additionalField8.visibility;

              const additionalField9column = this.grid.getColumnByField("additionalField9");
              additionalField9column.headerText = this.state.headerText.additionalField9.columnName;
              additionalField9column.visible = this.state.headerText.additionalField9.visibility;
              additionalField9column.showInColumnChooser = this.state.headerText.additionalField9.visibility;

              const additionalField10column = this.grid.getColumnByField("additionalField10");
              additionalField10column.headerText = this.state.headerText.additionalField10.columnName;
              additionalField10column.visible = this.state.headerText.additionalField10.visibility;
              additionalField10column.showInColumnChooser = this.state.headerText.additionalField10.visibility;

              this.grid.refreshHeader();
            }
          }, 1000);
        }
      }
    }

    $("#div_pbcrequest").append();
    if (this.userId) {
      this.getPermissionsByUser(this.userId);
      this.getrolenameByUserId(this.userId);
    }
  }

  AddNewRequest = (args) => {
    this.getAddeditPbcRequest(null, true)
    this.hideModal()
    this.setState({ isaddedit: true })
    this.listItems = [];
    this.selected = [];
  }
  //Grid ToolBar Button Clicks
  toolbarClick = (args) => {
    //Start Work Flow
    if (args.item.id == "setworkflow") {

      if (this.state.pbcpermissions.includes("Create")) {
        var selectedRecords = this.grid.getSelectedRecords();
        if (this.state.alreadyInProgress === false) {

          if (selectedRecords.length > 0) {
            this.setState({ alreadyInProgress: true })
            let timerInterval
            Swal.fire({
              position: 'top-end',
              title: 'workflow successfully started',
              html: 'should display for atleast 5+ seconds',
              timer: 5000,
              timerProgressBar: true,
              backdrop: true,
              allowOutsideClick: false,

              didOpen: () => {
                Swal.showLoading()
              },
              willClose: () => {
                let pbcRequestIds = [];
                pbcRequestIds = selectedRecords.map((e, key) => e.pbcRequestId)
                let pbcRequestIdswithComma = pbcRequestIds.join();

                PBCRequest_Service.UpdatePBCRequestFlow(this.userId, pbcRequestIdswithComma).then(result => {
                   ViewToaster.getToaster(result.data.responseText, result.data.success);
                  this.getAllData();
                  this.setState({ alreadyInProgress: false })
                }).catch(err => ViewToaster.getToaster(err.response.data.responseText, err.response.data.success));

              }
            }).then((result) => {
              /* Read more about handling dismissals below */
              if (result.dismiss === Swal.DismissReason.timer) {
                console.log('I was closed by the timer')
                this.setState({ alreadyInProgress: false })
              }
            })
          }
          else {
            this.setState({ alreadyInProgress: false })
            Swal.fire({
              text: "Please select atleast one column from given list.",
              confirmButtonColor: '#043a05',
              confirmButtonText: 'Ok'
            }).then((result) => {
              if (result.isConfirmed) {
              }
            })
          }
        }
        else{
          ViewToaster.getToaster("Workflow already in progress …", false)
        }

      }
      else {
        ViewToaster.getToaster("You are not authorized to perform action.", false)
      }
    }

    //open PBCRequest Model
    if (args.item.id === "AddNew") {
      if (this.state.pbcpermissions.includes("Create")) {
        // this.setState({ values: {} })
        // this.setState({ isOpenPbcrequest: true });
        // this.setState({ title: "PBC Request" })

        this.setState({ isaddNew: true });
      }
      else {
        ViewToaster.getToaster("You are not authorized to perform action.", false)
      }
    }
    if (args.item.id === "MassUpdate") {
      var selectedRecords = this.grid.getSelectedRecords();
      if (selectedRecords.length > 0) {
        let pbcRequestIds = [];
        pbcRequestIds = selectedRecords.map((e, key) => e.pbcRequestId)
        this.setState({ selectedColumns: pbcRequestIds });
        $("#divmassupdate").show();
      }
      else {
        Swal.fire({
          text: "Please select atleast one column from given list.",
          confirmButtonColor: '#043a05',
          confirmButtonText: 'Ok'
        }).then((result) => {
          if (result.isConfirmed) {
          }
        })
      }
    }
    else {
      //Exporing Grid
      PBCRequest_Service.exporingGrid(args, this.grid)
    }
  }

  //Command Click Action Perform
  commandClick = (args) => {
    if (args.commandColumn.type === "PBC Document Upload") {
      setTimeout(() => {
        this.setState({ values: args.rowData });
        this.setState({ isOpen: true });
        this.setState({ title: "PBC Document" });
      }, 500);
    }

    if (args.commandColumn.type === "DownloadZip") {
      this.SaveZip(args)
    }
    if (args.commandColumn.type === "Edit") {
      if (this.state.pbcpermissions.includes("Update")) {
        // this.setState({ values: args.rowData });
        // this.setState({ isOpenPbcrequest: true });
        // this.setState({ title: "PBC Request" });

        this.getAddeditPbcRequest(args.rowData, true)
        this.setState({ isaddedit: true })
        this.setState({ buttons: [] })
        this.listItems = [];
        this.selected = [];
      }
      else {
        ViewToaster.getToaster("You are not authorized to perform action.", false)
      }
    }
    if (args.requestType === 'add') {
      this.setState({ values: null });
      this.setState({ isOpenPbcrequest: true });
      this.setState({ title: "Add PBC Request" });
    }

    //Delete PCBRequest
    if (args.commandColumn.type === 'Delete') {

      if (this.state.pbcpermissions.includes("Delete")) {
        Swal.fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#d33',
          cancelButtonColor: '#043a05',
          confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
          if (result.isConfirmed) {
            PBCRequest_Service.deletePCBRequestById(args.rowData.pbcRequestId)
              .then(res => {

                Swal.fire(
                  'Deleted!',
                  'Your file has been deleted.',
                  'success',
                );
              }).catch(err => ViewToaster.getToaster(err.response.data.responseText, err.response.data.success));
          }
          if (result.isDismissed) {
            this.getHeaderTexts();
            this.getAllData();
          }
        })
      }
      else {
        ViewToaster.getToaster("You are not authorized to perform action.", false)
      }
    }
  }

  SaveZip = async (args) => {
    await axios.get(`${this.Url}pbcRequest/generateAdndDownloadZip?pbcRequestId=${args.rowData.pbcRequestId}`).then(res => {
      FileSaver.saveAs(res.config.url)
    }).catch(err => ViewToaster.getToaster(err.response.data.responseText, err.response.data.success));
  }

  hideModal = () => {
    this.setState({ isOpen: false });
    this.setState({ isDocumentAuditorOpen: false });
    this.setState({ isDocument_AtchmntOpen: false });
    this.setState({ isDocument_clrn_Open: false });
    this.setState({ isaddNew: false });
    this.setState({ title: "" });
    this.getHeaderTexts();
    this.getAllData();
  };

  hidePBCRequestModal = () => {
    this.setState({ isOpenPbcrequest: false });
    this.setState({ title: "" });
    this.getHeaderTexts();
    this.getAllData();
  };

  //get Documents Count And Icon
  getDocumentsImage(props) {
    var cssClass = "e-icons e-M_PV_Thumbnail";
    var colorcss = props.documentsCount > 0 ? "#043A05" : "gray";
    return (
      <div>
        <div style={{ fontSize: "12px", color: colorcss }} >
          <a href="javascript:"><span class={cssClass} style={{ color: colorcss }}></span></a>   {props.documentsCount > 0 ? `(${props.documentsCount})` : ""}</div>

      </div>
    );
  }

  //get Comments Count And Icon
  getCommentsImage(props) {
    return PBCRequest_Service.getCommentsImageInGrid(props);
  }
  //set Status Style
  getStatus(props) {
    const objBackgroundColor = props.status == "Request Partially Approved" || props.status == "Review Pending" || props.status == "Review Clarification Pending" || props.status == "Client Clarification Pending" || props.status == "Reivew Clarification Pending" || props.status == "Request Pending" ? "orange" : props.status == "Not Started" ? "gray" : props.status == "Completed" ? "green" : props.status == " Rejected" ? "red" : "";
    return (
      <div>
        <div style={{ fontSize: "10px", background: objBackgroundColor, borderRadius: "15px", color: "white", marginRight: "10px" }}>
          <span> {props.status}</span>
        </div>

      </div>
    );
  }

  getDropdown = () => {
    return (
      <div id='toolbar-template'>
        <input type="text" tabindex="1" id='ddlelement' />
      </div>
    );
  }

  getPbcRequestDetails = (args) => {
    this.setState({ values: args });
    this.setState({ isOpenPbcrequest: true });
    this.setState({ title: "PBC Request" });
  }

  removeButtons = (args) => {
    if (args.pbcRequestId != "0") {
      this.newList = this.selected.filter((item) => item.pbcRequestId !== args.pbcRequestId);
      this.listItems = this.newList.map((e, key) =>
        <div class="pbcgrid-quickview-button-div col-md-1">
          <button className="pbcgrid-quickview-button" onClick={() => { e.pbcRequestId == 0 ? this.showPbcList() : this.getAddeditPbcRequest(e, false) }} key={key}>{e.pbcRequestId == "0" ? "Back to List" : 'Req ID: ' + e.pbcRequestId}</button>
          <a className="pbcgrid-quickview-button" onClick={() => { this.removeButtons(e) }} key={key}>{e.pbcRequestId == 0 ? '' : 'X'}</a>
        </div>
      );
      this.selected = this.newList;

      if (this.newList.length === 1) {
        this.selected = [];
        this.listItems = [];
        this.setState({ buttons: [] });
        this.showPbcList()
      }
      else {
        this.setState({ buttons: [this.newList] });
      }
    }
  }

  ///On grid Colum Double Click
  onDoubleClick(args) {
    if (this.state.objroleId == 6) {
      let doesPBCListExists = this.selected.filter((item) => item.pbcRequestId == 0);
      if (doesPBCListExists.length == 0)
        this.selected.push({ pbcRequestId: 0 })
      let isAlreadySelected = this.selected.filter((item) => item.pbcRequestId == args.rowData.pbcRequestId);
      if (isAlreadySelected.length == 0) {
        this.getAddeditPbcRequest(args.rowData, false)

        this.selected.push(args.rowData)
        this.setState({ buttons: [this.selected] });
        this.listItems = this.selected.map((e, key) =>
          <>
            <div class="pbcgrid-quickview-button-div col-md-1">
              <button className="pbcgrid-quickview-button" onClick={() => { e.pbcRequestId == 0 ? this.showPbcList() : this.getAddeditPbcRequest(e, false) }} key={key}>{e.pbcRequestId == 0 ? 'Back to List' : 'Req ID: ' + e.pbcRequestId}</button>
              <a href="#" className="pbcgrid-quickview-button" onClick={() => { e.pbcRequestId == 0 ? this.removeButtons(e) : this.removeButtons(e) }} key={key}>{e.pbcRequestId == 0 ? '' : 'X'}</a>
            </div>
          </>
        );
        this.setState({ buttons: [this.selected] });
        this.setState({ isaddNew: false });
      }
    }
    else {
      this.setState({ values: args.rowData });
      this.setState({ isOpen: true });
      this.setState({ title: "PBC Document" });
    }
  }

  getAddeditPbcRequest = (dataValues, editable) => {
    this.setState({ pbcdata: [dataValues] });
    this.setState({ pbcEditable: editable });
    $("#div_pbcrequestAddedit").show();
    $("#div_pbcrequestList").hide();
  }

  showPbcList() {
    this.getAllData();
    $("#div_pbcrequestAddedit").hide();
    $("#div_pbcrequestList").show();
    this.setState({ isaddedit: false })
  }

  render() {
    return (
      <>
        <div>
          <AuditName />
          {/* <div class="container-fluid" style={{ background: "#e4e4e4", paddingTop: "5px" }}><div class="row"><div class="col-md-12"><h5>Audit Name</h5></div></div></div> */}
          <div class="container-fluid" style={{ marginTop: "3px", marginBottom: "3px" }}>
            <div class="row">
              {this.state.isaddedit == false ? this.listItems : ""}

              {this.state.isaddedit == true ? <div class="pbcgrid-quickview-button-div col-md-1"> <button className="pbcgrid-quickview-button" onClick={() => { this.showPbcList() }}>Back to List</button> </div> : ""}
            </div>
          </div>
          {/* fileUpload And Comments Model */}
          <Modal
            show={this.state.isOpen}
            onHide={this.hideModal}
            size="xl" backdrop="static">
            <Modal.Header closeButton>
              <Modal.Title>{this.state.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body >
              <Documents data={this.state.values} userName={this.state.objuserName} roleName={this.state.objroleName} roleId={this.state.objroleId} userId={this.userId} cmtpermissions={this.state.cmtpermissions} docpermissions={this.state.docpermissions} />
            </Modal.Body>
          </Modal>

          {/* pbcRequest Model */}
          <Modal
            show={this.state.isOpenPbcrequest}
            onHide={this.hidePBCRequestModal}
            size="xl" backdrop="static">
            <Modal.Header closeButton>
              <Modal.Title >{this.state.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body >
              <AddEditPBCRequest data={this.state.values} editable={true} />
            </Modal.Body>
          </Modal>

          <Modal
            show={this.state.isaddNew}
            onHide={this.hideModal}
            size="sm" >
            <Modal.Body>
              <div className="container">
                <div className="row">
                  <div className="col-md-12">
                    <a className="btn btn-md btn-primary" style={{ width: "100%" }} onClick={() => this.AddNewRequest(this)}> Add New</a>
                  </div>
                </div>
                <br></br>
                <div className="row">
                  <div className="col-md-12">
                    <button className="btn btn-md btn-primary" style={{ width: "100%" }}> Bulk Upload</button>
                  </div>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* pbcMassUpdate Component */}
          <MassUpdate data={this.state.selectedColumns} showHide={"none;"} />
          <div className="container-fluid" id="div_pbcrequestList">
            {
              <GridComponent id='grid'
                dataSource={this.state.data}
                filterSettings={this.filter}
                editSettings={this.editOptions}
                toolbar={this.toolbarOptions}
                allowFiltering={true}
                allowSorting={true}
                allowPaging={true}
                showColumnChooser={true}
                pageSettings={{ pageCount: 5, pageSizes: true }}
                toolbarClick={this.toolbarClick.bind(this)}
                allowResizing={true}
                allowPdfExport={true} allowExcelExport={true} ref={g => this.grid = g}
                commandClick={this.commandClick.bind(this)} allowSelection={true} selectionSettings={this.select} sortSettings={this.sortingOptions} allowReordering={true}
                allowGrouping={true} groupSettings={this.groupOptions} recordDoubleClick={this.onDoubleClick.bind(this)}
              >
                <ColumnsDirective>
                  <ColumnDirective type='checkbox' allowResizing={false} allowSorting={false} allowFiltering={false} width='30'></ColumnDirective>
                  <ColumnDirective allowResizing={false} allowFiltering={false} allowReordering={false} headerText='Manage Records' width='150' textAlign='Center' commands={this.commands}></ColumnDirective>
                  <ColumnDirective filter={this.filter} isPrimaryKey={true} field="pbcRequestId" textAlign='left' width='130' headerText={this.state.headerText.pbcrequestId !== undefined ? this.state.headerText.pbcrequestId.columnName : this.state.headerText.pbcrequestId} visible={this.state.headerText.pbcrequestId !== undefined ? this.state.headerText.pbcrequestId.visibility : true} showInColumnChooser={this.state.headerText.pbcrequestId !== undefined ? this.state.headerText.pbcrequestId.visibility : true} />
                  <ColumnDirective filter={this.check} field="status" width='200' textAlign='center' template={this.getStatus} headerText={this.state.headerText.status !== undefined ? this.state.headerText.status.columnName : this.state.headerText.status} visible={this.state.headerText.status !== undefined ? this.state.headerText.status.visibility : true} showInColumnChooser={this.state.headerText.status !== undefined ? this.state.headerText.status.visibility : true} />
                  <ColumnDirective filter={this.check} field="processAreaName" width='130' textAlign='left' headerText={this.state.headerText.processArea !== undefined ? this.state.headerText.processArea.columnName : this.state.headerText.processArea} visible={this.state.headerText.processArea !== undefined ? this.state.headerText.processArea.visibility : true} showInColumnChooser={this.state.headerText.processArea !== undefined ? this.state.headerText.processArea.visibility : true} />
                  <ColumnDirective filter={this.check} field="subProcessAreaName" width='180' textAlign='left' headerText={this.state.headerText.subProcessArea !== undefined ? this.state.headerText.subProcessArea.columnName : this.state.headerText.subProcessArea} visible={this.state.headerText.subProcessArea !== undefined ? this.state.headerText.subProcessArea.visibility : true} showInColumnChooser={this.state.headerText.subProcessArea !== undefined ? this.state.headerText.subProcessArea.visibility : true} />
                  <ColumnDirective filter={this.filter} field="controlDesc" width='150' textAlign='left' headerText={this.state.headerText.controlDesc !== undefined ? this.state.headerText.controlDesc.columnName : this.state.headerText.controlDesc} visible={this.state.headerText.controlDesc !== undefined ? this.state.headerText.controlDesc.visibility : true} showInColumnChooser={this.state.headerText.controlDesc !== undefined ? this.state.headerText.controlDesc.visibility : true} />
                  <ColumnDirective filter={this.filter} clipMode="EllipsisWithTooltip" field="pbcDesc" width='250' textAlign='left' headerText={this.state.headerText.pbcdesc !== undefined ? this.state.headerText.pbcdesc.columnName : this.state.headerText.pbcdesc} visible={this.state.headerText.pbcdesc !== undefined ? this.state.headerText.pbcdesc.visibility : true} showInColumnChooser={this.state.headerText.pbcdesc !== undefined ? this.state.headerText.pbcdesc.visibility : true} />
                  <ColumnDirective filter={this.check} field="requestItemSecurity" width='220' textAlign='left' headerText={this.state.headerText.requestItemSecurity !== undefined ? this.state.headerText.requestItemSecurity.columnName : this.state.headerText.requestItemSecurity} visible={this.state.headerText.requestItemSecurity !== undefined ? this.state.headerText.requestItemSecurity.visibility : true} showInColumnChooser={this.state.headerText.requestItemSecurity !== undefined ? this.state.headerText.requestItemSecurity.visibility : true} />
                  <ColumnDirective filter={this.filter} allowFiltering={true} field="pbcRequestDueDate" width='120' type="date" format='dd-MM-yyyy' textAlign='left' headerText={this.state.headerText.pbcrequestDueDate !== undefined ? this.state.headerText.pbcrequestDueDate.columnName : this.state.headerText.pbcrequestDueDate} visible={this.state.headerText.pbcrequestDueDate !== undefined ? this.state.headerText.pbcrequestDueDate.visibility : true} showInColumnChooser={this.state.headerText.pbcrequestDueDate !== undefined ? this.state.headerText.pbcrequestDueDate.visibility : true} />
                  <ColumnDirective filter={this.filter} field="documentsCount" width='100' textAlign='left' headerText={this.state.headerText.documentsCount !== undefined ? this.state.headerText.documentsCount.columnName : this.state.headerText.documentsCount} visible={this.state.headerText.documentsCount !== undefined ? this.state.headerText.documentsCount.visibility : true} template={this.getDocumentsImage} showInColumnChooser={this.state.headerText.documentsCount !== undefined ? this.state.headerText.documentsCount.visibility : true} />
                  <ColumnDirective filter={this.filter} field="commentsCount" width='100' textAlign='left' headerText={this.state.headerText.commentsCount !== undefined ? this.state.headerText.commentsCount.columnName : this.state.headerText.commentsCount} visible={this.state.headerText.commentsCount !== undefined ? this.state.headerText.commentsCount.visibility : true} template={this.getCommentsImage} showInColumnChooser={this.state.headerText.commentsCount !== undefined ? this.state.headerText.commentsCount.visibility : true} />
                  <ColumnDirective filter={this.filter} field="auditTeamMember" clipMode="EllipsisWithTooltip" width='200' textAlign='left' headerText={this.state.headerText.auditTeamMember !== undefined ? this.state.headerText.auditTeamMember.columnName : this.state.headerText.auditTeamMember} visible={this.state.headerText.auditTeamMember !== undefined ? this.state.headerText.auditTeamMember.visibility : true} showInColumnChooser={this.state.headerText.auditTeamMember !== undefined ? this.state.headerText.auditTeamMember.visibility : true} />
                  <ColumnDirective filter={this.filter} field="auditTeamManager" clipMode="EllipsisWithTooltip" width='200' textAlign='left' headerText={this.state.headerText.auditTeamManager !== undefined ? this.state.headerText.auditTeamManager.columnName : this.state.headerText.auditTeamManager} visible={this.state.headerText.auditTeamManager !== undefined ? this.state.headerText.auditTeamManager.visibility : true} showInColumnChooser={this.state.headerText.auditTeamManager !== undefined ? this.state.headerText.auditTeamManager.visibility : true} />
                  <ColumnDirective filter={this.filter} field="controlOwnerEmailId" clipMode="EllipsisWithTooltip" width='200' textAlign='left' headerText={this.state.headerText.controlOwnerEmailId !== undefined ? this.state.headerText.controlOwnerEmailId.columnName : this.state.headerText.controlOwnerEmailId} visible={this.state.headerText.controlOwnerEmailId !== undefined ? this.state.headerText.controlOwnerEmailId.visibility : true} showInColumnChooser={this.state.headerText.controlOwnerEmailId !== undefined ? this.state.headerText.controlOwnerEmailId.visibility : true} />
                  <ColumnDirective filter={this.filter} field="escalationEmailIds" clipMode="EllipsisWithTooltip" width='200' textAlign='left' headerText={this.state.headerText.escalationEmailIds !== undefined ? this.state.headerText.escalationEmailIds.columnName : this.state.headerText.escalationEmailIds} visible={this.state.headerText.escalationEmailIds !== undefined ? this.state.headerText.escalationEmailIds.visibility : true} showInColumnChooser={this.state.headerText.escalationEmailIds !== undefined ? this.state.headerText.escalationEmailIds.visibility : true} />
                  <ColumnDirective filter={this.filter} field="additionalField1" width='200' textAlign='left' headerText={this.state.headerText.additionalField1 !== undefined ? this.state.headerText.additionalField1.columnName : this.state.headerText.additionalField1} visible={this.state.headerText.additionalField1 !== undefined ? this.state.headerText.additionalField1.visibility : true} showInColumnChooser={this.state.headerText.additionalField1 !== undefined ? this.state.headerText.additionalField1.visibility : true} />
                  <ColumnDirective filter={this.filter} field="additionalField2" width='200' textAlign='left' headerText={this.state.headerText.additionalField2 !== undefined ? this.state.headerText.additionalField2.columnName : this.state.headerText.additionalField2} visible={this.state.headerText.additionalField2 !== undefined ? this.state.headerText.additionalField2.visibility : true} showInColumnChooser={this.state.headerText.additionalField2 !== undefined ? this.state.headerText.additionalField2.visibility : true} />
                  <ColumnDirective filter={this.filter} field="additionalField3" width='200' textAlign='left' headerText={this.state.headerText.additionalField3 !== undefined ? this.state.headerText.additionalField3.columnName : this.state.headerText.additionalField3} visible={this.state.headerText.additionalField3 !== undefined ? this.state.headerText.additionalField3.visibility : true} showInColumnChooser={this.state.headerText.additionalField3 !== undefined ? this.state.headerText.additionalField3.visibility : true} />
                  <ColumnDirective filter={this.filter} field="additionalField4" width='200' textAlign='left' headerText={this.state.headerText.additionalField4 !== undefined ? this.state.headerText.additionalField4.columnName : this.state.headerText.additionalField4} visible={this.state.headerText.additionalField4 !== undefined ? this.state.headerText.additionalField4.visibility : true} showInColumnChooser={this.state.headerText.additionalField4 !== undefined ? this.state.headerText.additionalField4.visibility : true} />
                  <ColumnDirective filter={this.filter} field="additionalField5" width='200' textAlign='left' headerText={this.state.headerText.additionalField5 !== undefined ? this.state.headerText.additionalField5.columnName : this.state.headerText.additionalField5} visible={this.state.headerText.additionalField5 !== undefined ? this.state.headerText.additionalField5.visibility : true} showInColumnChooser={this.state.headerText.additionalField5 !== undefined ? this.state.headerText.additionalField5.visibility : true} />
                  <ColumnDirective filter={this.filter} field="additionalField6" width='200' textAlign='left' headerText={this.state.headerText.additionalField6 !== undefined ? this.state.headerText.additionalField6.columnName : this.state.headerText.additionalField6} visible={this.state.headerText.additionalField6 !== undefined ? this.state.headerText.additionalField6.visibility : true} showInColumnChooser={this.state.headerText.additionalField6 !== undefined ? this.state.headerText.additionalField6.visibility : true} />
                  <ColumnDirective filter={this.filter} field="additionalField7" width='200' textAlign='left' headerText={this.state.headerText.additionalField7 !== undefined ? this.state.headerText.additionalField7.columnName : this.state.headerText.additionalField7} visible={this.state.headerText.additionalField7 !== undefined ? this.state.headerText.additionalField7.visibility : true} showInColumnChooser={this.state.headerText.additionalField7 !== undefined ? this.state.headerText.additionalField7.visibility : true} />
                  <ColumnDirective filter={this.filter} field="additionalField8" width='200' textAlign='left' headerText={this.state.headerText.additionalField8 !== undefined ? this.state.headerText.additionalField8.columnName : this.state.headerText.additionalField8} visible={this.state.headerText.additionalField8 !== undefined ? this.state.headerText.additionalField8.visibility : true} showInColumnChooser={this.state.headerText.additionalField8 !== undefined ? this.state.headerText.additionalField8.visibility : true} />
                  <ColumnDirective filter={this.filter} field="additionalField9" width='200' textAlign='left' headerText={this.state.headerText.additionalField9 !== undefined ? this.state.headerText.additionalField9.columnName : this.state.headerText.additionalField9} visible={this.state.headerText.additionalField10 !== undefined ? this.state.headerText.additionalField9.visibility : true} showInColumnChooser={this.state.headerText.additionalField10 !== undefined ? this.state.headerText.additionalField9.visibility : true} />
                  <ColumnDirective filter={this.filter} field="additionalField10" width='200' textAlign='left' headerText={this.state.headerText.additionalField10 !== undefined ? this.state.headerText.additionalField10.columnName : this.state.headerText.additionalField10} visible={this.state.headerText.additionalField10 !== undefined ? this.state.headerText.additionalField10.visibility : true} showInColumnChooser={this.state.headerText.additionalField10 !== undefined ? this.state.headerText.additionalField10.visibility : true} />
                </ColumnsDirective>
                <Inject services={[Reorder, Resize, InfiniteScroll, VirtualScroll, Group, Sort, Edit, Page, Toolbar, ColumnChooser, CommandColumn, Filter, PdfExport, ExcelExport]} />
              </GridComponent>
            }
          </div>
          <div id="div_pbcrequestAddedit" className="container" style={{ display: "none", paddingTop: "10px", paddingBottom: "10px" }}>
            {
              this.state.pbcdata.length > 0 ?
                <AddEditPBCRequest data={this.state.pbcdata[0]} editable={this.state.pbcEditable} cmtpermissions={this.state.cmtpermissions} roleId={this.state.objroleId} userId={this.userId} docpermissions={this.state.docpermissions} /> : ""
            }
          </div>
        </div>
      </>
    );
  }
}