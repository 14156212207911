import React, { useState, useEffect } from "react";
import { ViewToaster } from "./ViewToaster";
import { PBCRequest_Service } from "../Services/PBCRequest_Service";
import $ from 'jquery';
import Select from 'react-select';


export const MassUpdate = (props) => {

    let [divShowHide, SetdivShowHide] = useState("none")

    useEffect(() => {
        SetdivShowHide(props.showHide);
        getHeaderTexts();

    }, []);




    function closingMassUpdate() {
        //SetdivShowHide("none");
        $("#divmassupdate").hide();
    }

    const [dropDownSelected, SetdropDownSelected] = useState([]);

    const [oldValue, setOldValue] = useState("");
    const [newValue, setNewValue] = useState("");


    const handleChange = (event) => {
        const selected = [];
        let selectedOption = (event);
        for (let i = 0; i < selectedOption.length; i++) {
            selected.push(selectedOption[i].value)
        }
        SetdropDownSelected({ selected });
    }

    function submitMassUpdate() {

        const isValid = validate();
        if (isValid) {
            setValidationState({ selectFieldsError: "", oldvalueError: "", newvalueError: "" });
            //ids Comma Separate
            let pbcRequestIds = props.data.join();
            let fieldIds = dropDownSelected.selected.join();

            PBCRequest_Service.massUpdate(pbcRequestIds, fieldIds, oldValue, newValue).then(result => {
                ViewToaster.getToaster(result.data.responseText, result.data.success);
                setTimeout(() => window.location.reload(true), 2000)
            }).catch(err => ViewToaster.getToaster(err.response.data.responseText, err.response.data.success));


        }
    }

    let selectFieldsError = "";
    let oldvalueError = "";
    let newvalueError = "";
    const [validationstate, setValidationState] = useState(selectFieldsError, oldvalueError, newvalueError);
    const validate = () => {

        if (oldValue === "") {
            oldvalueError = "Please enter old value";
        }
        if (newValue === "") {
            newvalueError = "Please enter new value";
        }
        if (dropDownSelected.length == 0) {
            selectFieldsError = "Please select Fields";
        }
        if (oldvalueError || newvalueError || selectFieldsError) {
            setValidationState({ oldvalueError, newvalueError, selectFieldsError });
            return false;
        }
        return true;
    }

    let ArrayData = [];

    let [optionsArrays, setoptionsArrays] = useState([]);
    const getHeaderTexts = async () => {
        PBCRequest_Service.getHeaderText().then((result) => {
            //   setHeaderText(result.data);
            ArrayData.push({ value: result.data.controlId.columnValue, label: result.data.controlId.columnName },
                { value: result.data.processArea.columnValue, label: result.data.processArea.columnName },
                { value: result.data.subProcessArea.columnValue, label: result.data.subProcessArea.columnName },
                { value: result.data.pbcdesc.columnValue, label: result.data.pbcdesc.columnName },
                { value: result.data.requestItemSecurity.columnValue, label: result.data.requestItemSecurity.columnName },
                { value: result.data.controlOwnerEmailId.columnValue, label: result.data.controlOwnerEmailId.columnName },
                { value: result.data.escalationEmailIds.columnValue, label: result.data.escalationEmailIds.columnName })

            setoptionsArrays(ArrayData);
        })
    }


    return (
        <div id="divmassupdate" style={{ display: divShowHide }}>
            <div className="p-3" style={{ background: "#e1e1e1" }}>
                <div class="row">
                    <div class="col-md-3">
                        <Select isMulti={true} placeholder="Select Column" options={optionsArrays} onChange={handleChange.bind(this)} />
                        <small class="text-danger">{validationstate.selectFieldsError}</small>
                    </div>
                    <div class="col-md-3">
                        <input value={oldValue} type="text" onChange={(e) => setOldValue(e.target.value)} className="form-control" id="oldvalue" placeholder="Enter Old Value" />
                        <small class="text-danger">{validationstate.oldvalueError}</small>
                    </div>
                    <div class="col-md-3">
                        <input value={newValue} type="text" onChange={(e) => setNewValue(e.target.value)} className="form-control" id="newvalue" placeholder="Enter New Value" />
                        <small class="text-danger">{validationstate.newvalueError}</small>
                    </div>
                    <div class="col-md-3">
                        <button type="button" onClick={() => { submitMassUpdate() }} className="btn btn-md btn-primary">Update</button>&nbsp;
                        <button type="button" onClick={() => { closingMassUpdate() }} className="btn btn-md btn-danger">Close</button>
                    </div>
                </div>
            </div>
        </div>
    );
}