import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types'
import { Link, useHistory, NavLink } from "react-router-dom";
import Cookies from 'universal-cookie';
import { user_Service } from '../Services/user_Service';
import { Common_Service } from '../Services/Common_Service';
import 'bootstrap/dist/js/bootstrap.js';
import Modal from "react-bootstrap/Modal";
// import $ from 'jquery';


export default function Header(props) {
  const cookies = new Cookies();
  let history = useHistory();
  function getToken() {
    let objuserId = Common_Service.getCookies();
    return objuserId;
  }
  const userId = getToken();
  const logo = require('../dist/img/user1-128x128.jpg');

  useEffect(() => {
    if (userId) {
      getUserData(userId);
    }
  }, []);

  const [userName, setUserName] = useState({});
  function getUserData(objuserId) {
    user_Service.getUserById(objuserId).then((result) => setUserName(result.data));

  }


  const [chatshow, setchatShow] = useState(false);
  const [isopen, setisopen] = useState(false);


  const logout = () => {
    cookies.remove("_data");
    history.push("/");
    window.location.reload(true);
  }


  const showChatBox = () => {
    setchatShow(true);
  }
  const closeChatBox = () => {
    setchatShow(false);
  }
  const openAboutModel = () => {
    setisopen(true);
  }
  const closeAboutModel = () => {
    setisopen(false);
  }

  return (
    <div>
      <nav class="main-header navbar navbar-expand-md navbar-light navbar-blue">
        <div class="container-fluid">
          {/* <span style={{ paddingRight: "15px" }}><i class="fa fa-bars" style={{ color: "#fff" }}></i></span> */}
          <span>
            <ul class="order-3 order-md-1 navbar-nav">
              <li class="nav-item">
                <a class="nav-link" data-toggle="dropdown" href="javascript:" aria-expanded="true" role="button">
                  <i class="fa fa-bars" style={{ color: "#fff" }}></i>
                </a>
                <div class="dropdown-menu dropdown-menu-md dropdown-menu-left">
                  <Link to="/audit" class="dropdown-item">
                    <i class="fas fa-book mr-2"></i> Create New Audit
                  </Link>
                  <div className="pl-3">
                    <i class="fas fa-book mr-2"></i> Manage Audit
                    <ul>
                      <li> <Link to="/manageaudit">Audit 1</Link></li>
                      <li> <Link to="/manageaudit">Audit 2</Link></li>
                      <li> <Link to="/">Audit 3</Link></li>
                    </ul>
                  </div>
                </div>
              </li>
            </ul>
          </span>
          <span style={{ paddingRight: "15px" }}><a onClick={() => showChatBox()} href="javascript:"><i class="fas fa-bell" style={{ color: "#fff" }}></i></a>  </span>
          <NavLink to="/" class="navbar-brand"><img style={{ height: "60px", paddingTop: "3px" }} src={require('../dist/img/acme-1-logo-png-transparent.png').default} ></img></NavLink>
          <button class="navbar-toggler order-1" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"><i class="fa fa-bars" style={{ color: "#fff" }}></i></span>
          </button>
          <div class="collapse navbar-collapse order-3" id="navbarCollapse">
            <ul class="navbar-nav">
              <li className="nav-item"><NavLink to="/mytask" className="nav-link">My Task</NavLink></li>
              <li className="nav-item"><NavLink to="/PBCRequestList" className="nav-link">Document Requests</NavLink></li>
              {/* <li className="nav-item"><Link to="/demoPBCRequestList" className="nav-link">Internal Documents</Link></li> */}
              <li className="nav-item"><NavLink to="/audit" className="nav-link">Audit</NavLink></li>
              <li className="nav-item"><NavLink to="/Dashboard" className="nav-link">Dashboard</NavLink></li>
              {/* <li className="nav-item"><NavLink to="/pbcrequest1" className="nav-link">PBC Request 1</NavLink></li> */}
              {/* <li className="nav-item"><NavLink to="/Dashboard1" className="nav-link">Dashboard1</NavLink></li> */}
            </ul>
          </div>
          <ul class="order-1 order-md-3 navbar-nav">
            <li class="nav-item" href="javascript:">
              <a class="nav-link" href="javascript:"><i class="fas fa-search fa-fw"></i></a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="javascript:" role="button">
                <div class="image">
                  Welcome, {userName == undefined ? "" : userName.name}
                  <div>
                    <small className="float-right" style={{ fontSize: "10px" }}>Last Logged In: 07/06/2021 2:05 pm IST</small>
                  </div>
                  {/* <img src={require('../dist/img/avatar5.png')} class="img-circle elevation-2" alt="User Image" /> */}
                </div>
              </a>

            </li>

            <li class="nav-item">
              <a class="nav-link" data-toggle="dropdown" href="javascript:" aria-expanded="true" role="button">
                <i class="fas fa-ellipsis-v"></i>
              </a>
              <div class="dropdown-menu dropdown-menu-md dropdown-menu-right">
                <Link to="myprofile" class="dropdown-item">
                  <i class="fas fa-user mr-2"></i> My Profile
                </Link>
                <div class="dropdown-divider"></div>
                <Link to="/usermanagement" class="dropdown-item">
                  <i class="fas fa-user mr-2"></i> User Management
                </Link>
                <Link to="/settings" class="dropdown-item">
                  <i class="fas fa-users-cog mr-2"></i>Settings
                </Link>
                <a class="dropdown-item" href="javascript:" onClick={() => openAboutModel()}>
                  <i class="fas fa-users-cog mr-2"></i> About
                </a>
                {/* <div class="dropdown-divider"></div> */}
                {/* <a href="javascript:" onClick={() => logout()} class="dropdown-item">
                  <i class="fas fa-sign-out-alt mr-2"></i> Logout
                </a> */}
              </div>
            </li>

            <li class="nav-item">
              <a class="nav-link" title="Logout" onClick={() => logout()} href="javascript:" role="button">
                <i class="fas fa-sign-out-alt mr-2"></i>
              </a>
            </li>
            {/* <li class="nav-item">
              <a class="nav-link" data-toggle="dropdown" href="javascript:" aria-expanded="true" role="button">
              <i class="fas fa-comments" style={{ color: "#fff" }}></i>
              </a>
              <div class="dropdown-menu dropdown-menu-md dropdown-menu-right">
                <div class="card card-success card-outline direct-chat direct-chat-success" style={{maxWidth:"350px",width:"auto"}}>
                  <div class="card-header">
                    <h3 class="card-title">Alerts</h3>
                    <div class="card-tools">
                      <button type="button" class="btn btn-tool" onClick={() => closeChatBox()} data-card-widget="remove">
                        <i class="fas fa-times"></i>
                      </button>
                    </div>
                  </div>
                  <div class="card-body">
                    <div class="direct-chat-messages">
                      <div class="direct-chat-msg">
                        <div class="direct-chat-infos clearfix">
                          <span class="direct-chat-name float-left">User Name</span>
                          <span class="direct-chat-timestamp float-right">23 Jan 2:00 pm</span>
                        </div>
                        <img class="direct-chat-img" src={logo.default} alt="Message User Image" />
                        <div class="direct-chat-text">
                          Is this template really for free? That's unbelievable!
                        </div>
                      </div>
                      <div class="direct-chat-msg right">
                        <div class="direct-chat-infos clearfix">
                          <span class="direct-chat-name float-right">User Name</span>
                          <span class="direct-chat-timestamp float-left">23 Jan 2:05 pm</span>
                        </div>
                        <img class="direct-chat-img" src={logo.default} alt="Message User Image" />
                        <div class="direct-chat-text">
                          You better believe it!
                        </div>
                      </div>
                      <div class="direct-chat-msg">
                        <div class="direct-chat-infos clearfix">
                          <span class="direct-chat-name float-left">User Name</span>
                          <span class="direct-chat-timestamp float-right">23 Jan 2:00 pm</span>
                        </div>
                        <img class="direct-chat-img" src={logo.default} alt="Message User Image" />
                        <div class="direct-chat-text">
                          Is this template really for free? That's unbelievable. Is this template really for free? That's unbelievable!
                        </div>
                      </div>
                      <div class="direct-chat-msg right">
                        <div class="direct-chat-infos clearfix">
                          <span class="direct-chat-name float-right">User Name</span>
                          <span class="direct-chat-timestamp float-left">23 Jan 2:05 pm</span>
                        </div>
                        <img class="direct-chat-img" src={logo.default} alt="Message User Image" />
                        <div class="direct-chat-text">
                          You better believe it. Is this template really for free? That's unbelievable!
                        </div>
                      </div>
                    </div>

                  </div>
                  <div class="card-footer">
                    <form action="#" method="post">
                      <div class="input-group">
                        <input type="text" name="message" placeholder="Type Message ..." class="form-control" />
                        <span class="input-group-append">
                          <button type="submit" class="btn btn-primary">Send</button>
                        </span>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </li> */}
          </ul>
        </div>
      </nav>
      <div class="col-md-3" style={{ display: chatshow === false ? "none" : "", position:"absolute",zIndex:"1000" }}>
        <div class="card card-success chatbot-border direct-chat direct-chat-success">
          <div class="card-header" style={{borderTopLeftRadius:"0px",borderTopRightRadius:"0px"}}>
            <h3 class="card-title">Smart Updates</h3>
            <div class="card-tools">
              <button type="button" class="btn btn-tool" onClick={() => closeChatBox()} data-card-widget="remove">
                <i class="fas fa-times"></i>
              </button>
            </div>
          </div>
          <div class="card-body">
            <div class="direct-chat-messages">
              <div class="direct-chat-msg">
                <div class="direct-chat-infos clearfix">
                  <span class="direct-chat-name float-left">CollabBot</span>
                  <span class="direct-chat-timestamp float-right">23 Jan 2:00 pm</span>
                </div>
                <img class="direct-chat-img" src={require('../dist/img/small-logo.png').default} alt="Message User Image" />
                <div class="direct-chat-text">
                  Is this template really for free? That's unbelievable!
                </div>
              </div>
              <div class="direct-chat-msg right">
                <div class="direct-chat-infos clearfix">
                  <span class="direct-chat-name float-right">User Name</span>
                  <span class="direct-chat-timestamp float-left">23 Jan 2:05 pm</span>
                </div>
                <img class="direct-chat-img" src={require('../dist/img/avatar5.png').default} alt="Message User Image" />
                <div class="direct-chat-text">
                  You better believe it!
                </div>
              </div>
              <div class="direct-chat-msg">
                <div class="direct-chat-infos clearfix">
                  <span class="direct-chat-name float-left">CollabBot</span>
                  <span class="direct-chat-timestamp float-right">23 Jan 2:00 pm</span>
                </div>
                <img class="direct-chat-img" src={require('../dist/img/small-logo.png').default} alt="Message User Image" />
                <div class="direct-chat-text">
                  Is this template really for free? That's unbelievable. Is this template really for free? That's unbelievable!
                </div>
              </div>
              <div class="direct-chat-msg right">
                <div class="direct-chat-infos clearfix">
                  <span class="direct-chat-name float-right">User Name</span>
                  <span class="direct-chat-timestamp float-left">23 Jan 2:05 pm</span>
                </div>
                <img class="direct-chat-img" src={require('../dist/img/avatar5.png').default} alt="Message User Image" />
                <div class="direct-chat-text">
                  You better believe it. Is this template really for free? That's unbelievable!
                </div>
              </div>
            </div>

          </div>
          <div class="card-footer">
            <form action="#" method="post">
              <div class="input-group">
                <input type="text" name="message" placeholder="Type Message ..." class="form-control" />
                <span class="input-group-append">
                  <button type="submit" class="btn btn-primary">Send</button>
                </span>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Modal
        show={isopen}
        onHide={closeAboutModel}
        size="md">
        <Modal.Header closeButton>
          <Modal.Title>About</Modal.Title>
        </Modal.Header>
        <Modal.Body >
          <div className="row">
            <div className="form-group">
              <div class="col-md-12">
                <label className="form-label">Version :</label><span> 1.0</span>
              </div>
              <div class="col-md-12">
                <label className="form-label">Expiry Of License :</label><span> Jan 04 2022 </span>
              </div>
              <div class="col-md-12">
                <label className="form-label">Support Email :</label><span> support@collabAudit.com </span>
              </div>

            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}
Header.defaultProps = {
  title: "Collab Audit",
  searchBar: true
}

Header.propTypes = {
  title: PropTypes.string,
  searchBar: PropTypes.bool.isRequired
}