import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios'
toast.configure()

const Url = process.env.REACT_APP_API_URL;
export const Module_Service = {
    getModuleList,
    moduleDataById,
    submitModuleData,
    deleteModuleById
}

async function getModuleList() {
    const result = await axios.get(`${Url}module/getModuleList`);
    return result;
}

async function moduleDataById(id) {
    const result = await axios.get(`${Url}module/getModuleById?id=${id}`);
    return result;
}

async function submitModuleData(moduleData) {
    const objdata = {
        ModuleId: moduleData.moduleId,
        ModuleName: moduleData.moduleName,
    };
    const result = await axios.post(`${Url}module/addOrUpdate/`, objdata);
    return result;
}

async function deleteModuleById(id) {
    const result = await axios.post(`${Url}module/deleteModule?id=${id}`);
    return result;
}