import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios'
toast.configure()

const Url = process.env.REACT_APP_API_URL;
export const Permission_Service = {
    getPermissionList,
    permissionDataById,
    submitPermissionData,
    deletePermissionById
}

async function getPermissionList() {
    const result = await axios.get(`${Url}permission/getPermissionsList`);
    return result;
}

async function permissionDataById(id) {
    const result = await axios.get(`${Url}permission/getPermissionById?id=${id}`);
    return result;
}

async function submitPermissionData(permissionData) {
    const objdata = {
        PermissionId: permissionData.permissionId,
        PermissionName: permissionData.permissionName,
    };
    const result = await axios.post(`${Url}permission/addOrUpdate/`, objdata);
    return result;
}

async function deletePermissionById(id) {
    const result = await axios.post(`${Url}permission/deletePermission?id=${id}`);
    return result;
}