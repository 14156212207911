
import axios from 'axios'

const Url = process.env.REACT_APP_API_URL;
export const UserRolePermission_Service = {
    getUserTypeList,
    getRoleListByUserType,
    submitPermissions,
    getUserRolePermissionsList,
    deleteUserRolePermissionById
}

async function getUserTypeList() {
    const result = await axios.get(`${Url}userRolePermission/getUserTypeList`);
    return result;
}

async function getRoleListByUserType(usertypeId) {
    const result = await axios.get(`${Url}userRolePermission/getRoleListByuserTypeId?userTypeId=${usertypeId}`);
    return result;
}


async function submitPermissions(selectedNames, selectedIds, userPermissionData) {
    const objdata = {
        RoleId: parseInt(userPermissionData.roleId, 10),
        ModuleId: parseInt(userPermissionData.moduleId, 10),
        UserRoleId: parseInt(userPermissionData.userRoleId, 10),
        UserTypeId: parseInt(userPermissionData.userTypeId, 10),
        PermissionIds: selectedIds,
        PermissionNames: selectedNames
    };
    const result = await axios.post(`${Url}userRolePermission/addUpdatePermissions/`, objdata);
    return result;
}


async function getUserRolePermissionsList() {
    const result = await axios.get(`${Url}userRolePermission/getUserRolePermissionsList`);
    return result;
}

async function deleteUserRolePermissionById(id) {
    const result = await axios.post(`${Url}userRolePermission/deleteUserRolePermission?id=${id}`);
    return result;
}
