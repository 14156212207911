
import React, { useState, useEffect } from "react";
import {
    GridComponent,
    ColumnDirective,
    ColumnsDirective,
    Page,
    Edit,
    Toolbar,
    Inject, ColumnChooser, CommandColumn, Filter, PdfExport, ExcelExport,Resize, Grid, Sort, Reorder
} from "@syncfusion/ej2-react-grids";
import { ViewToaster } from "../ViewToaster";
import { Permission_Service } from "../../Services/Permission_Service";
import Swal from 'sweetalert2';
export const PermissionAddEdit = (props) => {

    useEffect(() => {
        getpermissionData();
    }, []);

    let [permissionData, setpermissionData] = useState({
        permissionId: 0,
        permissionName: ""
    });

    let { permissionName } = permissionData;
    const onInputChange = e => {
        setpermissionData({ ...permissionData, [e.target.id]: e.target.value });
    };

    //Form Submiting
    const onSubmit = async () => {
        const isValid = validate();
        if (isValid) {
            setValidationState({ permissionError: "" });
            Permission_Service.submitPermissionData(permissionData).then(result => {
                ViewToaster.getToaster(result.data.responseText, result.data.success);
                getpermissionData();
                setpermissionData({ permissionId: 0, permissionName: "" });

            }).catch(err => ViewToaster.getToaster(err.response.data.responseText, err.response.data.success));
        }
    };


    //Validating Inputes
    let permissionError = "";
    const [validationstate, setValidationState] = useState(permissionError);
    const validate = () => {


        if (!permissionName.length > 0) {
            permissionError = "Please Enter Permission Name";
        }

        if (permissionError) {
            setValidationState({ permissionError });
            return false;
        }
        return true;
    };

    ///Grid List
    const [permissionList, setPermissionList] = useState();
    //Get All Data for Grid
    const getpermissionData = async () => {
        Permission_Service.getPermissionList().then((result) => setPermissionList(result.data))
    }

    //Get Permission By Id
    const loadPermissionById = async (permissionId) => {
        Permission_Service.permissionDataById(permissionId).then((result) => setpermissionData(result.data));
    };


    //Command Click Action Perform
    const commandClick = (args) => {
        if (args.commandColumn.type === "Edit") {
            loadPermissionById(args.rowData.permissionId)
        }
        //Delete PCBRequest
        if (args.commandColumn.type === 'Delete') {


            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
                if (result.isConfirmed) {
                    Permission_Service.deletePermissionById(args.rowData.permissionId)
                        .then(res => {
                            Swal.fire(
                                'Deleted!',
                                'Your file has been deleted.',
                                'success'
                            );
                            getpermissionData();
                        }).catch(err => ViewToaster.getToaster(err.response.data.responseText, err.response.data.success));
                }
                if (result.isDismissed) {
                    getpermissionData();
                }
            })
        }
    }


    const editOptions = {
        allowEditing: false,
        allowAdding: true,
        allowDeleting: true,
    };
    const toolbarOptions = ["Search"];

    const commands = [{ type: 'Edit', buttonOption: { iconCss: ' e-icons e-edit', cssClass: 'e-flat' } },
    { type: 'Delete', buttonOption: { iconCss: 'e-icons e-delete', cssClass: 'e-flat' } }];

    const filter = {
        type: 'Menu'
    };
    const check = {
        type: 'CheckBox'
    };
    return (
        <form>
            <div className="card">
                <div className="card-body">
                    <div class="row"><div class="col-md-12"><h5 class="card-text">Manage Permission</h5><hr></hr></div></div>
                    <div class="row">
                        <div class="col-md-4">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label htmlFor="permissionName" className="form-label">Permission Name</label><span className="text-danger reqired-fields">*</span>
                                        <input type="text" value={permissionName} onChange={e => onInputChange(e)} className="form-control" id="permissionName" placeholder="Enter Permission Name" />
                                        <small class="text-danger">{validationstate.permissionError}</small>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div class="form-group text-right">
                                        {<button type="button" onClick={() => { onSubmit() }} className="btn btn-md btn-primary"> Save</button>}
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="col-md-8">
                            <GridComponent id='grid'
                                dataSource={permissionList}
                                allowResizing={true}
                                filterSettings={filter}
                                allowFiltering={true}
                                editSettings={editOptions}
                                toolbar={toolbarOptions}
                                allowSorting={true} allowPaging={true}
                                showColumnChooser={true}
                                pageSettings={{ pageCount: 5, pageSizes: true }}
                                allowPdfExport={true} allowExcelExport={true}
                                commandClick={commandClick.bind(this)}>
                                <ColumnsDirective>
                                    <ColumnDirective filter={filter} field="permissionName" width='200' textAlign='Center' headerText="Permission Name" />
                                    <ColumnDirective allowReordering={false} headerText='Manage Records' width='200' textAlign='Center' commands={commands}></ColumnDirective>
                                </ColumnsDirective>
                                <Inject services={[Reorder,Resize, Sort, Edit, Page, Toolbar, ColumnChooser, CommandColumn, Filter, PdfExport, ExcelExport]} />
                            </GridComponent>
                        </div>
                    </div>

                </div>
            </div>
        </form>
    );

}