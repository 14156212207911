import * as ReactDOM from 'react-dom';
import * as React from 'react';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import { user_Service } from "../Services/user_Service";
import { Role_Service } from "../Services/Role_Service";
import { Audit_Service } from "../Services/Audit_Service";
import { TabComponent, TabItemDirective, TabItemsDirective } from '@syncfusion/ej2-react-navigations';
import {
    GridComponent,
    ColumnDirective,
    ColumnsDirective,
    Page,
    Edit,
    Toolbar,
    Inject, ColumnChooser, CommandColumn, Filter, PdfExport, ExcelExport, Grid, Sort, Reorder, Resize, Group, VirtualScroll, InfiniteScroll
} from "@syncfusion/ej2-react-grids";
import AuditName from './Common/AuditName'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { ViewToaster } from "./ViewToaster";
import { Link } from "react-router-dom";
import Swal from 'sweetalert2';

export class Audit extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            Userdata: [],

            clientUserdata: [],
            clientRoleData: [],

            auditUserdata: [],
            auditRoleData: [],

            roleData: [],

            TabKey: "auditTab",

            auditTabDisable: true,
            clientTeamTabDisable: true,
            auditTeamTabDisable: true,
            completeTabDisable: true,

            objAuditData: {
                auditId: 0,
                auditName: "",
                description: "",
                auditStartDate: "",
                auditEndDate: "",
                auditPeriod: "",
            },

            objClientTeamData: {
                userId: 0,
                name: "",
                emailId: "",
                roleId: 0,
                userTypeId: 0,
                timeZoneId: 0,
                mobileNo: ""
            },

            clientDataError: {
                nameError: "",
                emailError: "",
                roleError: "",
            },


            objAuditTeamData: {
                userId: 0,
                name: "",
                emailId: "",
                roleId: 0,
                userTypeId: 0,
                timeZoneId: 0,
                mobileNo: ""
            },

            auditDataError: {
                nameError: "",
                emailError: "",
                roleError: "",
            },

        }

        this.today = new Date();
        this.headerText = [
            { text: "Audit" },
            { text: "Manage Client Team" },
            { text: "Manage Audit Team" },
            { text: "Complete" }
        ];

        // For Filtering
        this.filter = {
            type: 'Menu'
        };
        this.check = {
            type: 'CheckBox'
        };
        this.groupOptions = { showGroupedColumn: false };

        this.toolbarOptions = ["Search",]; //'ColumnChooser',

        this.commands = [{ type: 'Edit', buttonOption: { iconCss: 'e-icons e-edit', cssClass: 'e-flat' } },
        { type: 'Delete', buttonOption: { iconCss: 'e-icons e-delete', cssClass: 'e-flat' } }];

        this.grid = Grid;
        this.auditgrid = Grid;
    }


    getClientUserData = async () => {
        user_Service.getUserListByUserType(3).then((result) =>
            this.setState({ clientUserdata: result.data })
        )
    }

    getAuditUserData = async () => {
        user_Service.getUserListByUserType(2).then((result) =>
            this.setState({ auditUserdata: result.data })
        )
    }


    getClientRoleData = async () => {
        Role_Service.getRoleListByUserType(3).then((result) =>
            this.setState({ clientRoleData: result.data })
        )
    }

    getAuditRoleData = async () => {
        Role_Service.getRoleListByUserType(2).then((result) =>
            this.setState({ auditRoleData: result.data })
        )
    }


    getAuditExistingData = async () => {
        Audit_Service.getAuditById(this.props.location.state.objAuditId).then((result) => {
            this.setState({
                objAuditData: result.data[0]
            })

        });
    }


    componentDidMount() {

        if (this.props.location.state != undefined && this.props.location.state.objAuditId > 0) {
            this.getAuditExistingData()
            this.setState({ TabKey: this.props.location.state.tabName })
        }
        else {
            this.setState({ objAuditData: { auditId: 0 } })
        }

        this.getClientRoleData();
        this.getClientUserData();

        this.getAuditUserData();
        this.getAuditRoleData();



    }
    commandClick = (args) => { }
    onInputChange = e => {
    };

    btnClicked(e) {
        switch (e.target.id) {
            case "gotoNextClientTeam":
                this.setState({ TabKey: "manageClientTeamTab", auditTabDisable: true, clientTeamTabDisable: false, auditTeamTabDisable: true, completeTabDisable: true })
                break;
            case "gotoNextAuditTeam":
                this.setState({ TabKey: "manageAuditTeamTab", auditTabDisable: true, clientTeamTabDisable: true, auditTeamTabDisable: false, completeTabDisable: true })
                break;
            case "Sucess":
                this.setState({ TabKey: "completeTab", auditTabDisable: true, clientTeamTabDisable: true, auditTeamTabDisable: true, completeTabDisable: false })
                setTimeout(() => {
                    this.props.history.push('/audit')
                }, 3000);

                break;

            case "goToAudit":
                this.setState({ TabKey: "auditTab", auditTabDisable: true, clientTeamTabDisable: false, auditTeamTabDisable: false, completeTabDisable: false })
                break;
            case "goBackToAuditTeam":
                this.setState({ TabKey: "manageClientTeamTab", auditTabDisable: false, clientTeamTabDisable: false, auditTeamTabDisable: true, completeTabDisable: false })
                break;
        }
    }

    getDefaultDate(datestring) {
        var date;
        if (datestring === "" || datestring === undefined) {
            var curr = new Date();
            curr.setDate(curr.getDate());
            date = curr.toISOString().substr(0, 10);
        }
        else {
            date = datestring.substr(0, 10);
        }
        return date;
    }

    changeHandler = e => {
        this.setState({
            objAuditData: {
                ...this.state.objAuditData,
                [e.target.id]: e.target.value
            }
        });
    }

    dateChange = e => {
        this.setState({ objAuditData: { ...this.state.objAuditData, [e.target.id]: e.target.value } });
    };

    onAuditSubmit = async (e) => {
        e.preventDefault();
        if (this.state.objAuditData.auditStartDate === "") {
            this.state.objAuditData.auditStartDate = this.getDefaultDate("");
        }
        if (this.state.objAuditData.auditEndDate === "") {
            this.state.objAuditData.auditEndDate = this.getDefaultDate("");
        }

        Audit_Service.addUpdateAudit(this.state.objAuditData).then(result => {
            if (result.data.success === true) {
                this.setState({ objAuditData: { auditId: result.data.data } });
            }
            ViewToaster.getToaster(result.data.responseText, result.data.success);
        }).catch(err => ViewToaster.getToaster(err.response.data.responseText, err.response.data.success));
    }


    //Client team
    clientUservalidate = () => {

        let haveError = true;
        let objNameError = "";
        let objEmailError = "";
        let objRoleError = "";

        if (!this.state.objClientTeamData.name.length > 0) {
            objNameError = "Please Enter User Name";
            haveError = false
        }

        if (this.state.objClientTeamData.emailId.length > 0) {
            var emailpattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            if (!emailpattern.test(this.state.objClientTeamData.emailId)) {
                objEmailError = "Please enter valid email address";
                haveError = false
            }
        }
        else {
            objEmailError = "Please enter email address";
            haveError = false
        }

        if (!this.state.objClientTeamData.roleId > 0 || this.state.objClientTeamData.roleId == "0") {
            objRoleError = "Please select role";
            haveError = false
        }

        this.setState({ clientDataError: { nameError: objNameError, emailError: objEmailError, roleError: objRoleError, } })
        return haveError;
    };

    onClientUserInputeChange = (e) => {
        this.setState({
            objClientTeamData: {
                ...this.state.objClientTeamData,
                [e.target.id]: e.target.value
            }
        });
    }

    addClientUser = () => {
        const isValid = this.clientUservalidate();
        if (isValid) {
            this.state.objClientTeamData.userTypeId = 3;
            user_Service.addUpdateUser(this.state.objClientTeamData).then(result => {
                this.getClientUserData();
                this.setState({ objClientTeamData: { name: "", emailId: "", roleId: 0, userTypeId: 0 } })
                ViewToaster.getToaster(result.data.responseText, result.data.success);
            }).catch(err => ViewToaster.getToaster(err.response.data.responseText, err.response.data.success));
        }
    }
    //End Client Team

    //Audit Team
    auditUservalidate = () => {

        let haveError = true;
        let objNameError = "";
        let objEmailError = "";
        let objRoleError = "";

        if (!this.state.objAuditTeamData.name.length > 0) {
            objNameError = "Please Enter User Name";
            haveError = false
        }

        if (this.state.objAuditTeamData.emailId.length > 0) {
            var emailpattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            if (!emailpattern.test(this.state.objAuditTeamData.emailId)) {
                objEmailError = "Please enter valid email address";
                haveError = false
            }
        }
        else {
            objEmailError = "Please enter email address";
            haveError = false
        }

        if (!this.state.objAuditTeamData.roleId > 0 || this.state.objAuditTeamData.roleId == "0") {
            objRoleError = "Please select role";
            haveError = false
        }

        this.setState({ auditDataError: { nameError: objNameError, emailError: objEmailError, roleError: objRoleError, } })
        return haveError;
    };

    onAuditUserInputeChange = (e) => {
        this.setState({
            objAuditTeamData: {
                ...this.state.objAuditTeamData,
                [e.target.id]: e.target.value
            }
        });
    }

    addAuditUser = () => {
        const isValid = this.auditUservalidate();
        if (isValid) {
            this.state.objAuditTeamData.userTypeId = 2;
            user_Service.addUpdateUser(this.state.objAuditTeamData).then(result => {
                this.getAuditUserData();
                this.setState({ objAuditTeamData: { name: "", emailId: "", roleId: 0, userTypeId: 0 } })
                ViewToaster.getToaster(result.data.responseText, result.data.success);
            }).catch(err => ViewToaster.getToaster(err.response.data.responseText, err.response.data.success));
        }
    }

    // End Audit Team


    addSielectedClientOrAuditInToAudit(type) {
        var selectedRecords;
        if (type == 1)
            selectedRecords = this.grid.getSelectedRecords();
        if (type == 2) {
            selectedRecords = this.auditgrid.getSelectedRecords();
        }
        if (selectedRecords.length > 0) {
            let userids = [];
            userids = selectedRecords.map((e, key) => e.userId)
            if (this.state.objAuditData.auditId > 0) {
                let Ids = userids.join();

                Audit_Service.addAuditUser(Ids, this.state.objAuditData.auditId).then(result => {
                    ViewToaster.getToaster(result.data.responseText, result.data.success);
                }).catch(err => ViewToaster.getToaster(err.response.data.responseText, err.response.data.success));

            }
            else {
                ViewToaster.getToaster("something went wrong please try again later.", false);
            }
        }
        else {
            Swal.fire({
                text: "Please select atleast one column from given list.",
                confirmButtonColor: '#043a05',
                confirmButtonText: 'Ok'
            }).then((result) => {
                if (result.isConfirmed) {
                }
            })
        }
    }


    render() {
        return (<div>
            <div className="my-4 container-fluid">
                <div class="row">
                    <div class="col-md-6"></div>
                    <div class="col-md-6 text-right"><Link to="/AuditList" className="btn btn-md btn-danger">Exit</Link></div>
                    <hr></hr>
                </div>
                <div className="col-lg-12 control-section e-tab-section">

                    <Tabs id="controlled-tab-example" activeKey={this.state.TabKey} onSelect={(k) => this.setState({ TabKey: k })} >
                        <Tab eventKey="auditTab" title="Audit" disabled>
                            <div className="card">
                                <div className="card-body">
                                    <div class="row align-self-center">
                                        <div className="col-md-3"></div>
                                        <div class="col-md-6 col-sm-12 col-xs-12">
                                            <form className="container" onSubmit={this.onAuditSubmit}>
                                                <div class="form-body">
                                                    <div class="form-group row">
                                                        <div class="col-md-12 my-1">
                                                            <label className="form-label">Audit Name</label><span className="text-danger reqired-fields">*</span>
                                                            <input id="auditName" type="text" value={this.state.objAuditData.auditName} onChange={e => this.changeHandler(e)} className="form-control" placeholder="Enter Audit Name" />
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <div class="col-md-12 my-1">
                                                            <label className="form-label">Description</label><span className="text-danger reqired-fields">*</span>
                                                            <textarea type="text" rows="4" value={this.state.objAuditData.description} cols="50" onChange={e => this.changeHandler(e)} className="form-control" id="description" placeholder="Enter Request Description" />
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <div class="col-md-12 my-1">
                                                            <label className="form-label">Audit Start Data</label><span className="text-danger reqired-fields">*</span>
                                                            <input type="date" min={this.getDefaultDate(this.state.objAuditData.auditStartDate)} dateFormat={"mm-dd-yyyy"} value={this.getDefaultDate(this.state.objAuditData.auditStartDate)} onChange={date => this.dateChange(date)} className="form-control" id="auditStartDate" />
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <div class="col-md-12 my-1">
                                                            <label className="form-label">Target End Date</label><span className="text-danger reqired-fields">*</span>
                                                            <input type="date" min={this.getDefaultDate(this.state.objAuditData.auditEndDate)} dateFormat={"mm-dd-yyyy"} value={this.getDefaultDate(this.state.objAuditData.auditEndDate)} onChange={date => this.dateChange(date)} className="form-control" id="auditEndDate" />
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <div class="col-md-12 my-1">
                                                            <label className="form-label">Audit Period</label><span className="text-danger reqired-fields">*</span>
                                                            <input type="text" value={this.state.objAuditData.auditPeriod} onChange={e => this.changeHandler(e)} className="form-control" id="auditPeriod" placeholder="Enter Audit Period" />
                                                        </div>
                                                    </div>

                                                    <div className="form-group row">
                                                        <div class="col-md-12 my-1">
                                                            <div class="form-group text-right">
                                                                {<button type="submit" className="btn btn-md btn-primary">Submit</button>}
                                                                <button type="button" id="gotoNextClientTeam" className="btn btn-md btn-success" style={{ marginLeft: "10px" }} onClick={this.btnClicked.bind(this)}>
                                                                    Next
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>

                                        <div className="col-md-3"></div>
                                    </div>
                                </div>
                            </div>
                        </Tab>
                        <Tab eventKey="manageClientTeamTab" title="Manage Client Team" disabled >
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-12 col-sm-12 col-xs-12" >
                                            <div class="form-body">
                                                <div className="form-group row">
                                                    <div class="col-md-12">
                                                        <div class="text-right">
                                                            <button id="goToAudit" className="btn btn-md btn-danger" style={{ marginRight: "10px" }} onClick={this.btnClicked.bind(this)}>Previous</button>
                                                            {<button type="button" onClick={() => this.addSielectedClientOrAuditInToAudit(1)} className="btn btn-md btn-primary"> Save</button>}
                                                            <button id="gotoNextAuditTeam" className="btn btn-md btn-success" style={{ marginLeft: "10px" }} onClick={this.btnClicked.bind(this)}>
                                                                Next
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="card">
                                                <div className="card-header text-center">
                                                    <h6 className="card-text">Add new client member</h6>
                                                </div>
                                                <div className="card-body">
                                                    <div class="form-group row">
                                                        <div class="col-md-4">
                                                            <label htmlFor="name" className="form-label">Name</label><span className="text-danger reqired-fields">*</span>
                                                            <input value={this.state.objClientTeamData.name} type="text" onChange={e => this.onClientUserInputeChange(e)} className="form-control" id="name" placeholder="Enter User Name" />
                                                            <small class="text-danger">{this.state.clientDataError.nameError}</small>
                                                        </div>
                                                        <div class="col-md-4">
                                                            <label htmlFor="emailId" className="form-label">EmailId</label><span className="text-danger reqired-fields">*</span>
                                                            <input type="text" value={this.state.objClientTeamData.emailId} onChange={e => this.onClientUserInputeChange(e)} className="form-control" id="emailId" placeholder="Enter EmailId" />
                                                            <small class="text-danger">{this.state.clientDataError.emailError}</small>
                                                        </div>
                                                        <div class="col-md-4">
                                                            <label htmlFor="mobileNo" className="form-label">Role</label><span className="text-danger reqired-fields">*</span>

                                                            <select value={this.state.objClientTeamData.roleId} name="roleId" id="roleId" onChange={e => this.onClientUserInputeChange(e)} className='form-control '>
                                                                <option value={0}>-- Select Role --</option>
                                                                {
                                                                    this.state.clientRoleData.map((e, key) => {
                                                                        return <option key={key} value={e.roleId} >{e.roleName}</option>;
                                                                    })
                                                                }
                                                            </select>
                                                            <small class="text-danger">{this.state.clientDataError.roleError}</small>

                                                        </div>
                                                    </div>

                                                    <div class="form-group row text-center float-right">
                                                        <button type="button" onClick={this.addClientUser} className="btn btn-md btn-primary">Add User</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-md-12 pb-3 ">
                                            <h3 class="text-center"><span style={{ padding: "6px", background: "#043A05", fontSize: "12px", color: "#fff", borderRadius: "20px" }}>OR</span></h3>
                                        </div>

                                    </div>
                                    <div className="row">
                                        <div className="card">
                                            <div className="card-header text-center">
                                                <div className="col-md-12 text-center">
                                                    <h5 className="card-text">Select from existing client team list</h5>
                                                </div>
                                            </div>
                                            <div className="card-body">
                                                <div className="col-md-12">
                                                    <GridComponent id='grid'
                                                        dataSource={this.state.clientUserdata}
                                                        allowResizing={true}
                                                        filterSettings={this.filter}
                                                        allowFiltering={true}
                                                        editSettings={this.editOptions}
                                                        toolbar={this.toolbarOptions}
                                                        allowSorting={true} allowPaging={true}
                                                        showColumnChooser={true}
                                                        pageSettings={{ pageCount: 5, pageSizes: true }}
                                                        allowPdfExport={true} allowExcelExport={true}
                                                        ref={g => this.grid = g}>
                                                        <ColumnsDirective>
                                                            <ColumnDirective type='checkbox' allowSorting={false} allowFiltering={false} width='10'></ColumnDirective>
                                                            <ColumnDirective filter={this.filter} field="name" width='200' textAlign='left' headerText="Name" />
                                                            <ColumnDirective filter={this.filter} field="emailId" width='200' textAlign='left' headerText="EmailId" />
                                                            <ColumnDirective filter={this.check} field="roleName" width='100' textAlign='left' headerText="Role" />

                                                            {/* <ColumnDirective filter={this.filter} field="mobileNo" width='150' textAlign='left' headerText="Mobile No" />
                                            <ColumnDirective allowReordering={false} headerText='Manage Records' width='150' textAlign='Center' commands={this.commands}></ColumnDirective> */}
                                                        </ColumnsDirective>
                                                        <Inject services={[Reorder, Resize, Sort, Edit, Page, Toolbar, ColumnChooser, CommandColumn, Filter, PdfExport, ExcelExport]} />
                                                    </GridComponent>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="row">
                                        <div className="col-md-12 col-sm-12 col-xs-12" >
                                            <div class="form-body">
                                                <div className="form-group row">
                                                    <div class="col-md-12">
                                                        <div class="text-right">
                                                            <button id="goToAudit" className="btn btn-md btn-danger" style={{ marginRight: "10px" }} onClick={this.btnClicked.bind(this)}>Previous</button>
                                                            {<button type="button" onClick={() => this.addSielectedClientOrAuditInToAudit(1)} className="btn btn-md btn-primary"> Save</button>}
                                                            <button id="gotoNextAuditTeam" className="btn btn-md btn-success" style={{ marginLeft: "10px" }} onClick={this.btnClicked.bind(this)}>
                                                                Next
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </Tab>

                        <Tab eventKey="manageAuditTeamTab" title="Manage Audit Team" disabled>
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-12 col-sm-12 col-xs-12" >
                                            <div class="form-body">
                                                <div className="form-group row">
                                                    <div class="col-md-12">
                                                        <div class="text-right">
                                                            <button id="goBackToAuditTeam" className="btn btn-md btn-danger" style={{ marginRight: "10px" }} onClick={this.btnClicked.bind(this)}>Previous</button>
                                                            {<button type="button" onClick={() => this.addSielectedClientOrAuditInToAudit(2)} className="btn btn-md btn-primary"> Save</button>}
                                                            <button id="Sucess" className="btn btn-md btn-success" style={{ marginLeft: "10px" }} onClick={this.btnClicked.bind(this)}>
                                                                Next
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="card">
                                                <div className="card-header text-center">
                                                    <h6 className="card-text">Add new Audit member</h6>
                                                </div>
                                                <div className="card-body">
                                                    <div class="form-group row">
                                                        <div class="col-md-4">
                                                            <label htmlFor="name" className="form-label">Name</label><span className="text-danger reqired-fields">*</span>
                                                            <input value={this.state.objAuditTeamData.name} type="text" onChange={e => this.onAuditUserInputeChange(e)} className="form-control" id="name" placeholder="Enter User Name" />
                                                            <small class="text-danger">{this.state.auditDataError.nameError}</small>
                                                        </div>
                                                        <div class="col-md-4">
                                                            <label htmlFor="emailId" className="form-label">EmailId</label><span className="text-danger reqired-fields">*</span>
                                                            <input type="text" value={this.state.objAuditTeamData.emailId} onChange={e => this.onAuditUserInputeChange(e)} className="form-control" id="emailId" placeholder="Enter EmailId" />
                                                            <small class="text-danger">{this.state.auditDataError.emailError}</small>
                                                        </div>
                                                        <div class="col-md-4">
                                                            <label htmlFor="mobileNo" className="form-label">Role</label><span className="text-danger reqired-fields">*</span>

                                                            <select value={this.state.objAuditTeamData.roleId} name="roleId" id="roleId" onChange={e => this.onAuditUserInputeChange(e)} className='form-control '>
                                                                <option value={0}>-- Select Role --</option>
                                                                {
                                                                    this.state.auditRoleData.map((e, key) => {
                                                                        return <option key={key} value={e.roleId} >{e.roleName}</option>;
                                                                    })
                                                                }
                                                            </select>
                                                            <small class="text-danger">{this.state.auditDataError.roleError}</small>

                                                        </div>
                                                    </div>

                                                    <div class="form-group row text-center float-right">
                                                        <button type="button" onClick={this.addAuditUser} className="btn btn-md btn-primary">Add User</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-md-12 pb-3 ">
                                            <h3 class="text-center"><span style={{ padding: "6px", background: "#043A05", fontSize: "12px", color: "#fff", borderRadius: "20px" }}>OR</span></h3>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="card">
                                            <div className="card-header text-center">
                                                <div className="col-md-12 text-center">
                                                    <h6 className="card-text">Select from existing client team list</h6>
                                                </div>
                                            </div>
                                            <div className="card-body">
                                                <div className="col-md-12">
                                                    <GridComponent id='grid'
                                                        dataSource={this.state.auditUserdata}
                                                        filterSettings={this.filter}
                                                        allowResizing={true}
                                                        allowFiltering={true}
                                                        editSettings={this.editOptions}
                                                        toolbar={this.toolbarOptions}
                                                        allowSorting={true} allowPaging={true}
                                                        showColumnChooser={true}
                                                        pageSettings={{ pageCount: 5, pageSizes: true }}
                                                        allowPdfExport={true} allowExcelExport={true}
                                                        ref={g => this.auditgrid = g} >
                                                        <ColumnsDirective>
                                                            <ColumnDirective type='checkbox' allowSorting={false} allowFiltering={false} width='10'></ColumnDirective>
                                                            <ColumnDirective filter={this.filter} field="name" width='200' textAlign='left' headerText="Name" />
                                                            <ColumnDirective filter={this.filter} field="emailId" width='200' textAlign='left' headerText="EmailId" />
                                                            <ColumnDirective filter={this.check} field="roleName" width='100' textAlign='left' headerText="Role" />

                                                            {/* <ColumnDirective filter={this.filter} field="mobileNo" width='150' textAlign='left' headerText="Mobile No" />
                                            <ColumnDirective allowReordering={false} headerText='Manage Records' width='150' textAlign='Center' commands={this.commands}></ColumnDirective> */}
                                                        </ColumnsDirective>
                                                        <Inject services={[Reorder, Resize, Sort, Edit, Page, Toolbar, ColumnChooser, CommandColumn, Filter, PdfExport, ExcelExport]} />
                                                    </GridComponent>
                                                </div>
                                            </div>
                                        </div>

                                    </div>


                                    <div className="row">
                                        <div className="col-md-12 col-sm-12 col-xs-12" >
                                            <div class="form-body">
                                                <div className="form-group row">
                                                    <div class="col-md-12">
                                                        <div class="text-right">
                                                            <button id="goBackToAuditTeam" className="btn btn-md btn-danger" style={{ marginRight: "10px" }} onClick={this.btnClicked.bind(this)}>Previous</button>
                                                            {<button type="button" onClick={() => this.addSielectedClientOrAuditInToAudit(2)} className="btn btn-md btn-primary"> Save</button>}
                                                            <button id="Sucess" className="btn btn-md btn-success" style={{ marginLeft: "10px" }} onClick={this.btnClicked.bind(this)}>
                                                                Next
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>


                        </Tab>

                        <Tab eventKey="completeTab" title="Complete" disabled>

                            <div id="confirm">
                                <div className="card">
                                    <div className="text-center" style={{ padding: "100px" }}>
                                        <img style={{ width: "100px" }} src={require('../dist/icons/check-circle.gif').default} ></img>
                                        <h3 className="card-text">Audit has been successfully Created</h3>
                                    </div>
                                </div>
                            </div>

                        </Tab>
                    </Tabs>


                </div>
            </div>
        </div>);
    }
}