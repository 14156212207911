import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios'
import { MassUpdate } from "../Component/MassUpdate";
toast.configure()

const Url = process.env.REACT_APP_API_URL;
export const PBCRequest_Service = {
    // getToaster ,
    getPBCRequestList,
    getRowsShowHideData,
    getHeaderText,
    exporingGrid,
    deletePCBRequestById,
    getDocumentsImageInGrid,
    getCommentsImageInGrid,
    PBCRequestDataById,
    getStatusList,
    getprocessAreaList,
    getSubProcessList,
    getControlList,
    getItemSecurityList,
    submitPBCRequestData,
    getDemoHeaderText,
    massUpdate,
    showMassUpdate,
    UpdatePBCRequestFlow,
    getroleByUserId,
    getLastTaskData,
    getDraftComment,
    getFinalSubmissionAdvanceStatus,
    getSubmissionAdvanceStatus
}


async function getPBCRequestList(userId) {
    const result = await axios.get(`${Url}pbcRequest/getPBCRequestList?userId=${userId}`);
    return result;
}

async function getRowsShowHideData() {
    const result = await axios.get(`${Url}pbcRequest/getRowsShowHideData`);
    return result;
}

async function getHeaderText() {
    const result = await axios.get(`${Url}pbcRequest/getHeaderText`);
    return result;
}

async function getDemoHeaderText() {
    const result = await axios.get(`${Url}pbcRequest/getDemoHeaderText`);
    return result;
}

async function deletePCBRequestById(id) {
    const result = await axios.post(`${Url}pbcRequest/deletePCBRequest?id=${id}`);
    return result;
}


async function submitPBCRequestData(pbcrequestData, pbcRequestDueDate) {
    const objdata = {
        PbcRequestId: pbcrequestData.pbcRequestId,
        PbcDesc: pbcrequestData.pbcDesc,
        ControlOwnerEmailId: pbcrequestData.controlOwnerEmailId,
        ControlId: parseInt(pbcrequestData.controlId, 10),
        ProcessAreaId: parseInt(pbcrequestData.processAreaId, 10),
        SubProcessAreaId: parseInt(pbcrequestData.subProcessAreaId, 10),
        PbcRequestDueDate: pbcRequestDueDate,
        RequestItemSecurityId: parseInt(pbcrequestData.requestItemSecurityId, 10),
        AdditionalField1: pbcrequestData.additionalField1,
        AdditionalField2: pbcrequestData.additionalField2,
        AdditionalField3: pbcrequestData.additionalField3,
        AdditionalField4: pbcrequestData.additionalField4,
        AdditionalField5: pbcrequestData.additionalField5,
        AdditionalField6: pbcrequestData.additionalField6,
        AdditionalField7: pbcrequestData.additionalField7,
        AdditionalField8: pbcrequestData.additionalField8,
        AdditionalField9: pbcrequestData.additionalField9,
        AdditionalField10: pbcrequestData.additionalField10,
        EscalationEmailIds: pbcrequestData.escalationEmailIds,
        StatusId: parseInt(pbcrequestData.statusId, 10)
    };
    const result = await axios.post(`${Url}pbcRequest/addOrUpdate/`, objdata);
    return result;
}

function getDocumentsImageInGrid(props) {
    var cssClass = "e-icons e-M_PV_Thumbnail";
    var colorcss = props.documentsCount > 0 ? "#043A05" : "red";
    return (
        <div>
            <div style={{ fontSize: "12px", color: colorcss }} > <span class={cssClass} style={{ color: colorcss }}></span>{props.documentsCount > 0 ? `(${props.documentsCount})` : ""}</div>
        </div>
    );
}

function getCommentsImageInGrid(props) {
    var cssClass = "e-icons e-M_Comment";
    var colorcss = props.commentsCount > 0 ? "#043A05" : "gray";
    return (<div style={{ fontSize: "12px", color: colorcss }} > <span class={cssClass} style={{ color: colorcss }}></span>{props.commentsCount > 0 ? `(${props.commentsCount})` : ""}</div>);

}

async function PBCRequestDataById(id) {
    const result = await axios.get(`${Url}pbcRequest/getPBCRequestById?id=${id}`);
    return result;
}

async function getStatusList() {
    const result = await axios.get(`${Url}status/getStatusList`);
    return result;
}

async function getprocessAreaList() {

    const result = await axios.get(`${Url}processArea/getProcessAreaList`);
    return result;
}

async function getSubProcessList() {
    const result = await axios.get(`${Url}subProcessArea/getSubProcessAreaList`);
    return result;
}
async function getControlList() {
    const result = await axios.get(`${Url}control/getControlList`);
    return result;
}
async function getItemSecurityList() {
    const result = await axios.get(`${Url}requestItemSecurity/getItemSecurityList`);
    return result;
}


// async function getDefaultDateValue(datestring){
//     var date;
//     if (datestring === "") {
//         var curr = new Date();
//         curr.setDate(curr.getDate());
//         date = curr.toISOString().substr(0, 10);
//     }
//     else {
//         date = datestring.substr(0, 10);
//     }
//     return date;
// }

function exporingGrid(args, grid) {
    switch (args.item.text) {
        case 'PDF Export':
            grid.pdfExport();
            break;
        case 'Excel Export':
            grid.excelExport();
            break;
        case 'CSV Export':
            grid.csvExport();
            break;
    }
}

async function massUpdate(pbcRequestIds, fieldIds, oldValue, newValue) {
    const result = await axios.post(`${Url}pbcMassUpdate/massUpdate?pbcRequestIds=${pbcRequestIds}&fieldIds=${fieldIds}&oldValue=${oldValue}&newValue=${newValue}`);
    return result;
}

async function showMassUpdate(selectedColumns, divShowHide) {
    return (<> < MassUpdate data={selectedColumns} showHide={divShowHide} /></>)
}

async function UpdatePBCRequestFlow(userId, pbcRequestIds) {
    const result = await axios.post(`${Url}pbcRequest/UpdatePBCRequestFlow?userId=${userId}&pbcRequestIds=${pbcRequestIds}&status=${2}`);
    return result;
}

async function getroleByUserId(userId) {
    const result = await axios.get(`${Url}user/getRoleByUserId?userId=${userId}`);
    return result;
}

async function getLastTaskData(pbcRequestId) {
    const result = await axios.get(`${Url}pbcRequest/getLastTaskData?pbcRequestId=${pbcRequestId}`);
    return result;
}

async function getDraftComment(pbcRequestId, userId) {
    const result = await axios.get(`${Url}pbcRequest/getDraftComment?pbcRequestId=${pbcRequestId}&userId=${userId}`);
    return result;
}

async function getFinalSubmissionAdvanceStatus(id) {
    const result = await axios.get(`${Url}pbcRequest/getFinalSubmissionAdvanceStatus?pbcRequestId=${id}`);
    return result;
}

async function getSubmissionAdvanceStatus(userId, pbcrequestId, requestType) {
    const result = await axios.get(`${Url}pbcRequest/getSubmissionAdvanceStatus?userId=${userId}&pbcrequestId=${pbcrequestId}&requestType=${requestType}`);
    return result;
}
