import React, { useState, useEffect } from "react";
import "react-datetime/css/react-datetime.css";
import { ViewToaster } from "./ViewToaster";
import { PBCRequest_Service } from "../Services/PBCRequest_Service";
import Cookies from 'universal-cookie';
import Modal from "react-bootstrap/Modal";
import { Documents } from "./Documents";
import FileSaver from 'file-saver';
import axios from 'axios'
import { Link, useHistory } from "react-router-dom";
export const AddEditPBCRequest = (props) => {

    // let IsEditable=props.editable;
    let Url = process.env.REACT_APP_API_URL;
    //Defining global variables.
    let [pbcrequestData, setPBCRequest] = useState({
        pbcRequestId: 0,
        pbcDesc: "",
        controlOwnerEmailId: "",
        controlId: 0,
        processAreaId: 0,
        subProcessAreaId: 0,
        pbcRequestDueDate: "",
        requestItemSecurityId: 0,
        additionalField1: "",
        additionalField2: "",
        additionalField3: "",
        additionalField4: "",
        additionalField5: "",
        additionalField6: "",
        additionalField7: "",
        additionalField8: "",
        additionalField9: "",
        additionalField10: "",
        escalationEmailIds: "",
        statusId: 1,
        IsEditable: props.editable

    });

    let { pbcDesc, controlOwnerEmailId,
        controlId, processAreaId, subProcessAreaId,
        pbcRequestDueDate, requestItemSecurityId, additionalField1, additionalField2, additionalField3, additionalField4,
        additionalField5, additionalField6, additionalField7, additionalField8, additionalField9, additionalField10, escalationEmailIds, statusId, IsEditable = props.editable } = pbcrequestData;
    const onInputChange = e => {
        setPBCRequest({ ...pbcrequestData, [e.target.id]: e.target.value });

    };

    //Get PCB Request By Id
    const loadPBCRequestById = async () => {
        PBCRequest_Service.PBCRequestDataById(props.data.pbcRequestId).then((result) => {
            setPBCRequest(result.data)
        });
    };

    //Status List
    const [StatusData, setStatus] = useState([]);
    const statusList = async () => {
        PBCRequest_Service.getStatusList().then((result) => setStatus(result.data));
    }

    //Proceess Area List
    const [ProcessAreaData, setProcessArea] = useState([]);
    const processAreaList = async () => {
        PBCRequest_Service.getprocessAreaList().then((result) => setProcessArea(result.data));
    }

    //Sub Process Area List
    const [SubProcessAreaData, setSubProcessArea] = useState([]);
    const subprocessAreaList = async () => {
        PBCRequest_Service.getSubProcessList().then((result) => setSubProcessArea(result.data));
    }
    //Control List
    const [ControlData, setControl] = useState([]);
    const controlList = async () => {
        PBCRequest_Service.getControlList().then((result) => setControl(result.data));
    }

    //Item Security List
    const [ItemSecurityData, setItemSecurity] = useState([]);
    const itremSecurityList = async () => {
        PBCRequest_Service.getItemSecurityList().then((result) => setItemSecurity(result.data));
    }

    const [headerTextData, setHeaderText] = useState([]);
    const getHeaderTexts = async () => {
        PBCRequest_Service.getHeaderText().then((result) => {
            setHeaderText(result.data);
        })
    }



    useEffect(() => {
        if (props.data != null && props.data.pbcRequestId > 0) {
            loadPBCRequestById();
        }
        else {

            setPBCRequest({
                pbcRequestId: 0,
                pbcDesc: "",
                controlOwnerEmailId: "",
                controlId: 0,
                processAreaId: 0,
                subProcessAreaId: 0,
                pbcRequestDueDate: "",
                requestItemSecurityId: 0,
                additionalField1: "",
                additionalField2: "",
                additionalField3: "",
                additionalField4: "",
                additionalField5: "",
                additionalField6: "",
                additionalField7: "",
                additionalField8: "",
                additionalField9: "",
                additionalField10: "",
                escalationEmailIds: "",
                statusId: 1,
                IsEditable: props.editable

            });
        }
        getHeaderTexts();
        processAreaList();
        subprocessAreaList();
        itremSecurityList();
        controlList();
        statusList();
        IsEditable = props.editable;
        setValidationState({ processAreaError: "", subprocessAreaError: "", controlError: "", pbcdescError: "", controlOwnerEmailIdError: "", itemSecurityError: "", escalationEmailIdError: "", statusError: "" });
        // setPBCRequest({ ...pbcrequestData, IsEditable: props.editable });
    }, [props.data != null ? props.data.pbcRequestId : props.data], [props.editable]);

    //Form Submiting
    const onSubmit = async () => {
        //  e.preventDefault();
        const isValid = validate();
        if (isValid) {
            setValidationState({ processAreaError: "", subprocessAreaError: "", controlError: "", pbcdescError: "", controlOwnerEmailIdError: "", itemSecurityError: "", escalationEmailIdError: "", statusError: "" });

            PBCRequest_Service.submitPBCRequestData(pbcrequestData, pbcRequestDueDate).then(result => {
                ViewToaster.getToaster(result.data.responseText, result.data.success);
                // setTimeout(() => window.location.reload(true), 2000)
            }).catch(err => ViewToaster.getToaster(err.response.data.responseText, err.response.data.success));
        }
    };

    //Validating Inputes
    let processAreaError = "";
    let subprocessAreaError = "";
    let controlError = "";
    let pbcdescError = "";
    let controlOwnerEmailIdError = "";
    let itemSecurityError = "";
    let escalationEmailIdError = "";
    let statusError = "";
    const [validationstate, setValidationState] = useState(processAreaError,
        subprocessAreaError,
        controlError,
        pbcdescError,
        controlOwnerEmailIdError, itemSecurityError, escalationEmailIdError, statusError);
    const validate = () => {

        if (!processAreaId > 0 || processAreaId === "true") {
            processAreaError = "Please Select Process Area";
        }
        if (!subProcessAreaId > 0 || subProcessAreaId === "true") {
            subprocessAreaError = "Please Select Sub Process Area";
        }
        if (!controlId > 0 || controlId === "true") {
            controlError = "Please Select Control";
        }
        if (!statusId > 0 || statusId === "true") {
            statusError = "Please Select Status";
        }
        if (!pbcDesc.length > 0) {
            pbcdescError = "Please Enter Description";
        }
        if (controlOwnerEmailId.length > 0) {
            var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            if (!pattern.test(controlOwnerEmailId)) {
                controlOwnerEmailIdError = "Please enter valid email address.";
            }
        }
        else {
            controlOwnerEmailIdError = "Please Enter Control Owner Email Address";
        }
        if (!requestItemSecurityId > 0 || requestItemSecurityId === "true") {
            itemSecurityError = "Please Select Request Item Security";
        }

        if (escalationEmailIds.length > 0) {

            var separatedEmails = escalationEmailIds.split(";");

            separatedEmails.forEach(element => {
                var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
                if (!pattern.test(element)) {
                    escalationEmailIdError = "Please enter valid email address.";
                }
            });

        }
        else {
            escalationEmailIdError = "Please Enter Control Owner Email Address";
        }
        if (subprocessAreaError || processAreaError || controlError || pbcdescError || controlOwnerEmailIdError || itemSecurityError || escalationEmailIdError || statusError) {
            setValidationState({ processAreaError, subprocessAreaError, controlError, pbcdescError, controlOwnerEmailIdError, itemSecurityError, escalationEmailIdError, statusError });
            return false;
        }
        return true;
    };

    //set date

    const dateChange = e => {
        setPBCRequest({ ...pbcrequestData, [e.target.id]: e.target.value });
    };

    function getDefaultDate(datestring) {
        var date;
        if (datestring === "") {
            var curr = new Date();
            curr.setDate(curr.getDate());
            date = curr.toISOString().substr(0, 10);
        }
        else {
            date = datestring.substr(0, 10);
        }
        return date;
    }

    const onEdit = () => {
        setPBCRequest({ ...pbcrequestData, IsEditable: true });
    }


    const [isOpen, setIsOpen] = React.useState(false);
    const [title, setTitle] = useState();
    const [tabName, setTabName] = useState("documenthistorytab");
    let [values, SetValues] = useState({});
    const hideModal = () => {
        setIsOpen(false);
        setTitle("");
    };


    const openDocumentsModel = (e, type) => {
        e.preventDefault();
        SetValues(pbcrequestData);
        setIsOpen(true);
        setTitle("Documents");
        if (type === 1) {
            setTabName("documenthistorytab")
        }
        if (type === 2) {
            setTabName("commenttab")
        }
        if (type === 3) {
            setTabName("audittab")
        }
    }

    const SaveZip = async (e) => {
        e.preventDefault();
        await axios.get(`${Url}pbcRequest/generateAdndDownloadZip?pbcRequestId=${pbcrequestData.pbcRequestId}`).then(res => {
            FileSaver.saveAs(res.config.url)
        }).catch(err => ViewToaster.getToaster(err.response.data.responseText, err.response.data.success));
    }


    return (
        <>
            {/* fileUpload And Comments Model */}
            <Modal
                show={isOpen}
                onHide={hideModal}
                size="xl" backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <Documents data={values} cmtpermissions={props.cmtpermissions} userId={props.userId} roleId={props.roleId} KeyTab={tabName} docpermissions={props.docpermissions} />
                </Modal.Body>
            </Modal>
            <div className="container my-3">
                <div className="card border-0 p-0">
                    <div className="card-body">
                        {/* <>
                            {props.data != null && props.data.pbcRequestId > 0 && IsEditable == true ?
                                <div> <div class="row">
                                    <div class="col-md-6"><h4 class="form-section">Edit PBC Request</h4></div>
                                    <div class="col-md-6" style={{ textAlign: "end" }}><h6 class="form-section"><a href="../PBCRequestList" className="btn btn-md btn-danger">Back To List</a></h6></div>
                                </div> <hr></hr></div> : ""}

                            {props.data == null ?
                                <div> <div class="row">
                                    <div class="col-md-6"><h4 class="form-section">Add PBC Request</h4></div>
                                    <div class="col-md-6" style={{ textAlign: "end" }}><h6 class="form-section"><a href="../PBCRequestList" className="btn btn-md btn-danger">Back To List</a></h6></div>
                                </div> <hr></hr></div> : ""}
                        </> */}
                        <form>

                            <div className="mb-3" style={{ textAlign: "right" }}>
                                <div class="row form-group text-right">
                                    <div class="col-md-12">
                                        {<button type="button" onClick={() => { onSubmit() }} disabled={IsEditable == true ? false : true} className="btn btn-primary btn-md">{props.data != null && props.data.pbcRequestId > 0 ? "Update" : "Save"} </button>}&nbsp;
                                        {props.data != null && props.data.pbcRequestId > 0 ? <button type="button" disabled={IsEditable} onClick={() => { onEdit() }} className="btn btn-primary btn-md">Edit</button> : ""}&nbsp;
                                        {props.data != null && props.data.pbcRequestId > 0 ? <button onClick={(e) => { openDocumentsModel(e, 1) }} className="btn btn-primary btn-md">Documents {`(${props.data.documentsCount})`}</button> : ""}&nbsp;
                                        {props.data != null && props.data.pbcRequestId > 0 ? <button onClick={(e) => { openDocumentsModel(e, 2) }} className="btn btn-primary btn-md">Comments {`(${props.data.commentsCount})`}</button> : ""}&nbsp;
                                        {props.data != null && props.data.pbcRequestId > 0 ? <button onClick={(e) => { openDocumentsModel(e, 3) }} className="btn btn-primary btn-md">Audit Trail</button> : ""}&nbsp;
                                        {props.data != null && props.data.pbcRequestId > 0 ? <button onClick={(e) => { SaveZip(e) }} className="btn btn-primary btn-md">Download Documents</button> : ""}
                                    </div>
                                </div>
                            </div>

                            <div className="mb-3">
                                <div class="form-group row">
                                    {(() => {
                                        if (headerTextData.pbcdesc !== undefined && headerTextData.pbcdesc.visibility == true) {
                                            return (
                                                <div class="col-md-12">
                                                    <label htmlFor="pbcDesc" className="form-label">Request Description</label><span className="text-danger reqired-fields">*</span>
                                                    <textarea disabled={IsEditable == true ? false : true} type="text" rows="4" cols="50" value={pbcDesc} onChange={e => onInputChange(e)} className="form-control" id="pbcDesc" placeholder="Enter Request Description" />
                                                    <small class="text-danger">{validationstate.pbcdescError}</small>
                                                </div>
                                            )
                                        }
                                    })()}
                                </div>
                            </div>
                            <div className="mb-3">
                                <div class="form-group row">
                                    {(() => {
                                        if (headerTextData.processArea !== undefined && headerTextData.processArea.visibility == true) {
                                            return (
                                                <div class="col-md-4">
                                                    <label className="form-label">Process Area</label><span className="text-danger reqired-fields">*</span>
                                                    <select disabled={IsEditable == true ? false : true} name="processAreaId" id="processAreaId" value={processAreaId} onChange={e => { onInputChange(e) }} className='form-control '>
                                                        <option value>-- Select Process Area --</option>
                                                        {
                                                            ProcessAreaData.map((e, key) => {
                                                                return <option key={key} value={e.processAreaId} >{e.processAreaName}</option>;
                                                            })
                                                        }
                                                    </select>
                                                    <small class="text-danger">{validationstate.processAreaError}</small>
                                                </div>
                                            )
                                        }

                                    })()}

                                    {(() => {
                                        if (headerTextData.subProcessArea !== undefined && headerTextData.subProcessArea.visibility == true) {
                                            return (
                                                <div class="col-md-4">
                                                    <label className="form-label">Sub Process Area</label><span className="text-danger reqired-fields">*</span>
                                                    <select disabled={IsEditable == true ? false : true} name="subProcessAreaId" id="subProcessAreaId" value={subProcessAreaId} onChange={e => { onInputChange(e) }} className='form-control'>
                                                        <option value>-- Select Sub Process Area --</option>
                                                        {
                                                            SubProcessAreaData.map((e, key) => {
                                                                return <option key={key} value={e.subProcessAreaId} >{e.subProcessAreaName}</option>;
                                                            })
                                                        }
                                                    </select>
                                                    <small class="text-danger">{validationstate.subprocessAreaError}</small>
                                                </div>
                                            )
                                        }
                                    })()}

                                    {(() => {
                                        if (headerTextData.controlId !== undefined && headerTextData.controlId.visibility == true) {
                                            return (
                                                <div class="col-md-4">
                                                    <label className="form-label">Control</label><span className="text-danger reqired-fields">*</span>
                                                    <select disabled={IsEditable == true ? false : true} name="controlId" id="controlId" value={controlId} onChange={e => { onInputChange(e) }} className='form-control'>
                                                        <option value>-- Select Control --</option>
                                                        {
                                                            ControlData.map((e, key) => {
                                                                return <option key={key} value={e.controlId} >{e.controlDesc}</option>;
                                                            })
                                                        }
                                                    </select>
                                                    <small class="text-danger">{validationstate.controlError}</small>
                                                </div>
                                            )
                                        }
                                    })()}



                                </div>
                            </div>
                            <div className="mb-3">
                                <div class="form-group row">

                                    {(() => {

                                        if (headerTextData.requestItemSecurity !== undefined && headerTextData.requestItemSecurity.visibility == true) {
                                            return (
                                                <div class="col-md-4">
                                                    <label className="form-label">Item Security</label><span className="text-danger reqired-fields">*</span>
                                                    <select disabled={IsEditable == true ? false : true} name="requestItemSecurityId" id="requestItemSecurityId" value={requestItemSecurityId} onChange={e => { onInputChange(e) }} className='form-control'>
                                                        <option value>-- Select item Security --</option>
                                                        {
                                                            ItemSecurityData.map((e, key) => {
                                                                return <option key={key} value={e.requestItemSecurityId} >{e.requestItemSecurity}</option>;
                                                            })
                                                        }
                                                    </select>
                                                    <small class="text-danger">{validationstate.itemSecurityError}</small>
                                                </div>
                                            )
                                        }
                                    })()}

                                    {(() => {
                                        if (headerTextData.pbcrequestDueDate !== undefined && headerTextData.pbcrequestDueDate.visibility == true) {
                                            return (
                                                <div class="col-md-4">
                                                    <label htmlFor="pbcRequestDueDate" className="form-label">Select Due Date</label>
                                                    <input disabled={IsEditable == true ? false : true} type="date" min={getDefaultDate(pbcRequestDueDate)} dateFormat={"mm-dd-yyyy"} value={getDefaultDate(pbcRequestDueDate)} onChange={date => dateChange(date)} className="form-control" id="pbcRequestDueDate" />
                                                </div>
                                            )
                                        }
                                    })()}

                                    {(() => {
                                        if (headerTextData.status !== undefined && headerTextData.status.visibility == true) {
                                            return (
                                                <div className="col-md-4">
                                                    <label className="form-label">Status</label><span className="text-danger reqired-fields">*</span>
                                                    <select disabled={statusId == 6 ? false : true} name="statusId" id="statusId" value={statusId} onChange={e => { onInputChange(e) }} className='form-control'>
                                                        <option value>-- Select Status --</option>
                                                        {
                                                            StatusData.map((e, key) => {
                                                                return <option key={key} value={e.statusId} >{e.statusName}</option>;
                                                            })
                                                        }
                                                    </select>
                                                    <small class="text-danger">{validationstate.statusError}</small>
                                                </div>
                                            )
                                        }
                                    })()}


                                </div>
                            </div>
                            <div className="mb-3">
                                <div class="form-group row">
                                    {(() => {
                                        if (headerTextData.controlOwnerEmailId !== undefined && headerTextData.controlOwnerEmailId.visibility == true) {
                                            return (
                                                <div class="col-md-4">
                                                    <label htmlFor="controlOwnerEmailId" className="form-label">Owner EmailId</label><span className="text-danger reqired-fields">*</span>
                                                    <input disabled={IsEditable == true ? false : true} type="text" value={controlOwnerEmailId} onChange={e => onInputChange(e)} className="form-control" id="controlOwnerEmailId" placeholder="Enter Owner EmailId" />
                                                    <small class="text-danger">{validationstate.controlOwnerEmailIdError}</small>
                                                </div>
                                            )
                                        }
                                    })()}

                                    {(() => {
                                        if (headerTextData.escalationEmailIds !== undefined && headerTextData.escalationEmailIds.visibility == true) {
                                            return (
                                                <div class="col-md-4">
                                                    <label htmlFor="escalationEmailIds" className="form-label">Escalation EmailId(s)</label><span className="text-danger reqired-fields">*</span>
                                                    <input disabled={IsEditable == true ? false : true} type="text" value={escalationEmailIds} onChange={e => onInputChange(e)} className="form-control" id="escalationEmailIds" placeholder="Enter Escalation EmailId(s) followed by ;" />
                                                    <small class="text-danger">{validationstate.escalationEmailIdError}</small>
                                                </div>
                                            )
                                        }

                                    })()}

                                    <div class="col-md-4">
                                        <label htmlFor="controlOwnerEmailId" className="form-label">Audit Team Member</label><span className="text-danger reqired-fields">*</span>
                                        <input disabled={IsEditable == true ? false : true} type="text" onChange={e => onInputChange(e)} className="form-control" id="AuditTeamMember" placeholder="Enter Audit Team Member" />
                                        {/* <small class="text-danger">{validationstate.controlOwnerEmailIdError}</small> */}
                                    </div>

                                </div>
                            </div>
                            <div className="mb-3">
                                <div class="form-group row">
                                    <div class="col-md-4">
                                        <label htmlFor="controlOwnerEmailId" className="form-label">Audit Team Manager</label><span className="text-danger reqired-fields">*</span>
                                        <input disabled={IsEditable == true ? false : true} type="text" onChange={e => onInputChange(e)} className="form-control" id="AuditTeamManager" placeholder="Enter Owner Manager" />
                                        {/* <small class="text-danger">{validationstate.controlOwnerEmailIdError}</small> */}
                                    </div>
                                </div>
                            </div>

                            <div className="mb-3">
                                <div class="form-group row">
                                    {(() => {
                                        if (headerTextData.additionalField1 !== undefined && headerTextData.additionalField1.visibility == true) {
                                            return (
                                                <div class="col-md-4">
                                                    <label htmlFor="additionalField1" className="form-label">Additional Field #1</label>
                                                    <input disabled={IsEditable == true ? false : true} type="text" value={additionalField1} onChange={e => onInputChange(e)} className="form-control" id="additionalField1" placeholder="Enter Additional Field #1" />
                                                </div>
                                            )
                                        }
                                    })()}

                                    {(() => {
                                        if (headerTextData.additionalField2 !== undefined && headerTextData.additionalField2.visibility == true) {
                                            return (
                                                <div class="col-md-4">
                                                    <label htmlFor="additionalField2" className="form-label">Additional Field #2</label>
                                                    <input disabled={IsEditable == true ? false : true} type="text" value={additionalField2} onChange={e => onInputChange(e)} className="form-control" id="additionalField2" placeholder="Enter Additional Field #2" />
                                                </div>
                                            )
                                        }
                                    })()}

                                    {(() => {
                                        if (headerTextData.additionalField3 !== undefined && headerTextData.additionalField3.visibility == true) {
                                            return (
                                                <div class="col-md-4">
                                                    <label htmlFor="additionalField3" className="form-label">Additional Field #3</label>
                                                    <input disabled={IsEditable == true ? false : true} type="text" value={additionalField3} onChange={e => onInputChange(e)} className="form-control" id="additionalField3" placeholder="Enter Additional Field #3" />
                                                </div>
                                            )
                                        }
                                    })()}
                                </div>
                            </div>

                            <div className="mb-3">
                                <div class="form-group row">

                                    {(() => {
                                        if (headerTextData.additionalField4 !== undefined && headerTextData.additionalField4.visibility == true) {
                                            return (
                                                <div class="col-md-4">
                                                    <label htmlFor="additionalField4" className="form-label">Additional Field #4</label>
                                                    <input disabled={IsEditable == true ? false : true} type="text" value={additionalField4} onChange={e => onInputChange(e)} className="form-control" id="additionalField4" placeholder="Enter Additional Field #4" />
                                                </div>
                                            )
                                        }
                                    })()}

                                    {(() => {
                                        if (headerTextData.additionalField5 !== undefined && headerTextData.additionalField5.visibility == true) {
                                            return (
                                                <div class="col-md-4">
                                                    <label htmlFor="additionalField5" className="form-label">Additional Field #5</label>
                                                    <input disabled={IsEditable == true ? false : true} type="text" value={additionalField5} onChange={e => onInputChange(e)} className="form-control" id="additionalField5" placeholder="Enter Additional Field #5" />
                                                </div>
                                            )
                                        }
                                    })()}


                                    {(() => {
                                        if (headerTextData.additionalField6 !== undefined && headerTextData.additionalField6.visibility == true) {
                                            return (
                                                <div class="col-md-4">
                                                    <label htmlFor="additionalField6" className="form-label">Additional Field #6</label>
                                                    <input disabled={IsEditable == true ? false : true} type="text" value={additionalField6} onChange={e => onInputChange(e)} className="form-control" id="additionalField6" placeholder="Enter Additional Field #6" />
                                                </div>
                                            )
                                        }
                                    })()}


                                </div>
                            </div>

                            <div className="mb-3">
                                <div class="form-group row">

                                    {(() => {
                                        if (headerTextData.additionalField7 !== undefined && headerTextData.additionalField7.visibility == true) {
                                            return (
                                                <div class="col-md-4">
                                                    <label htmlFor="additionalField7" className="form-label">Additional Field #7</label>
                                                    <input disabled={IsEditable == true ? false : true} type="text" value={additionalField7} onChange={e => onInputChange(e)} className="form-control" id="additionalField7" placeholder="Enter Additional Field #7" />
                                                </div>
                                            )
                                        }
                                    })()}

                                    {(() => {
                                        if (headerTextData.additionalField8 !== undefined && headerTextData.additionalField8.visibility == true) {
                                            return (
                                                <div class="col-md-4">
                                                    <label htmlFor="additionalField8" className="form-label">Additional Field #8</label>
                                                    <input disabled={IsEditable == true ? false : true} type="text" value={additionalField8} onChange={e => onInputChange(e)} className="form-control" id="additionalField8" placeholder="Enter Additional Field #8" />
                                                </div>
                                            )
                                        }
                                    })()}

                                    {(() => {
                                        if (headerTextData.additionalField9 !== undefined && headerTextData.additionalField9.visibility == true) {
                                            return (
                                                <div class="col-md-4">
                                                    <label htmlFor="additionalField9" className="form-label">Additional Field #9</label>
                                                    <input disabled={IsEditable == true ? false : true} type="text" value={additionalField9} onChange={e => onInputChange(e)} className="form-control" id="additionalField9" placeholder="Enter Additional Field #9" />
                                                </div>
                                            )
                                        }
                                    })()}

                                </div>
                            </div>
                            <div className="mb-3">
                                <div class="form-group row">
                                    {(() => {
                                        if (headerTextData.additionalField10 !== undefined && headerTextData.additionalField10.visibility == true) {
                                            return (
                                                <div class="col-md-4">
                                                    <label htmlFor="additionalField10" className="form-label">Additional Field #10</label>
                                                    <input disabled={IsEditable == true ? false : true} type="text" value={additionalField10} onChange={e => onInputChange(e)} className="form-control" id="additionalField10" placeholder="Enter Additional Field #10" />
                                                </div>
                                            )
                                        }
                                    })()}





                                </div>
                            </div>
                            <div className="mb-3" style={{ textAlign: "right" }}>
                                <div class="row">
                                    <div class="col-md-12">
                                        {<button type="button" onClick={() => { onSubmit() }} disabled={IsEditable == true ? false : true} className="btn btn-primary btn-md">{props.data != null && props.data.pbcRequestId > 0 ? "Update" : "Save"} </button>}&nbsp;
                                        {props.data != null && props.data.pbcRequestId > 0 ? <button type="button" disabled={IsEditable} onClick={() => { onEdit() }} className="btn btn-primary btn-md">Edit</button> : ""}&nbsp;
                                        {props.data != null && props.data.pbcRequestId > 0 ? <button onClick={(e) => { openDocumentsModel(e, 1) }} className="btn btn-primary btn-md">Documents {`(${props.data.documentsCount})`}</button> : ""}&nbsp;
                                        {props.data != null && props.data.pbcRequestId > 0 ? <button onClick={(e) => { openDocumentsModel(e, 2) }} className="btn btn-primary btn-md">Comments {`(${props.data.commentsCount})`}</button> : ""}&nbsp;
                                        {props.data != null && props.data.pbcRequestId > 0 ? <button onClick={(e) => { openDocumentsModel(e, 3) }} className="btn btn-primary btn-md">Audit Trail</button> : ""}&nbsp;
                                        {props.data != null && props.data.pbcRequestId > 0 ? <button onClick={(e) => { SaveZip(e) }} className="btn btn-primary btn-md">Download Documents</button> : ""}
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

        </>
    );

}