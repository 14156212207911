import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios'
toast.configure()
const Url = process.env.REACT_APP_API_URL;
export const Comments_Service = {
    addComments
}

async function addComments(objdata) {
    const result = await axios.post(`${Url}pbcRequest/addComment/`, objdata);
    return result;
}