import PropTypes from 'prop-types'
import { Link, useHistory } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { user_Service } from "../Services/user_Service";
import { ViewToaster } from "./ViewToaster";
import Cookies from 'universal-cookie';
import Swal from 'sweetalert2';
import Modal from "react-bootstrap/Modal";



export const ResetPassword = (props) => {
    let history = useHistory();
    const cookies = new Cookies();
    var CryptoJS = require("crypto-js");
    let [Credentials, setCredentials] = useState({ newPassword: "", confirmPassword: "" });

    const onInputChange = e => {
        setCredentials({ ...Credentials, [e.target.id]: e.target.value });
    };

    const [isOpen, setIsOpen] = React.useState(false);
    const [title, setTitle] = useState();
    let [values, SetValues] = useState({});
    const hideModal = () => {
        setIsOpen(false);
        setTitle("");
    };

    //Validating Inputes
    let newPasswordError = "";
    let confirmPasswordError = "";
    const [validationstate, setValidationState] = useState(newPasswordError, confirmPasswordError);
    const validate = () => {

        if (!Credentials.newPassword.length > 0) {
            newPasswordError = "Please Enter New Password";
        }
        else {
            if (Credentials.newPassword.length < 6) {
                newPasswordError = "New Password length must be atleast 6 characters";
            }
        }


        if (!Credentials.confirmPassword.length > 0) {
            confirmPasswordError = "Please Enter Confirm Password";
        }
        else {
            if (Credentials.confirmPassword.length < 6) {
                confirmPasswordError = "Confirm Password length must be atleast 6 characters";
            }
        }

        if (newPasswordError || confirmPasswordError) {
            setValidationState({ newPasswordError, confirmPasswordError });
            return false;
        }
        return true;
    }


    const [loading, setloading] = useState("Submit");
    const [buttonDisable, setbuttonDisable] = useState(false);

    //Form Submiting
    const onSubmit = async (e) => {
        e.preventDefault();
        const isValid = validate();
        if (isValid) {
            setValidationState({ newPasswordError: "", confirmPasswordError: "" });
            setloading("Loading");
            setbuttonDisable(true);

            user_Service.resetPassword(props.objuserId, Credentials.newPassword, Credentials.confirmPassword).then(result => {
                if (result.data.success == true) {
                    cookies.remove("_data");
                    cookies.remove("_IsSystem");
                    history.push("/");
                    window.location.reload(true);
                }

            }).catch(err => {
                ViewToaster.getToaster(err.response.data.responseText, err.response.data.success);
                setloading("Submit");
                setbuttonDisable(false);
            });
        }
    };

    return (
        <div className="container pt-50">

            <Modal
                show={true}
                // onHide={closeAboutModel}
                size="md" backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>Reset Password</Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <div class="content-body">
                        <div class="row justify-content-md-center">
                            <div class="col-md-12">
                                <div class="card">
                                    <form onSubmit={onSubmit}>
                                        <div class="card-body">
                                            <div class="form-body">
                                                <div class="form-group">
                                                    <label htmlFor="name" className="form-label">Enter New Password</label><span className="text-danger reqired-fields">*</span>
                                                    <input type="text" onChange={e => onInputChange(e)} className="form-control" id="newPassword" placeholder="Enter New Password" />
                                                    <small class="text-danger">{validationstate.newPasswordError}</small>
                                                </div>
                                                <div class="form-group">
                                                    <label htmlFor="name" className="form-label">Enter Confirm Password</label><span className="text-danger reqired-fields">*</span>
                                                    <input type="text" onChange={e => onInputChange(e)} className="form-control" id="confirmPassword" placeholder="Enter Confirm Password" />
                                                    <small class="text-danger">{validationstate.confirmPasswordError}</small>
                                                </div>
                                                <div class="form-actions text-right">
                                                    <button type="submit" class="btn btn-md btn-primary" disabled={buttonDisable} id="btnSubmit">
                                                        <i class="la la-check-square-o"></i> {loading}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                </Modal.Body>
            </Modal>
        </div>
    )
}
