
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios'
toast.configure()

const Url = process.env.REACT_APP_API_URL;
export const Login_Service = {
    userValidation,
    
}


async function userValidation(emailId, password) {
    const result = await axios.post(`${Url}login/userValidation?emailId=${emailId}&password=${password}`);
    return result;
}



