import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2'
toast.configure()

export const ViewToaster = {
    getToaster
}

function getToaster(message, success) {


    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    })

    if (success === true) {
        // return toast.success(message, { autoClose: 2000 })
        return Toast.fire({ icon: 'success', title: message })
    }
    else if (success === false) {
        return Toast.fire({ icon: 'error', title: message })
        //return toast.error(message, { autoClose: 2000 })
    }
}