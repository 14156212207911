import { queryAllByDisplayValue } from '@testing-library/dom';
import axios from 'axios'
const Url = process.env.REACT_APP_API_URL;
export const user_Service = {
    getTimeZoneList,
    getUserById,
    getUserList,
    deleteUserById,
    resetPassword,
    getUserListByUserType,
    addUpdateUser
}

async function getTimeZoneList() {
    const result = await axios.get(`${Url}user/getTimeZoneList`)
    return result;
}


async function getUserById(id) {
    const result = await axios.get(`${Url}user/getuserById?id=${id}`);
    return result;
}

async function getUserList() {
    const result = await axios.get(`${Url}user/getUserList`);
    return result;
}


async function getUserListByUserType(usertypeId) {
    const result = await axios.get(`${Url}user/getUserListByUserType?usertypeId=${usertypeId}`);
    return result;
}


async function deleteUserById(id) {
    const result = await axios.post(`${Url}user/deleteUser?id=${id}`);
    return result;
}

async function resetPassword(userId, newPassword, confirmPassword) {
    const result = await axios.post(`${Url}user/resetPassword?userId=${userId}&newpassword=${newPassword}&confirmPassword=${confirmPassword}`);
    return result;
}


async function addUpdateUser(userData){
    const objdata = {
        UserId: parseInt(userData.userId, 10),
        RoleId: parseInt(userData.roleId, 10),
        Name: userData.name,
        EmailId: userData.emailId,
        TimeZoneId: parseInt(userData.timeZoneId, 10),
        UserTypeId: parseInt(userData.userTypeId, 10),
        MobileNo: userData.mobileNo
    };
    const result = await axios.post(`${Url}user/addUpdateUser/`, objdata);
    return result;
}