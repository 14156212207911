import React from 'react';
// import '../dist/bootstrap/js/bootstrap.bundle.js';
//import '../dist/js/adminlte.min.js';


export const Footer = () => { 
    return (
        <p></p>
    )
}
