import React, { useState, useEffect } from "react";
import { UploaderComponent } from '@syncfusion/ej2-react-inputs';
import {
    GridComponent,
    ColumnDirective,
    ColumnsDirective,
    Page,
    Edit,
    Toolbar,
    Inject, ColumnChooser, CommandColumn, Filter, PdfExport, ExcelExport, Grid, Sort, Reorder, Resize, Group, VirtualScroll, InfiniteScroll
} from "@syncfusion/ej2-react-grids";
import FileSaver from 'file-saver';
import { ViewToaster } from "./ViewToaster";
import { Documents_Service } from "../Services/Documents_Service";
import { PBCRequest_Service } from "../Services/PBCRequest_Service";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { Comments } from "../Component/Comments";
// import validator from 'validator'
import Swal from 'sweetalert2'
import Modal from "react-bootstrap/Modal";
import axios from 'axios'
import Parser from 'html-react-parser';
import { Audit_Service } from "../Services/Audit_Service"
import { Comments_Service } from "../Services/Comments_Service"
import { getRoles } from "@testing-library/dom";
import dateFormat from 'dateformat';


export const Documents = (props) => {

    // const MySwal = withReactContent(Swal)
    const Url = process.env.REACT_APP_API_URL;
    const DocumentsUrl = process.env.REACT_APP_URL_For_Documents;

    let grid = Grid | null;
    let Commentsgrid = Grid | null;

    const commentsCommand = [{ type: 'Comment', buttonOption: { iconCss: 'e-icons e-M_PV_Comment', cssClass: 'e-flat' } }];
    const toolbarOptions = ["Search"];

    let [objCommands, setCommands] = useState([]);

    function setCommandsArray() {
        let commands = [];
        if (props.roleId != 0 && props.roleId == 6) {
            commands = [{ type: 'Approve', buttonOption: { content: 'Approve', iconCss: 'e-icons e-delete', cssClass: 'e-flat' } },
            { type: 'Delete', buttonOption: { iconCss: 'e-icons e-delete', cssClass: 'e-flat' } },
            { type: 'Download', buttonOption: { iconCss: 'e-icons e-M_PV_Download', cssClass: 'e-flat' } },
            { type: 'Reject', buttonOption: { content: 'Reject', cssClass: 'e-flat' } }];
        }
        else {
            commands = [{ type: 'Delete', buttonOption: { iconCss: 'e-icons e-delete', cssClass: 'e-flat' } },
            { type: 'Download', buttonOption: { iconCss: 'e-icons e-M_PV_Download', cssClass: 'e-flat' } }];
        }
        setCommands(commands);
    }

    ///File Upload 
    let uploadObj;
    let dropContainerEle = null;
    const dropContainerRef = element => {
        dropContainerEle = element;
    };
    function onRemoveFile(args) {
        args.postRawFile = false;
    }
    //File Upload Url
    const asyncSettings = {
        saveUrl: `${Url}pbcRequest/DcoumentsUpload?id=${props.data.pbcRequestId}&userId=${props.userId}`,
        removeUrl: 'https://ej2.syncfusion.com/services/api/uploadbox/Remove'
    };
    function filesUploaded() {
        getAllData();
        getAllDatawithNoAttachments();
    }

    const [objroleId, setobjRoleId] = useState();
    function getrolenameByUserId() {
        setTimeout(() => {
            PBCRequest_Service.getroleByUserId(props.userId).then((result) => {
                setobjRoleId(result.data.data.roleId);
                if (result.data.data == 6) {
                    setradioSelected(2);
                    setisClarification(true);
                }
            })
        }, 100);
    }

    let objtaskstatus = false;
    let isDisableForReviewpending = false;
    let [isDisableForProcess, SetIsDisableForProcess] = useState(false);
    const [lastTaskdata, SetLastTaskData] = useState();
    const [statusColor, SetstatusColor] = useState("");
    const [TaskComplete, SetTaskComplete] = useState(false);
    function getLastTaskData() {
        PBCRequest_Service.getLastTaskData(props.data.pbcRequestId).then((result) => {
            setTimeout(() => {
                SetLastTaskData(result.data);
                var statusType = "badge-warning";

                if (result.data.success == true) {

                    if (props.roleId === 7) {
                        if (result.data.data.statusName === "Review Pending") {
                            SetIsDisableForProcess(true);
                            isDisableForReviewpending = true;

                        }
                    }

                    if (result.data.data.statusName == "Completed") {
                        statusType = "badge-success"
                        SetTaskComplete(true)
                        objtaskstatus = true;
                    }
                    SetstatusColor(statusType);
                    if (result.data.data.docRequestTypeId == 3) {
                        setradioSelected(2);
                        setisClarification(true);
                    }
                    if (result.data.data.docRequestTypeId == 2) {

                        if (dataIndb == false) {
                            setradioSelected(1);
                            setisClarification(false);
                        }
                    }
                    if (result.data.data.docRequestTypeId == 1) {

                        if (dataIndb == false) {
                            setradioSelected(0);
                            setisClarification(false);

                            if (result.data.ids.length > 0) {
                                setCheckedValue(result.data.ids);
                            }
                        }
                    }
                }
            }, 100);
        })
    }

    function getDraftCommentByPBC() {
        setTimeout(() => {
            PBCRequest_Service.getDraftComment(props.data.pbcRequestId, props.userId).then((result) => {
                if (result.data.success == true) {

                    if (result.data.data.docRequestType == 3) {
                        setclrfiction({ text: result.data.data.pbcComment })
                        setradioSelected(2);
                        setisClarification(true);
                    }
                    if (result.data.data.docRequestType == 2) {
                        setnoFileReason({ text: result.data.data.pbcComment })
                        setradioSelected(1);
                        setisClarification(false);
                    }

                    if (result.data.data.docRequestType == 1) {
                        setDocComment({ text: result.data.data.pbcComment })
                        setradioSelected(0);
                        setisClarification(false);

                        if (result.data.ids.length > 0) {
                            setCheckedValue(result.data.ids);
                        }
                    }
                }
            })
        }, 100);
    }

    //get All Files List By PCB ReqyuestId
    const [dataIndb, setdataIndb] = useState(false);
    const [DocumentList, setDocumentList] = useState();
    useEffect(() => {
        setTimeout(() => {

            if (props.KeyTab != undefined) {
                setKey(props.KeyTab);
            }
            getDraftCommentByPBC();
            getLastTaskData();
            getrolenameByUserId();
            getAllData();
            getCommentsList(0);
            getallcomments(0)
            // getAllAuditData();
            getAllDatawithNoAttachments();
            setCommandsArray();


            if (Commentsgrid == null) {
            }
            else {
                if (Commentsgrid !== 0) {
                    if (Commentsgrid != null) {
                        setTimeout(() => {
                            if (Commentsgrid != null) {
                                Commentsgrid.refresh();
                            }
                        }, 1000);
                    }
                }
            }
            getAuditTrialData();

        }, 500);

    }, []);


    //No Attachments true data
    const getAllData = async () => {
        Documents_Service.getDocumentsList(props.data.pbcRequestId).then((result) => {
            setTimeout(() => {
                setDocumentList(result.data);
            }, 550);
        })
    }

    //Audit Trial List By PBCRequestId
    const [auditTrialData, setauditTrialData] = useState();
    const getAuditTrialData = async () => {
        Documents_Service.getAuditTrialList(props.data.pbcRequestId).then((result) => {
            setTimeout(() => {
                setauditTrialData(result.data);
            }, 1000);
        })
    }

    //no Attachments true data
    const getAllDatawithNoAttachments = async () => {
        Documents_Service.getDocumentsListWithNoAttchments(props.data.pbcRequestId).then((result) => {
            setTimeout(() => {
                result.data.length > 0 ? setdataIndb(true) : setdataIndb(false)
            }, 600);
        })
    }

    //Get Images For Files
    function getImage(props) {
        var cssClass = props.isUrlLink == true ? "e-icons e-Hyperlink" : props.pbcDocName.includes('.pdf') ? "e-icons e-M_PDFExport" : props.pbcDocName.includes('.xlsx') || props.pbcDocName.includes('.xls') ? 'e-icons e-Excel_Export' : props.pbcDocName.includes('.jpeg') || props.pbcDocName.includes('.png') || props.pbcDocName.includes('.jpg') ? 'e-icons e-M_Image' : '';
        if (props.isUrlLink == true) {
            const logo = require('../dist/icons/link-128px.png');
            return (<div style={{ fontSize: "25px" }} >
                <img style={{ height: "35px" }} src={logo.default} />
            </div>);
        }
        else if (props.pbcDocName.includes('.jpeg') || props.pbcDocName.includes('.png') || props.pbcDocName.includes('.jpg')) {
            const logo = require('../dist/icons/image-file-96px.png');
            return (<div style={{ fontSize: "25px" }} >
                <img style={{ height: "35px" }} src={logo.default} />
            </div>);
        }
        else if (props.pbcDocName.includes('.xlsx') || props.pbcDocName.includes('.xls')) {

            const logo = require('../dist/icons/xls-96px.png');
            return (<div style={{ fontSize: "25px" }} >
                <img style={{ height: "35px" }} src={logo.default} />
            </div>);
        }
        else if (props.pbcDocName.includes('.pdf')) {
            const logo = require('../dist/icons/pdf-80px.png');
            return (<div style={{ fontSize: "25px" }} >
                <img style={{ height: "35px" }} src={logo.default} />
            </div>);
        }
        else if (props.pbcDocName.includes('.doc') || props.pbcDocName.includes('.docx')) {
            const logo = require('../dist/icons/doc-96px.png');
            return (<div style={{ fontSize: "25px" }} >
                <img style={{ height: "35px" }} src={logo.default} />
            </div>);
        }
        else {
            cssClass = "";
            return (<div style={{ fontSize: "25px" }} > <span class={cssClass}></span>
            </div>);
        }
    }

    //Get files Links
    function getDocumentsName(props) {
        var objDocumentName = props.isUrlLink == true ? <a target="_blank" rel="noopener noreferrer" href={props.pbcDocName.includes("http://") || props.pbcDocName.includes("https://") ? props.pbcDocName : `//${props.pbcDocName}`}>{props.pbcDocName}</a> : <span>{props.pbcDocName}</span>;
        return (<div>{objDocumentName}</div>);
    }

    //set Status Style
    function getStatus(args) {
        const objBackgroundColor = args.docStatus == 0 ? "orange" : args.docStatus == 1 ? "green" : args.docStatus == 2 ? "red" : "gray";
        let objStatus = args.docStatus == 0 ? "Pending" : args.docStatus == 1 ? "Approved" : args.docStatus == 2 ? "Rejected" : "";
        return (
            <div>
                <div style={{ fontSize: "10px", background: objBackgroundColor, borderRadius: "15px", color: "white", marginRight: "10px" }}>
                    <span>{objStatus}</span>
                </div>
            </div>
        );
    }
    const [documentid, setdocumentid] = useState(0);
    const [documentName, setdocumentName] = useState("");
    const [tabdisable, settabdisable] = useState(true);

    const commandClick = (args) => {
        if (args.commandColumn.type === "Comment") {
            setdocumentid(args.rowData.caDocId);
            setdocumentName(args.rowData.pbcDocName)
            setKey("commenttab");
            settabdisable(false)
            getCommentsList(args.rowData.caDocId);
        }

        if (args.commandColumn.type === "Download") {
            saveFile(args)
        }
        if (args.commandColumn.type === "Delete") {

            if (props.docpermissions.includes("Delete")) {
                Swal.fire({
                    title: 'Are you sure?',
                    text: "You won't be able to revert this!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#d33',
                    cancelButtonColor: '#043a05',
                    confirmButtonText: 'Yes, delete it!'
                }).then((result) => {
                    if (result.isConfirmed) {

                        Documents_Service.deleteDocuments(args.rowData.pbcRequestId, args.rowData.pbcDocumentsId, args.rowData.caDocId, args.rowData.projectId, args.rowData.controlId, props.userId)
                            .then(res => {
                                getAllData();
                                getAllDatawithNoAttachments();
                                getAuditTrialData();
                                Swal.fire(
                                    'Deleted!',
                                    'Your file has been deleted.',
                                    'success'
                                );
                            }).catch(err => ViewToaster.getToaster(err.response.data.responseText, err.response.data.success));
                    }
                })
            }
            else {
                ViewToaster.getToaster("You are not authorized to perform action.", false)
            }
        }
    }

    //File Download
    const saveFile = (args) => {
        FileSaver.saveAs(
            `${DocumentsUrl}Audit/${args.projectId.toString()}/${args.controlId.toString()}/${args.pbcRequestId.toString()}/${args.pbcDocumentsId.toString()}/${args.caDocId.toString()}/${args.pbcDocName}`,
            args.pbcDocName
        );
    }

    //Add Documents Link
    let [documentLink, setDocumentLink] = useState({ urlLink: "" });
    let [clrfiction, setclrfiction] = useState({ text: "" });
    let [noFileReason, setnoFileReason] = useState({ text: "" });
    let [docComment, setDocComment] = useState({ text: "" });

    const onInputChange = e => {
        setDocumentLink({ ...documentLink, [e.target.id]: e.target.value });
    };

    const onClr_InputChange = e => {
        setclrfiction({ ...clrfiction, [e.target.id]: e.target.value });
    };

    const onNoFile_InputChange = e => {
        setnoFileReason({ ...noFileReason, [e.target.id]: e.target.value });
    };

    const docComment_InputChange = e => {
        setDocComment({ ...docComment, [e.target.id]: e.target.value });
    };

    const [disableButton, setDisableButton] = useState(false);
    const onSubmit = async () => {
        setDisableButton(true);
        const isValid = validate();
        if (isValid) {
            setValidationState({ linkError: "" })
            Documents_Service.addDocumentLink(props.data.pbcRequestId, documentLink.urlLink).then(result => {
                ViewToaster.getToaster(result.data.responseText, result.data.success);
                getAllData();
                getAllDatawithNoAttachments();

            }).catch(err => ViewToaster.getToaster(err.response.data.responseText, err.response.data.success));
            setDocumentLink({ urlLink: "" })
            setDisableButton(false);
        }
        setDisableButton(false);
    };

    const onClarificationSubmit = async (type, requestType) => {
        setDisableButton(true);

        let clrValidate = true;
        let nofileValidate = true;
        let docCommentValidate = true;

        var objtext = requestType == 3 ? clrfiction.text : requestType == 2 ? noFileReason.text : requestType == 1 ? docComment.text : ""
        //on Submit Add into Task
        if (type == 1) {
            var objtext = requestType == 3 ? clrfiction.text : requestType == 2 ? noFileReason.text : requestType == 1 ? docComment.text : ""
            let selectedvalue = checkedValue.join();
            if (props.roleId === 6) {
                if (requestType == 3) {
                    clrValidate = clarficationvalidate();
                }
                if (clrValidate) {
                    setClrValidationState("");
                    setValidationState("");
                    setDocCommentValidation("");
                    SubmitingRequest(props.userId, props.data.pbcRequestId, requestType, selectedvalue, objtext)
                }
            }
            if (props.roleId === 7) {

                if (requestType == 3) {
                    clrValidate = clarficationvalidate();
                }
                if (requestType == 2) {
                    nofileValidate = nofilevalidate();
                }

                if (clrValidate && nofileValidate) {
                    setClrValidationState("");
                    setValidationState("");
                    setDocCommentValidation("");
                    SubmitingRequest(props.userId, props.data.pbcRequestId, requestType, selectedvalue, objtext)
                }
            }

            setclrfiction({ text: "" })
            setDisableButton(false);
        }
        //Add to draft
        if (type == 0) {
            // var objtext = requestType == 3 ? clrfiction.text : requestType == 2 ? noFileReason.text : requestType == 1 ?"" : "";

            if (requestType == 3) {
                clrValidate = clarficationvalidate();
            }
            if (requestType == 2) {
                nofileValidate = nofilevalidate();
            }
            if (requestType == 1) {
                docCommentValidate = DocCommentvalidate();
            }
            if (clrValidate && nofileValidate && docCommentValidate) {
                setClrValidationState("");
                setValidationState("");
                setDocCommentValidation("");

                const objdata = {
                    PBCRequestId: props.data.pbcRequestId,
                    PBCComment: objtext,
                    CADocId: 0,
                    DocRequestType: requestType,
                    IsDraft: true,
                    UserId: props.userId,
                    DocIds: checkedValue.join()
                };
                Comments_Service.addComments(objdata).then(result => {
                    ViewToaster.getToaster(result.data.responseText, result.data.success);
                    getAllData();
                    setclrfiction({ text: "" })
                    setnoFileReason({ text: "" })
                    setDisableButton(false);
                }).catch(err => ViewToaster.getToaster(err.response.data.responseText, err.response.data.success));


            }
        }
    }

    function SubmitingRequest(userId, pbcRequestId, requestType, selectedvalue, objtext) {

        PBCRequest_Service.getSubmissionAdvanceStatus(userId, pbcRequestId, requestType).then(result => {
            if (result.data.success == true) {
                Swal.fire({
                    title: `Are you sure you want to change the status to ${result.data.data}?`,
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#d33',
                    cancelButtonColor: '#043a05',
                    confirmButtonText: 'Yes'
                }).then((result) => {
                    if (result.isConfirmed) {
                        Documents_Service.addSubmission(userId, pbcRequestId, objtext, requestType, selectedvalue).then(result => {
                            ViewToaster.getToaster(result.data.responseText, result.data.success);
                            getAllData();
                            getLastTaskData();
                            getAuditTrialData();
                            getCommentsList(0)
                            setDocComment({ text: "" });
                            setTimeout(() => {
                                window.location.reload(false);
                            }, 2000);

                        }).catch(err => ViewToaster.getToaster(err.response.data.responseText, err.response.data.success));
                    }
                    if (result.isDismissed) {
                        getAllData();
                        getLastTaskData();
                        getAuditTrialData();
                        getCommentsList(0)
                    }
                })
            }
            else
                ViewToaster.getToaster(result.data.responseText, result.data.success);
        }).catch(err => ViewToaster.getToaster(err.response.data.responseText, err.response.data.success));

    }


    //Validating Inputes
    let linkError = "";
    const [validationstate, setValidationState] = useState(linkError);
    const validate = () => {
        if (documentLink.urlLink == "") {
            linkError = "Please Enter Url";
        }
        if (linkError) {
            setValidationState({ linkError });
            return false;
        }
        return true;
    }

    let clarficationerror = "";
    const [clrvalidationstate, setClrValidationState] = useState(clarficationerror);

    const clarficationvalidate = () => {
        if (clrfiction.text == "") {
            clarficationerror = "Please Enter Your Clarification";
        }
        if (clarficationerror) {
            setClrValidationState({ clarficationerror });
            return false;
        }
        return true;
    }

    let nofilenerror = "";
    const [nofilevalidationstate, setNoFileValidationState] = useState(nofilenerror);

    const nofilevalidate = () => {
        if (noFileReason.text == "") {
            nofilenerror = "Please Enter Your Reason";
        }
        if (nofilenerror) {
            setNoFileValidationState({ nofilenerror });
            return false;
        }
        return true;
    }

    let DocCommentError = "";
    const [Documentcommentvalidation, setDocCommentValidation] = useState(DocCommentError);
    const DocCommentvalidate = () => {

        if (docComment.text === "") {
            DocCommentError = "Please Enter Comment";
        }
        if (DocCommentError) {
            setDocCommentValidation({ DocCommentError });
            return false;
        }
        return true;
    }


    //Update PbcRequest No Attachments
    const [radioSelected, setradioSelected] = useState(0);
    const [isClarification, setisClarification] = useState(false);
    const onRadioValueChange = (event) => {
        let selectedValue = JSON.parse(event.target.value)
        setradioSelected(selectedValue);

        if (selectedValue === 1 || selectedValue === 0) {
            setisClarification(false);
        }
        if (selectedValue == 2) {
            setisClarification(true);
        }
    }

    const [isOpen, setIsOpen] = React.useState(false);
    const [title, setTitle] = useState();

    const hideModal = () => {
        setIsOpen(false);
        setTitle("");
        getAllData();
        getAllDatawithNoAttachments();
        setIdiscriptionOpen(false);

    };

    function getDescription() {
        return (
            <>
                <div class="col-md-3">
                    <p><b>PBC Req Id:</b> {props.data.pbcRequestId}</p>
                </div>
                <div class="col-md-9">
                    <p><b>Description: </b> {props.data.pbcDesc.substring(0, 80)}
                        {props.data.pbcDesc.length > 80 ? <a onClick={() => opendescription()} href="#"> <span style={{ fontSize: "12px", fontWeight: "550" }}>View More</span></a> : ""}</p>
                </div>
            </>
        )

    }
    const [discriptionOpen, setIdiscriptionOpen] = React.useState(false);
    const opendescription = () => {
        setIdiscriptionOpen(true)
    }

    ///tabs Selection
    const [key, setKey] = useState('documenthistorytab');
    const [DocumentKey, setDocumentKey] = useState('documenthistorytab');

    //comment Section
    const [disableCommentButton, setDisableXCommentButton] = useState(false);

    let [PBCComments, setPBCComments] = useState({ comment: "" });
    const oncommentsInputChange = e => {
        setPBCComments({ ...PBCComments, [e.target.id]: e.target.value });
    };

    //Validating Inputes
    let CommentError = "";
    const [commentvalidationstate, setCommentValidationState] = useState(CommentError);
    const commentvalidate = () => {

        if (PBCComments.comment === "") {
            CommentError = "Please Enter Comment";
        }
        if (CommentError) {
            setCommentValidationState({ CommentError });
            return false;
        }
        return true;
    }

    //Insert Comment Of PBC Request Documents
    const onCommentSubmit = async () => {
        setDisableXCommentButton(true);
        const isValid = commentvalidate();
        if (isValid) {
            setCommentValidationState({ CommentError: "" });
            const objdata = {
                PBCRequestId: props.data.pbcRequestId,
                PBCComment: PBCComments.comment,
                CADocId: documentid
            };
            Comments_Service.addComments(objdata).then(result => {
                ViewToaster.getToaster(result.data.responseText, result.data.success);
                getCommentsList(documentid);
            }).catch(err => ViewToaster.getToaster(err.response.data.responseText, err.response.data.success));
            setPBCComments({ comment: "" });
            setDisableXCommentButton(false);
        }
        setDisableXCommentButton(false);
    };

    const [commentsList, setCommentsList] = useState([]);
    const [AllcommentsList, setAllCommentsList] = useState([]);
    ///Get Comments List
    const getCommentsList = async (objdocumentid) => {
        const result = await axios.get(`${Url}pbcRequest/getPBCRequestCommentsList?id=${props.data.pbcRequestId}&caDocId=${objdocumentid}`);

        if (objdocumentid == 0) {
            setdocumentName("")
        }
        setTimeout(() => {

            setCommentsList(result.data);
        }, 100);
    }

    const getallcomments = async (objdocumentid) => {
        const result = await axios.get(`${Url}pbcRequest/getPBCRequestCommentsList?id=${props.data.pbcRequestId}&caDocId=${objdocumentid}`);
        setdocumentName("")
        setTimeout(() => {
            setAllCommentsList(result.data);
        }, 100);
    }

    let listItems = [];
    if (commentsList.length > 0) {
        listItems = commentsList.map((e, key) =>
            <li style={{ listStyle: "none" }} key={key}>
                <p style={{ padding: "10px 5px", background: "#e4e4e4", borderRadius: "5px", marginBottom: "2px", width: "90%" }}>   {Parser(e.pbcComment)}</p>
                <p style={{ fontStyle: "italic", textAlign: "right", width: "90%", marginBottom: "15px", fontSize: "12px", color: "#043A05" }}>{e.lastUpdateByEmailId}, {e.lastUpdateDateTime}</p>
            </li>
        );
    }

    const [checkedValue, setCheckedValue] = useState([]);
    const onAddingItem = (item) => {
        const isChecked = item.target.checked;
        const value = item.target.value;
        if (isChecked) {
            if (checkedValue.length == 0) {
                setCheckedValue([Number(value)]);
            }
            else {
                setCheckedValue([...checkedValue, Number(value)]);
            }
        }
        else {
            const newAddedProducts = checkedValue.filter(x => x !== Number(value))
            setCheckedValue(newAddedProducts);
        }
    }

    let listDocumentsItems = [];
    let hasvalue = false;
    if (DocumentList != undefined && DocumentList.length > 0) {

        hasvalue = checkCheckBoxExist(-1)
        listDocumentsItems.push(
            <div className="form-check" >
                <input className="form-check-input" disabled={TaskComplete} type="checkbox" checked={hasvalue} onChange={onAddingItem} value={-1} id="flexCheckDefault" />
                <label class="form-check-label" style={{ fontSize: "12px" }} htmlFor="flexCheckDefault">General</label>
            </div>
        )

        for (var i = 0; i < DocumentList.length; i++) {
            hasvalue = checkCheckBoxExist(DocumentList[i].caDocId)
            listDocumentsItems.push(
                <div className="form-check" >
                    <input className="form-check-input" disabled={TaskComplete} type="checkbox" checked={hasvalue} onChange={onAddingItem} value={DocumentList[i].caDocId} id="flexCheckDefault" />
                    <label class="form-check-label" style={{ fontSize: "12px" }} htmlFor="flexCheckDefault">{DocumentList[i].pbcDocName}</label>
                </div>
            );
        }
    }

    function checkCheckBoxExist(docId) {
        let checked = false;
        let IsInDb = checkedValue.filter(x => x == docId)
        return IsInDb.length > 0 ? true : false
    }

    const filter = {
        type: 'Menu'
    };
    //grouping For Comments
    const groupOptions = { showGroupedColumn: false, columns: ['pbcDocName'] };

    function getDefaultDate(datestring) {
        var date;
        // date = datestring.substr(0, 10);
        date = dateFormat(datestring, "mm-dd-yyyy hh:mm")
        return date;
    }

    function onApproveNReject(caDocId, status) {

        Documents_Service.approveORRejectDocument(caDocId, status, props.data.pbcRequestId, props.userId).then(result => {
            ViewToaster.getToaster(result.data.responseText, result.data.success);
            getAllData();
            getAuditTrialData();
        }).catch(err => ViewToaster.getToaster(err.data.responseText, err.data.success));
    }

    function onDocumenDelete(args) {
        if (props.docpermissions.includes("Delete")) {
            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#d33',
                cancelButtonColor: '#043a05',
                confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
                if (result.isConfirmed) {

                    Documents_Service.deleteDocuments(args.pbcRequestId, args.pbcDocumentsId, args.caDocId, args.projectId, args.controlId, props.userId)
                        .then(res => {
                            Swal.fire(
                                'Deleted!',
                                'Your file has been deleted.',
                                'success'
                            );
                            getAllData();
                            getAllDatawithNoAttachments();
                        }).catch(err => ViewToaster.getToaster(err.response.data.responseText, err.response.data.success));
                }
            })
        }
        else {
            ViewToaster.getToaster("You are not authorized to perform action.", false)
        }
    }

    function onDownloadFile(args) {
        saveFile(args)
    }
    function onComments(args) {
        setdocumentid(args.caDocId);
        setdocumentName(args.pbcDocName)
        setKey("commenttab");
        settabdisable(false)
        getCommentsList(args.caDocId);
    }

    function onUplaodFail(args) {
        var header = args.response.headers;
        var statusMessage = header.slice(header.indexOf('status'), header.indexOf('date'));
        statusMessage = statusMessage.slice(statusMessage.indexOf(',') + 1);
        args.response.statusText = "File Already Exists";
        args.statusText = "File Already Exists";
    }

    function getcommandButton(args) {
        return (
            <>
                {
                    props == undefined || props.roleId == undefined ? "" : props.roleId != 0 && props.roleId == 6 ?
                        <div className="row">
                            <button href="javscript:" disabled={objtaskstatus} title="Approve" style={{ marginRight: "4px" }} className="e-lib e-btn e-control e-flat" onClick={() => onApproveNReject(args.caDocId, 1)}><span className="e-btn-icon e-icons e-M_CheckBox"></span></button>
                            <button href="javscript:" disabled={objtaskstatus} title="Delete" style={{ marginLeft: "4px", marginRight: "4px" }} className="e-lib e-btn e-control e-flat" onClick={() => onDocumenDelete(args)}><span className="e-btn-icon e-icons e-delete"></span></button>
                            <button href="javscript:" title="Download" style={{ marginLeft: "4px", marginRight: "4px" }} className="e-lib e-btn e-control e-flat" onClick={() => onDownloadFile(args)}><span className="e-btn-icon e-icons e-M_PV_Download"></span></button>
                            <button href="javscript:" title="Comments" style={{ marginLeft: "4px", marginRight: "4px" }} className="e-lib e-btn e-control e-flat" onClick={() => onComments(args)}><span className="e-btn-icon e-icons e-M_PV_Comment"></span></button>
                            <button href="javscript:" disabled={objtaskstatus} title="Reject" style={{ marginLeft: "4px" }} className="e-lib e-btn e-control e-flat" onClick={() => onApproveNReject(args.caDocId, 2)}><span className="e-btn-icon e-icons e-MT_Close"></span></button>
                        </div> :
                        <div>
                            <button href="javscript:" disabled={objtaskstatus == false && isDisableForReviewpending == false ? false : true} title="Delete" style={{ marginLeft: "4px", marginRight: "4px" }} className="e-lib e-btn e-control e-flat" onClick={() => onDocumenDelete(args)}><span className="e-btn-icon e-icons e-delete"></span></button>
                            <button href="javscript:" title="Download" style={{ marginLeft: "4px", marginRight: "4px" }} className="e-lib e-btn e-control e-flat" onClick={() => onDownloadFile(args)}><span className="e-btn-icon e-icons e-M_PV_Download"></span></button>
                            <button href="javscript:" title="Comments" style={{ marginLeft: "4px", marginRight: "4px" }} className="e-lib e-btn e-control e-flat" onClick={() => onComments(args)}><span className="e-btn-icon e-icons e-M_PV_Comment"></span></button>
                        </div>
                }
            </>
        );
    }

    return (
        <div className="container-fluid">
            <div className="mb-3">
                <div className="row">
                    <div class="col-md-5" >
                        <div className="row">
                            <div className="col-md-4">
                                <p ><b>PBC Req Id:</b> {props.data.pbcRequestId}</p>
                            </div>
                            <div class="col-md-8 text-right">
                                <div className="text-right"><b>Document Status:</b>
                                    <span class={`badge ${statusColor} badge-lg`}>
                                        {lastTaskdata != undefined ? lastTaskdata.data != undefined ? lastTaskdata.data.statusName != "" ?
                                            lastTaskdata.data.statusName : "" : "" : ""}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <p style={{ marginBottom: "20px", background: "#e4e4e4", padding: "15px", borderRadius: "10px" }}><b>Description: </b> {props.data.pbcDesc.substring(0, 145)}
                                    {props.data.pbcDesc.length > 145 ? <a onClick={() => opendescription()} href="#"> <span style={{ fontSize: "12px", fontWeight: "550" }}>View More</span></a> : ""}</p>
                            </div>
                            <div className="col-md-12">
                                <div className="text-center" onChange={e => { onRadioValueChange(e) }}>
                                    <div class='custom-control custom-radio' style={{ marginBottom: "8px" }}>

                                        <input type="radio" checked={radioSelected == 0 ? true : false} value={0} name="documentUpload" id="rdoAttachFiles" /><label for="rdoAttachFiles" style={{ paddingRight: "10px", paddingLeft: "2px" }}>Attach Files</label>
                                        {/* dataIndb === false ? */}
                                        <input type="radio" checked={radioSelected == 1 ? true : false} disabled={props.docpermissions.includes("Create") ? false : true} value={1} name="documentUpload" id="rdoNofile" /><label for="rdoNofile" style={{ paddingLeft: "2px", paddingRight: "10px" }}>No Files</label>
                                        {/* <input type="radio" checked={radioSelected == false} value={2} name="documentUpload" /><label style={{ paddingLeft: "2px" }}>Clarification</label> */}
                                        <input type="radio" checked={radioSelected == 2 ? true : false} disabled={props.docpermissions.includes("Create") ? false : true} value={2} name="documentUpload" id="rdoclarification" /><label for="rdoclarification" style={{ paddingLeft: "2px" }}>Clarification</label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {radioSelected == 1 ? <div>
                            <div className="row">
                                <div className="col-md-12">
                                    {
                                        lastTaskdata != undefined ?
                                            lastTaskdata.data == null || lastTaskdata.data == undefined || lastTaskdata == null ? "" :
                                                lastTaskdata.data.docRequestTypeId == 2 && lastTaskdata.data.comments != "" && lastTaskdata.data.comments != undefined ? <div className="card">
                                                    <div className="card-body">
                                                        <div className="row">
                                                            {objroleId == 6 ? `Comment By ${lastTaskdata.data.startedByName} at` : `Comment By ${lastTaskdata.data.startedByName} at`}&nbsp;&nbsp;{getDefaultDate(lastTaskdata.data.startDate)} <br />
                                                            {lastTaskdata.data.comments}
                                                        </div>
                                                    </div>
                                                </div> : "" : ""
                                    }

                                    {
                                        objroleId == undefined || objroleId == 6 ? "" :
                                            <div className="card">
                                                <div className='card-body'>
                                                    <div className="row">
                                                        <div class="col-md-12">
                                                            <label className="form-label">Your Reason.</label>
                                                            <textarea rows="4" cols="20" value={noFileReason.text} maxlength="500" disabled={TaskComplete == false && isDisableForProcess == false ? false : true} onChange={e => { onNoFile_InputChange(e) }} className="form-control" id="text" placeholder="Provide Reason" />
                                                            <small class="text-danger">{nofilevalidationstate.nofilenerror}</small>

                                                            {/* {
                                                            clrfiction.text != "" ? <div className="pt-3" style={{ textAlign: "right" }}>
                                                                <button disabled={props.docpermissions.includes("Create") && disableButton === false ? false : true} type="button" onClick={() => { onClarificationSubmit() }} id="clsSaveData" className="btn btn-md btn-primary"> Save</button>
                                                            </div> : ""
                                                        } */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                    }
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6" >
                                    <button type="button" id="clsSaveData" disabled={TaskComplete == false && isDisableForProcess == false ? false : true} onClick={() => { onClarificationSubmit(1, 2) }} className="btn btn-md btn-primary" style={{ width: "100%" }}> Submit</button>
                                </div>
                                <div className="col-md-6" >
                                    <button type="button" id="clsSaveData" disabled={TaskComplete == false && isDisableForProcess == false ? false : true} onClick={() => { onClarificationSubmit(0, 2) }} className="btn btn-md btn-primary" style={{ width: "100%" }}>Save Draft</button>
                                </div>
                            </div>
                        </div> :
                            isClarification == true ?
                                <div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            {
                                                lastTaskdata != undefined ?
                                                    lastTaskdata.data == null || lastTaskdata.data == undefined || lastTaskdata == null ? "" :
                                                        lastTaskdata.data.docRequestTypeId == 3 && lastTaskdata.data.comments != "" && lastTaskdata.data.comments != undefined ? <div className="card">
                                                            <div className="card-body">
                                                                <div className="row">
                                                                    {objroleId == 6 ? `Clarification Requested By ${lastTaskdata.data.startedByName} at` : `Clarification Provided By ${lastTaskdata.data.startedByName} at`}&nbsp;&nbsp;{getDefaultDate(lastTaskdata.data.startDate)} <br />
                                                                    {lastTaskdata.data.comments}
                                                                </div>
                                                            </div>
                                                        </div> : "" : ""
                                            }

                                            <div className="card">
                                                <div className='card-body'>
                                                    <div className="row">
                                                        <div class="col-md-12">
                                                            <label className="form-label">Your Reason.</label>
                                                            <textarea rows="4" disabled={TaskComplete == false && isDisableForProcess == false ? false : true} cols="20" value={clrfiction.text} maxlength="500" onChange={e => { onClr_InputChange(e) }} className="form-control" id="text" placeholder="Provide Clarification" />
                                                            <small class="text-danger">{clrvalidationstate.clarficationerror}</small>
                                                            {/* {
                                                            clrfiction.text != "" ? <div className="pt-3" style={{ textAlign: "right" }}>
                                                                <button disabled={props.docpermissions.includes("Create") && disableButton === false ? false : true} type="button" onClick={() => { onClarificationSubmit() }} id="clsSaveData" className="btn btn-md btn-primary"> Save</button>
                                                            </div> : ""
                                                        } */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6" >
                                            <button type="button" id="clsSaveData" disabled={TaskComplete == false && isDisableForProcess == false ? false : true} onClick={() => { onClarificationSubmit(1, 3) }} className="btn btn-md btn-primary" style={{ width: "100%" }}> Submit</button>
                                        </div>
                                        <div className="col-md-6" >
                                            <button type="button" id="clsSaveData" disabled={TaskComplete == false && isDisableForProcess == false ? false : true} onClick={() => { onClarificationSubmit(0, 3) }} className="btn btn-md btn-primary" style={{ width: "100%" }}>Save Draft</button>
                                        </div>
                                    </div>
                                </div>
                                : radioSelected == 0 ?
                                    <div>
                                        {
                                            lastTaskdata != undefined ?
                                                lastTaskdata.data == null || lastTaskdata.data == undefined || lastTaskdata == null ? "" :
                                                    lastTaskdata.data.docRequestTypeId == 1 && lastTaskdata.data.comments != "" && lastTaskdata.data.comments != undefined ? <div className="card">
                                                        <div className="card-body">
                                                            <div className="row">
                                                                {objroleId == 6 ? `Comment Requested By ${lastTaskdata.data.startedByName} at` : `Comment Provided By ${lastTaskdata.data.startedByName} at`}&nbsp;&nbsp;{getDefaultDate(lastTaskdata.data.startDate)} <br />
                                                                {lastTaskdata.data.comments}
                                                            </div>
                                                        </div>
                                                    </div> : "" : ""
                                        }
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div style={{ marginTop: "10px", border: "2px dashed #d2ccccdd" }} >
                                                    <div className="pt-4 pb-4">
                                                        <div class="row pb-2" style={{ justifyContent: "center" }}>
                                                            <div class="col-md-1">
                                                                <img style={{ height: "50px" }} src={require('../dist/icons/icon-fileupload.png').default} ></img>
                                                            </div>
                                                        </div>
                                                        <div className='control-pane' ref={dropContainerRef}>

                                                            <div className='control-section row uploadpreview pb-2'>
                                                                <div className='col-md-2'>
                                                                </div>
                                                                <div className='col-md-8'>

                                                                    <div className='upload_wrapper'>
                                                                        {
                                                                            radioSelected == 0 ?
                                                                                <UploaderComponent  multiple={true} disabled={props.docpermissions.includes("Create") && TaskComplete == false && isDisableForProcess == false ? false : true}  failure={onUplaodFail} success={filesUploaded} ref={upload => { uploadObj = upload }} allowedExtensions=".pdf,.xlsx,.xls,.doc,.docx,.jpeg,.jpg,.png" autoUpload={false} id='fileUpload' type='file' asyncSettings={asyncSettings} removing={onRemoveFile.bind(this)}></UploaderComponent> : ""
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <div className='col-md-2 pb-2'>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-md-12 pt-2"><h3 class="text-center"><span style={{ padding: "6px", background: "#043A05", fontSize: "12px", color: "#fff", borderRadius: "20px" }}>OR</span></h3></div>
                                                        </div>

                                                        <div className='row'>
                                                            <div className='col-md-2'>
                                                            </div>
                                                            <div class="col-md-8">
                                                                <label className="form-label">Document Url</label>
                                                                <input value={documentLink.urlLink} disabled={props.docpermissions.includes("Create") && TaskComplete === false && isDisableForProcess === false ? false : true} type="text" onChange={e => { onInputChange(e) }} className="form-control" id="urlLink" placeholder="Enter Document URL" />
                                                                <small class="text-danger">{validationstate.linkError}</small>
                                                                {
                                                                    documentLink.urlLink != "" ? <div className="pt-3" style={{ textAlign: "right" }}>
                                                                        <button disabled={props.docpermissions.includes("Create") && disableButton === false && isDisableForProcess == false ? false : true} type="button" onClick={() => { onSubmit() }} id="clsSaveData" className="btn btn-md btn-primary"> Save</button>
                                                                    </div> : ""
                                                                }
                                                            </div>
                                                            <div className='col-md-2'>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div class="col-md-12">
                                                <div style={{ marginTop: "10px" }} className="card">
                                                    <div className="card-body">
                                                        <div className="form-group row">
                                                            <div className="col-md-8">
                                                                <label htmlFor="additionalField10" className="form-label">Comment</label>
                                                                <textarea value={docComment.text} disabled={TaskComplete == false && isDisableForProcess == false ? false : true} onChange={e => { docComment_InputChange(e) }} rows="4" cols="20" maxlength="500" type="text" className="form-control" id="text" placeholder="Enter Comment" />
                                                                <small className="text-danger">{Documentcommentvalidation.DocCommentError}</small>
                                                            </div>
                                                            <div className="col-md-4">

                                                                <div class="row pt-3">
                                                                    <div class="col-md-12" >
                                                                        <div style={{ overflowY: "scroll", height: "100px" }}>
                                                                            {listDocumentsItems}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6" >
                                                <button type="button" disabled={TaskComplete == false && isDisableForProcess == false ? false : true} id="clsSaveData" onClick={() => { onClarificationSubmit(1, 1) }} className="btn btn-md btn-primary" style={{ width: "100%" }}> Submit</button>
                                            </div>
                                            <div className="col-md-6" >
                                                <button type="button" disabled={TaskComplete == false && isDisableForProcess == false ? false : true} id="clsSaveData" onClick={() => { onClarificationSubmit(0, 1) }} className="btn btn-md btn-primary" style={{ width: "100%" }}>Save Draft</button>
                                            </div>
                                        </div>
                                    </div>
                                    : ""
                        }

                    </div>
                    <div class="col-md-7">
                        {objroleId == 6 ?
                            < div className="row">
                                <div className="col-md-12">
                                    <div className="col-md-12">
                                        <p ><b>Assigned To: </b>   {lastTaskdata != undefined ? lastTaskdata.data != undefined ? lastTaskdata.data.assignedToName != "" ?
                                            lastTaskdata.data.assignedToName + ` (${lastTaskdata.data.roleName})` : "" : "" : ""}</p>
                                    </div>
                                </div>
                            </div> : < div className="row">
                                <div className="col-md-12">
                                    <div className="col-md-12">
                                        <p ><b>Assigned By: </b>   {lastTaskdata != undefined ? lastTaskdata.data != undefined ? lastTaskdata.data.assignedToName != "" ?
                                            lastTaskdata.data.assignedByName + ` (${lastTaskdata.data.assignedByRoleName})` : "" : "" : ""}</p>
                                    </div>
                                </div>
                            </div>
                        }

                        <Tabs id="controlled-tab-example" activeKey={key} onSelect={(k) => setKey(k)} >
                            <Tab eventKey="documenthistorytab" title="Documents">
                                <div className="card">
                                    <div className="card-body">
                                        <div style={{ marginTop: "10px" }}>
                                            <GridComponent id='grid'
                                                dataSource={DocumentList == undefined ? null : DocumentList}
                                                allowResizing={true}
                                                toolbar={toolbarOptions}
                                                filterSettings={filter}
                                                allowFiltering={true}
                                                allowSorting={true} allowPaging={true}
                                                recordDoubleClick={true}
                                                pageSettings={{ pageCount: 5, pageSize: 10 }}
                                                allowPdfExport={true} allowExcelExport={true} ref={g => grid = g} height={"100%"}
                                                commandClick={commandClick.bind(this)}>
                                                <ColumnsDirective>
                                                    <ColumnDirective field='pbcDocName' headerText='File Type' width='120' textAlign='Center' template={getImage}></ColumnDirective>
                                                    <ColumnDirective filter={filter} field="pbcDocName" textAlign='left' width='200' headerText="Name" template={getDocumentsName} />
                                                    <ColumnDirective filter={filter} textAlign='Center' width='105' headerText="Status" template={getStatus} />
                                                    {/* <ColumnDirective allowFiltering={false} allowReordering={false} headerText='Manage Records' width='250' textAlign='Center' commands={objCommands}></ColumnDirective> */}
                                                    <ColumnDirective field="caDocId" allowFiltering={false} allowReordering={false} headerText='Manage Records' width='200' textAlign='center' template={getcommandButton}></ColumnDirective>
                                                </ColumnsDirective>
                                                <Inject services={[Reorder, Resize, InfiniteScroll, VirtualScroll, Group, Sort, Edit, Page, Toolbar, ColumnChooser, CommandColumn, Filter, PdfExport, ExcelExport]} />
                                            </GridComponent>
                                        </div>
                                    </div>
                                </div>
                            </Tab>
                            <Tab eventKey="commenttab" title="Comments" >
                                <div className="card">
                                    <div className="card-body">
                                        <div className="container">
                                            <div >
                                                {
                                                    <>
                                                        <div style={{ textAlign: "right" }}>
                                                            {documentName.length > 0 ? <button className="btn btn-sm btn-primary" onClick={() => getCommentsList(0)}>View All Comments</button> : ""}
                                                        </div>
                                                        <div>
                                                            {documentName.length > 0 ? "Document for: " + documentName : ""}
                                                        </div>
                                                    </>
                                                }
                                            </div>
                                            <div class="form-group row">
                                                <div class="col-md-12">
                                                    <div className="mb-3">
                                                        <div class="form-group row">
                                                            <div class="col-md-12">
                                                                {
                                                                    <div>
                                                                        <GridComponent id='Commentsgrid'
                                                                            dataSource={commentsList}
                                                                            allowResizing={true}
                                                                            toolbar={toolbarOptions}
                                                                            allowSorting={true} allowPaging={true}
                                                                            recordDoubleClick={true}
                                                                            filterSettings={filter}
                                                                            allowFiltering={true}
                                                                            pageSettings={{ pageCount: 5, pageSize: 10 }}
                                                                            allowPdfExport={true} allowExcelExport={true} ref={g => Commentsgrid = g} allowTextWrap={true} height={"100%"}
                                                                            allowGrouping={true} groupSettings={groupOptions}>
                                                                            <ColumnsDirective>
                                                                                <ColumnDirective field="pbcDocName" headerText='Comment For' width='150' ></ColumnDirective>
                                                                                <ColumnDirective filter={filter} field="pbcComment" textAlign='left' width='220' headerText="Comment" />
                                                                                <ColumnDirective filter={filter} field="lastUpdateByEmailId" textAlign='left' width='120' headerText="Email" />
                                                                                <ColumnDirective allowFiltering={false} field="lastUpdateDateTime" type="date" format='dd-MM-yyyy hh:mm:ss.s' textAlign='left' width='150' headerText="DateTime" />
                                                                            </ColumnsDirective>
                                                                            <Inject services={[Reorder, Resize, InfiniteScroll, VirtualScroll, Group, Sort, Edit, Page, Toolbar, ColumnChooser, CommandColumn, Filter, PdfExport, ExcelExport]} />
                                                                        </GridComponent>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {documentName.length > 0 ?
                                                    <div class="col-md-12">
                                                        <div className="mb-3">
                                                            <div class="form-group row">
                                                                <div class="col-md-9">
                                                                    <label htmlFor="additionalField10" className="form-label">Enter Comment</label>
                                                                    <textarea value={PBCComments.comment} disabled={props.cmtpermissions.includes("Create") ? false : true} onChange={e => { oncommentsInputChange(e) }} rows="3" cols="40" maxlength="500" type="text" className="form-control" id="comment" placeholder="Enter Comment" />
                                                                    <small class="text-danger">{commentvalidationstate.CommentError}</small>
                                                                </div>
                                                                {
                                                                    PBCComments.comment != "" ? <div className="col-md-3 pt-5" >
                                                                        <button type="button" disabled={props.cmtpermissions.includes("Create") && disableButton === false ? false : true} onClick={() => { onCommentSubmit() }} id="clsSaveData" className="btn btn-md btn-primary"> Save</button>
                                                                    </div> : ""
                                                                }
                                                            </div>
                                                        </div>
                                                    </div> : ""}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </Tab>
                            <Tab eventKey="audittab" title="Audit Trail">
                                <div className="card">
                                    <div className="card-body">
                                        <GridComponent id='grid'
                                            dataSource={auditTrialData}
                                            allowResizing={true}
                                            toolbar={toolbarOptions}
                                            allowSorting={true} allowPaging={true}
                                            filterSettings={filter}
                                            allowFiltering={true}
                                            recordDoubleClick={true}
                                            filterSettings={filter}
                                            allowFiltering={true}
                                            pageSettings={{ pageCount: 5, pageSize: 10 }}
                                            allowPdfExport={true} allowExcelExport={true} ref={g => grid = g} allowTextWrap={true} height={"100%"}>
                                            <ColumnsDirective>
                                                <ColumnDirective field="description" filter={filter} textAlign='Left' width='150' headerText="Description" />
                                                <ColumnDirective field="userName" filter={filter} textAlign='center' width='150' headerText="User" />
                                                <ColumnDirective field="createdDateTime" filter={filter} textAlign='center' type="date" format='dd-MM-yyyy HH:MM:ss' width='120' headerText="Date" />
                                            </ColumnsDirective>
                                            <Inject services={[Reorder, Resize, InfiniteScroll, VirtualScroll, Group, Sort, Edit, Page, Toolbar, ColumnChooser, CommandColumn, Filter, PdfExport, ExcelExport]} />
                                        </GridComponent>
                                    </div>
                                </div>
                            </Tab>
                        </Tabs>
                    </div>

                    {/* And Comments Model */}
                    <Modal
                        show={isOpen}
                        onHide={hideModal}
                        size="lg" backdrop="static">
                        <Modal.Header closeButton>
                            <Modal.Title>{title}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body >
                            <Comments pbcRequestId={props.data.pbcRequestId} documentid={documentid} documentname={documentName} commentsPermission={props.cmtpermissions} />
                        </Modal.Body>
                    </Modal>
                    <Modal
                        show={discriptionOpen}
                        onHide={hideModal}
                        size="lg" >
                        <Modal.Header closeButton>
                            <Modal.Title>Description</Modal.Title>
                        </Modal.Header>
                        <Modal.Body >
                            <p>{props.data.pbcDesc}</p>
                        </Modal.Body>
                    </Modal>
                </div>

            </div>
        </div >
    );
}