import React, { useState, useEffect } from "react";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { UserAddEdit } from '../Component/User/UserAddEdit';
import { PermissionAddEdit } from '../Component/Permission/PermissionAddEdit'
import { UserRolePermission } from '../Component/UserRolePermission/UserRolePermission'

export const UserManagement = () => {

    const [key, setKey] = useState('usertab');

    return (
        <>
            <div className="container-fluid pt-20">
                <Tabs id="controlled-tab-example" activeKey={key} onSelect={(k) => setKey(k)} >
                    <Tab eventKey="usertab" title="User">
                        <UserAddEdit />
                    </Tab>
                    <Tab eventKey="permissiontab" title="Permissions">
                        <PermissionAddEdit />
                    </Tab>
                    <Tab eventKey="advpermissiontab" title="Advanced Permission">
                        <UserRolePermission />
                    </Tab>
                </Tabs >
            </div>
        </>
    )
}