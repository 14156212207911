import React, { useState, useEffect } from "react";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import {
    GridComponent,
    ColumnDirective,
    ColumnsDirective,
    Page,
    Edit,
    Toolbar,
    Inject, ColumnChooser, CommandColumn, Filter, PdfExport, ExcelExport, Grid, Sort, Reorder,Resize, Group, VirtualScroll, InfiniteScroll
} from "@syncfusion/ej2-react-grids";

export const Setting = () => {

    const [key, setKey] = useState('licencetab');

    const onInputChange = e => {
    };
    const commandClick = (args) => { }
    const editOptions = {
        allowEditing: false,
        allowAdding: true,
        allowDeleting: true,
    };
    const toolbarOptions = ["Search"];

    const commands = [{ type: 'Edit', buttonOption: { iconCss: ' e-icons e-edit', cssClass: 'e-flat' } },
    { type: 'Delete', buttonOption: { iconCss: 'e-icons e-delete', cssClass: 'e-flat' } }];

    const filter = {
        type: 'Menu'
    };
    const check = {
        type: 'CheckBox'
    };
    return (
        <>
            <div className="container-fluid pt-20">
                <div className="row">
                    <div className="col-md-12">

                        < Tabs id="controlled-tab-example" activeKey={key} onSelect={(k) => setKey(k)} >
                            <Tab eventKey="licencetab" title="License">

                                <div className="card">
                                    <div className="card-body">
                                        <div className="row pt-20">

                                            <div className="form-group">
                                                <div class="col-md-12">
                                                    <label className="form-label">Expiry Of License :</label><span> Jan 04 2022 </span>
                                                </div>
                                                <div class="col-md-12">
                                                    <label className="form-label">License Info :</label><span> Info </span>
                                                </div>
                                                <div class="col-md-12">
                                                    <label className="form-label">License Type :</label><span> Permanent </span>
                                                </div>
                                                <div class="col-md-12">
                                                    <label className="form-label">License Support Team :</label><span> support@collabAudit.com </span>
                                                </div>
                                                <div class="col-md-12">
                                                    <label className="form-label">Collab Audit Version :</label><span> v1.5 </span>
                                                </div>
                                                <div class="col-md-12">
                                                    <label className="form-label">License Key :</label><span> Ecbgf34xfgj </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </Tab>
                            <Tab eventKey="Alerttab" title="Alerts">
                                <div className="card">
                                    <div className="card-body">

                                        <div class="row pt-20">
                                            <div className="col-md-4">
                                                <div class="form-body">
                                                    <div class="col-md-12">
                                                        <div class="form-group">
                                                            <label className="form-label">User Type</label><span className="text-danger reqired-fields"></span>
                                                            <select name="userTypeId" id="userTypeId" className='form-control '>
                                                                <option value>-- Select User Type --</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-12">
                                                        <div class="form-group">
                                                            <label className="form-label">User Role</label><span className="text-danger reqired-fields"></span>
                                                            <select name="userTypeId" id="userTypeId" className='form-control '>
                                                                <option value>-- Select User Role --</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">

                                                        <label htmlFor="additionalField10" className="form-label">Frequency</label><span className="text-danger reqired-fields">*</span>
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                            <label class="form-check-label" htmlFor="flexCheckDefault" style={{ paddingRight: "30px", paddingLeft: "5px" }}>Immediately</label>

                                                            <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                            <label class="form-check-label" htmlFor="flexCheckDefault" style={{ paddingRight: "30px", paddingLeft: "5px" }}>hourly</label>

                                                            <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                            <label class="form-check-label" htmlFor="flexCheckDefault" style={{ paddingRight: "30px", paddingLeft: "5px" }}>Daily</label>
                                                        </div>
                                                        <hr></hr>
                                                    </div>
                                                    <div class="col-md-12">
                                                        <div class="form-group text-right">
                                                            {<button type="button" className="btn btn-md btn-primary"> Save</button>}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-8">
                                                <GridComponent id='grid'
                                                    // dataSource={userList}
                                                    filterSettings={filter}
                                                    allowResizing={true}
                                                    allowFiltering={true}
                                                    editSettings={editOptions}
                                                    toolbar={toolbarOptions}
                                                    allowSorting={true} allowPaging={true}
                                                    showColumnChooser={true}
                                                    pageSettings={{ pageCount: 5, pageSizes: true }}
                                                    allowPdfExport={true} allowExcelExport={true}
                                                    commandClick={commandClick.bind(this)}>
                                                    <ColumnsDirective>
                                                        <ColumnDirective filter={filter} field="name" width='200' textAlign='Center' headerText="User Type" />
                                                        <ColumnDirective filter={filter} field="emailId" width='200' textAlign='Center' headerText="User Role" />
                                                        <ColumnDirective filter={filter} field="mobileNo" width='200' textAlign='Center' headerText="Frequencies" />
                                                        <ColumnDirective allowReordering={false} headerText='Manage Records' width='200' textAlign='Center' commands={commands}></ColumnDirective>
                                                    </ColumnsDirective>
                                                    <Inject services={[Reorder,Resize, Sort, Edit, Page, Toolbar, ColumnChooser, CommandColumn, Filter, PdfExport, ExcelExport]} />
                                                </GridComponent>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Tab>
                            <Tab eventKey="Remindertab" title="Reminders">
                                <div className="card">
                                    <div className="card-body">
                                        <div class="row pt-20">
                                            <div className="col-md-4">
                                                <div class="form-body">
                                                    <div class="col-md-12">
                                                        <div class="form-group">
                                                            <label className="form-label">User Type</label><span className="text-danger reqired-fields"></span>
                                                            <select name="userTypeId" id="userTypeId" className='form-control '>
                                                                <option value>-- Select User Type --</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-12">
                                                        <div class="form-group">
                                                            <label className="form-label">User Role</label><span className="text-danger reqired-fields"></span>
                                                            <select name="userTypeId" id="userTypeId" className='form-control '>
                                                                <option value>-- Select User Role --</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <label htmlFor="additionalField10" className="form-label">Frequency</label><span className="text-danger reqired-fields">*</span>



                                                        <div className="form-check">
                                                            <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                            <label class="form-check-label" htmlFor="flexCheckDefault" style={{ paddingRight: "30px", paddingLeft: "5px" }}>1 Day</label>

                                                            <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                            <label class="form-check-label" htmlFor="flexCheckDefault" style={{ paddingRight: "30px", paddingLeft: "5px" }}>2 Days</label>

                                                            <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                            <label class="form-check-label" htmlFor="flexCheckDefault" style={{ paddingRight: "30px", paddingLeft: "5px" }}>3 Days</label>
                                                        </div>
                                                        <hr></hr>
                                                    </div>
                                                    <div class="col-md-12">
                                                        <div class="form-group text-right">
                                                            {<button type="button" className="btn btn-md btn-primary"> Save</button>}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-8">
                                                <GridComponent id='grid'
                                                    // dataSource={userList}
                                                    allowResizing={true}
                                                    filterSettings={filter}
                                                    allowFiltering={true}
                                                    editSettings={editOptions}
                                                    toolbar={toolbarOptions}
                                                    allowSorting={true} allowPaging={true}
                                                    showColumnChooser={true}
                                                    pageSettings={{ pageCount: 5, pageSizes: true }}
                                                    allowPdfExport={true} allowExcelExport={true}
                                                    commandClick={commandClick.bind(this)}>
                                                    <ColumnsDirective>
                                                        <ColumnDirective filter={filter} field="name" width='200' textAlign='Center' headerText="User Type" />
                                                        <ColumnDirective filter={filter} field="emailId" width='200' textAlign='Center' headerText="User Role" />
                                                        <ColumnDirective filter={filter} field="mobileNo" width='200' textAlign='Center' headerText="Frequencies" />
                                                        <ColumnDirective allowReordering={false} headerText='Manage Records' width='200' textAlign='Center' commands={commands}></ColumnDirective>
                                                    </ColumnsDirective>
                                                    <Inject services={[Reorder,Resize, Sort, Edit, Page, Toolbar, ColumnChooser, CommandColumn, Filter, PdfExport, ExcelExport]} />
                                                </GridComponent>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </Tab>
                            <Tab eventKey="Authenticationtab" title="Authentication Provider">
                                <h5 className="card-text">Authentication Provider</h5>
                            </Tab>
                            <Tab eventKey="Integrationtab" title="Integration">
                                <h5 className="card-text">Integration</h5>
                            </Tab>
                        </Tabs >
                    </div>
                </div>

            </div>
        </>
    )
}