import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios'
toast.configure()
const Url = process.env.REACT_APP_API_URL;
export const Audit_Service = {
    getAuditList,
    addUpdateAudit,
    getAuditById,
    addAuditUser,
    deleteAudit
}

async function getAuditList() {
    const result = await axios.get(`${Url}audit/getAuditList`);
    return result;
}

async function addUpdateAudit(objAuditData) {

    const objdata = {
        AuditId: parseInt(objAuditData.auditId, 10),
        AuditName: objAuditData.auditName,
        Description: objAuditData.description,
        AuditStartDate: objAuditData.auditStartDate,
        AuditEndDate: objAuditData.auditEndDate,
        AuditPeriod: objAuditData.auditPeriod,
    };
    const result = await axios.post(`${Url}audit/addOrUpdate/`, objdata);
    return result;

}

async function getAuditById(id) {
    const result = await axios.get(`${Url}audit/getAuditList?id=${id}`);
    return result;
}


async function addAuditUser(userIds, auditId) {
    // var ids = JSON.stringify(userIds);
    const result = await axios.post(`${Url}audit/addSelectedUsersIntoAudit?userIds=${userIds}&auditId=${auditId}`);
    return result;
}

async function deleteAudit(auditId) {
    const result = await axios.post(`${Url}audit/deleteAudit?auditId=${auditId}`);
    return result;
}
